import { useNavigate } from "react-router";
import { z } from "zod";

import { Group } from "@/components/group";
import { HasInternalRole } from "@/components/has-role";
import { SectionContent, SectionDescription, SectionFooter, SectionHeader, SectionTitle } from "@/components/section";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { Form } from "@/forms-v2/form";
import { FormSubmit } from "@/forms-v2/form-submit";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import { useCreateBindingAuthorityExclusionMutation } from "src/generated/graphql";
import { FormReset } from "../forms-v2/form-reset";

const validationSchema = z.object({
  tiv: z.string().optional(),
  revenue: z.string().optional(),
  limit: z.string().optional(),
  state: z.string().optional(),
  operations: z.string().optional(),
  other: z.string().optional(),
});

const defaultValues = {
  tiv: "",
  revenue: "",
  limit: "",
  state: "",
  operations: "",
  other: "",
};

export const EscapeHatch = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { opportunityId, refetchQueries } = useMarketingPlan();
  const [update] = useCreateBindingAuthorityExclusionMutation();

  const handleSubmit = async (values: z.infer<typeof validationSchema>) => {
    await update({
      variables: {
        input: {
          ...values,
          opportunityId: opportunityId ?? "",
        },
      },
      onError: (e: any) => toast({ title: e.message }),
      onCompleted: () => {
        toast({ title: `Opportunity reassigned with details` });
        navigate("..");
      },
      refetchQueries,
    });
  };

  return (
    <HasInternalRole>
      <SectionHeader>
        <SectionTitle>
          <h2>Binding Authority Escape Hatch</h2>
        </SectionTitle>
        <SectionDescription>
          Please provide details on why this opportunity is not a fit for binding authority. All fields are optional but
          please be as descriptive as possible.
        </SectionDescription>
      </SectionHeader>

      <Form
        validationSchema={validationSchema}
        id="ESCAPE_HATCH_FORM"
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
      >
        <SectionContent>
          <Group className="grid-cols-2">
            <FieldInput name="tiv" label="TIV threshold" />
            <FieldInput name="revenue" label="Revenue threshold" />
            <FieldInput name="limit" label="Limit reason" />
            <FieldInput name="state" label="State" />
            <FieldInput name="operations" label="Operations" />
            <FieldInput name="other" label="Other" />
          </Group>
        </SectionContent>
        <SectionFooter className="justify-end">
          <FormReset onClick={() => navigate("..")} />
          <FormSubmit />
        </SectionFooter>
      </Form>
    </HasInternalRole>
  );
};
