import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { FC, PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";

import { Group } from "@/components/group";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { FieldDatePicker } from "@/forms-v2/fields/field-date-picker";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldCurrency } from "@/forms-v2/fields/field-number";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldToggleGroup } from "@/forms-v2/fields/field-toggle-group";
import {
  PrimaryLabelDataType,
  usePrimaryLabelsQuery,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";
import { cn } from "src/utils";

import {
  CglRuleItem,
  CglRuleOperator,
  ClientDataRuleItem,
  ClientDataRuleOperatorItemsMap,
  ClientDataRuleValueType,
  MinimumPremiumRuleItem,
  operatorString,
  PrimaryLabelRuleItem,
  PrimaryLabelRuleOperatorItemsMap,
  RuleItem,
  RuleType,
  StateRuleItem,
  StateRuleOperator,
  validRule,
} from "../rule";

export const ProductRuleForm = ({ products }: { products: VerticalMarketingPlanTemplateProductFragment[] }) => (
  <Group>
    <RuleEditor products={products} />
  </Group>
);

export function RuleEditor({ products }: { products: VerticalMarketingPlanTemplateProductFragment[] }) {
  const { watch } = useFormContext();

  const rule = watch("rule");

  const productName =
    products.length > 1
      ? `${products.length} products`
      : `${products[0].appetiteProduct.carrierName} ${products[0].appetiteProduct.carrierProductName}`;

  switch (rule?.type) {
    case RuleType.MinimumPremium:
      return <MinimumPremiumRule productName={productName} rule={rule} />;
    case RuleType.State:
      return <StateRule productName={productName} rule={rule} />;
    case RuleType.CGL:
      return <CglRule productName={productName} rule={rule} />;
    case RuleType.ClientData:
      return <ClientDataRule productName={productName} rule={rule} />;
    case RuleType.PrimaryLabel:
      return <PrimaryLabelRule productName={productName} rule={rule} />;
    default:
      return null;
  }
}

function MinimumPremiumRule({ productName, rule }: { productName: string; rule?: MinimumPremiumRuleItem }) {
  return (
    <>
      <FieldCurrency label="Minimum premium" name="rule.value" />

      {rule && (
        <RuleStatement rule={rule}>
          <strong>{productName}</strong>
          <span> will be </span>
          <strong>included</strong>
          <span> if premium is above </span>
          <strong>${rule.value ?? 0}</strong>.
        </RuleStatement>
      )}
    </>
  );
}

function StateRule({ productName, rule }: { productName: string; rule?: StateRuleItem }) {
  return (
    <>
      <FieldToggleGroup
        label="Operator"
        name="rule.operator"
        options={Object.values(StateRuleOperator).map((v) => ({ label: v, value: v }))}
        className="[&_[role=group]>div]:flex-1!"
      />

      <FieldSelect label="State" name="rule.value" options={STRICT_STATE_OPTIONS} />

      {rule && (
        <RuleStatement rule={rule}>
          <strong>{productName}</strong>
          <span> will be </span>
          <strong>{operatorString[rule.operator!]}</strong>
          <span> if primary state is </span>
          <strong>{STRICT_STATE_OPTIONS.find((state) => state.value === rule.value)?.label}</strong>.
        </RuleStatement>
      )}
    </>
  );
}

function CglRule({ productName, rule }: { productName: string; rule?: CglRuleItem }) {
  return (
    <>
      <FieldToggleGroup
        label="Operator"
        name="rule.operator"
        options={Object.values(CglRuleOperator).map((v) => ({ label: v, value: v }))}
        className="[&_[role=group]>div]:flex-1!"
      />

      <FieldInput label="CGL" name="rule.value" />

      {rule && (
        <RuleStatement rule={rule}>
          <strong>{productName}</strong>
          <span> will be </span>
          <strong>{operatorString[rule.operator!]}</strong>
          <span> if CGL Code is </span>
          <strong>{rule.value}</strong>.
        </RuleStatement>
      )}
    </>
  );
}

function ClientDataRule({ productName, rule }: { productName: string; rule?: ClientDataRuleItem }) {
  return (
    <>
      <FieldInput label="Key" name="rule.key" readOnly inputProps={{ defaultValue: rule?.key }} />

      <FieldToggleGroup
        label="Operator"
        name="rule.operator"
        options={Object.values(ClientDataRuleOperatorItemsMap[rule?.valueType ?? ClientDataRuleValueType.Text]).map(
          (v) => ({ label: v, value: v })
        )}
        className="[&_[role=group]>div]:flex-1!"
      />

      {rule?.valueType === ClientDataRuleValueType.Boolean ? (
        <FieldToggleGroup
          label="Client data value"
          name="rule.value"
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          className="[&_[role=group]>div]:flex-1!"
        />
      ) : (
        <FieldInput label="Client data value" name="rule.value" />
      )}

      {rule && (
        <RuleStatement rule={rule}>
          {rule.valueType === ClientDataRuleValueType.Boolean ? (
            <>
              <strong>{productName}</strong>
              <span> will be </span>
              <strong>{operatorString[rule.operator!]}</strong>
              <span> if </span>
              <strong>{rule.key}</strong>
              <span> is </span>
              <strong>{rule.value}</strong>.
            </>
          ) : (
            <>
              <strong>{productName}</strong>
              <span> will be excluded if </span>
              <strong>{rule.key}</strong>
              <span> {operatorString[rule.operator!]} </span>
              <strong>{rule.value}</strong>.
            </>
          )}
        </RuleStatement>
      )}
    </>
  );
}

function PrimaryLabelRule({ productName, rule }: { productName: string; rule?: PrimaryLabelRuleItem }) {
  const { data, loading } = usePrimaryLabelsQuery({
    variables: { input: { filter: { primaryKey: rule?.key } } },
  });

  const primaryLabels = data?.primaryLabels;
  const primaryLabel = primaryLabels?.[0];

  if (loading && !primaryLabels) {
    return <Loading label="Loading primary labels..." />;
  }

  if (!primaryLabel) {
    return <>There is no primary label that matches the rule key.</>;
  }

  if (!primaryLabel?.dataType) {
    return (
      <>
        The primary label has no data type. You must set a data type before you can use this primary label for a rule.
      </>
    );
  }

  return (
    <>
      <FieldInput label="Key" name="rule.key" readOnly />

      <FieldInput
        label="Data type"
        name="rule.dataType"
        readOnly
        inputProps={{ defaultValue: primaryLabel.dataType }}
      />

      <FieldToggleGroup
        label="Operator"
        name="rule.operator"
        options={Object.values(PrimaryLabelRuleOperatorItemsMap[primaryLabel.dataType]).map((v) => ({
          label: v,
          value: v,
        }))}
        className="[&_[role=group]>div]:flex-1!"
      />

      {primaryLabel?.dataType === PrimaryLabelDataType.Boolean && (
        <FieldToggleGroup
          label="Primary label value"
          name="rule.value"
          options={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
          className="[&_[role=group]>div]:flex-1!"
        />
      )}

      {primaryLabel?.dataType === PrimaryLabelDataType.Date && (
        <FieldDatePicker label="Primary label value" name="rule.value" dateFormat="yyyy-MM-dd" />
      )}

      {![PrimaryLabelDataType.Boolean, PrimaryLabelDataType.Date].includes(primaryLabel?.dataType) && (
        <FieldInput label="Primary label value" name="rule.value" />
      )}

      {rule && (
        <RuleStatement rule={rule}>
          {primaryLabel?.dataType === PrimaryLabelDataType.Boolean ? (
            <>
              <strong>{productName}</strong>
              <span> will be </span>
              <strong>{operatorString[rule.operator!]}</strong>
              <span> if </span>
              <strong>{rule.key}</strong>
              <span> is </span>
              <strong>{rule.value}</strong>.
            </>
          ) : (
            <>
              <strong>{productName}</strong>
              <span> will be excluded if </span>
              <strong>{rule.key}</strong>
              <span> {operatorString[rule.operator!]} </span>
              <strong>{rule.value}</strong>.
            </>
          )}
        </RuleStatement>
      )}
    </>
  );
}

export const RuleStatement: FC<PropsWithChildren<{ rule: RuleItem }>> = ({ rule, children }) => {
  const isValid = validRule(rule);

  return (
    <aside
      className={cn(
        "flex gap-4 items-start mt-4 px-4 py-3 rounded-md",
        isValid ? "bg-muted" : "bg-destructive text-destructive-foreground"
      )}
    >
      <Icon icon={isValid ? "help" : "error"} className="mt-1" />
      <span className="leading-relaxed text-sm">{isValid ? children : "Invalid Rule"}</span>
    </aside>
  );
};
