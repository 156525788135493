import { Link, Outlet, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { ScrollPane } from "@/components/scroll-pane";
import { useInsured } from "@/hooks/use-insured";
import { InsuredSidebar } from "./sidebar/insured-sidebar";

export const InsuredRoot = () => {
  const navigate = useNavigate();
  const { insured } = useInsured();

  useDocumentTitle(`${insured.name}: QuoteWell`);

  if (insured.mergedIntoId) {
    navigate(`/insured/${insured.mergedIntoId}`);
  }

  return (
    <>
      <InsuredSidebar />
      <ScrollPane sidebarPosition="left">
        <Outlet />
      </ScrollPane>
    </>
  );
};

export const InsuredCrumb = () => {
  const { insured } = useInsured();
  return <Link to={`/insured/${insured.id}`}>{insured.name}</Link>;
};
