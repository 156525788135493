import { Maps } from "@cp/toolkit";
import { uniq } from "lodash";
import React from "react";
import { useNavigate } from "react-router";
import { z } from "zod";

import { ContinueButton } from "@/components/continue-button-v2";
import { Group } from "@/components/group";
import { HasInternalRole, hasInternalRoleOrInternalAdmin } from "@/components/has-role";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Loading } from "@/components/ui/loading";
import { Form } from "@/forms-v2/form";
import { useAccount } from "@/hooks/use-account";
import { useInsured } from "@/hooks/use-insured";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import {
  ScoredAppetiteProductFragment,
  useAgencyAppointmentsQuery,
  useReplaceMarketingPlanSubmissionsMutation,
  useSearchAppetiteForOpportunityQuery,
} from "src/generated/graphql";
import { parseError } from "src/utils";

import { MarketsList } from "./markets-list";

const FormSchema = z.object({ productIds: z.string().array() });

export type FormSchemaType = z.infer<typeof FormSchema>;

export const SelectMarkets: React.FC<{ initialSelect?: boolean }> = () => {
  const { insuredId, insured } = useInsured();
  const { opportunity, refetchQueries } = useMarketingPlan();
  const { user } = useAccount();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: { agencyAppointments = [] } = {} } = useAgencyAppointmentsQuery({
    variables: { agencyId: insured.agent?.agencyId ?? "" },
  });

  const { loading, data } = useSearchAppetiteForOpportunityQuery({
    variables: { id: opportunity?.id ?? "" },
    skip: !opportunity,
  });

  const [replaceMarketingPlanSubmissions] = useReplaceMarketingPlanSubmissionsMutation({
    onCompleted: () => {
      toast({ title: "Markets updated" });
      navigate(`/insured/${insuredId}/plans/${opportunity?.id}`);
    },
    onError: (e) => toast({ title: "An error occurred", description: parseError(e.message) }),
    refetchQueries,
  });

  const directAppointments = agencyAppointments.map((a) => a.carrierSlug);

  const { id, selectedLinesOfBusiness, appetiteProducts } = opportunity;
  const requestedLines = uniq(selectedLinesOfBusiness);

  if (loading) {
    return (
      <HasInternalRole>
        <div className="p-6">
          <Loading className="p-4" />
        </div>
      </HasInternalRole>
    );
  }

  if (!data) {
    return (
      <HasInternalRole>
        <div className="p-6">
          <Card>
            <CardHeader>
              <CardTitle>Oops!</CardTitle>
              <CardDescription>
                We can&apos;t display markets &ndash; we&apos;re missing some important information.
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <p>
                {selectedLinesOfBusiness[0] ? (
                  <span>Lines: {selectedLinesOfBusiness.join(", ")}</span>
                ) : (
                  <span className="text-destructive">No lines selected.</span>
                )}
              </p>
              <p>
                {insured.businessClasses[0] ? (
                  <span>Business Class: {insured.businessClasses[0].description}</span>
                ) : (
                  <span className="text-destructive">No business class.</span>
                )}
              </p>
              <p>
                {insured.primaryState ? (
                  <span>Primary State: {insured.primaryState}</span>
                ) : (
                  <span className="text-destructive">No primary state selected.</span>
                )}
              </p>
              <p>
                {insured.description ? (
                  <span>Business Description: {insured.description}</span>
                ) : (
                  <span className="text-destructive">No business description.</span>
                )}
              </p>
            </CardContent>
          </Card>
        </div>
      </HasInternalRole>
    );
  }

  const { scoredProducts } = data.appetiteSearchForOpportunity;
  const highScoringProducts = getTopThreeProductsPerCoreLine(scoredProducts, selectedLinesOfBusiness);
  let defaultProductIds = appetiteProducts.map((o) => o.id);

  const scoredProductIdSet = new Set(scoredProducts.map((p) => p.product.id));
  const absentSelectedProductIds = defaultProductIds.filter((id) => !scoredProductIdSet.has(id));

  if (!hasInternalRoleOrInternalAdmin(user) && appetiteProducts.length === 0) {
    defaultProductIds = highScoringProducts.map((o) => o.id);
  }

  const handleSubmit = async ({ productIds }: FormSchemaType) => {
    await replaceMarketingPlanSubmissions({
      variables: {
        // Don't override products that aren't currently visible
        input: { appetiteProductIds: [...productIds, ...absentSelectedProductIds], marketingPlanId: id },
      },
    });
  };

  return (
    <HasInternalRole>
      <div className="p-6">
        <Form
          // This key forces the form to rerender if this length changes (which can occur when core lines are updated)
          key={absentSelectedProductIds.length}
          validationSchema={FormSchema}
          defaultValues={{ productIds: defaultProductIds }}
          onSubmit={handleSubmit}
        >
          <Group>
            <MarketsList
              scoredProducts={scoredProducts}
              requestedLines={requestedLines}
              directAppointments={directAppointments}
            />
            <footer className="bottom-6 flex items-center justify-end sticky">
              <ContinueButton className="pointer-events-auto shadow-xl" />
            </footer>
          </Group>
        </Form>
      </div>
    </HasInternalRole>
  );
};

const getTopThreeProductsPerCoreLine = (
  scoredProducts: ScoredAppetiteProductFragment[],
  selectedLinesOfBusiness: string[]
) => {
  const productsGroupedByCoreLine = Maps.multiMap(scoredProducts, (product) =>
    product.product.coreLines.find((line) => selectedLinesOfBusiness.includes(line))
  );

  const selectedProducts = Maps.mapValues(productsGroupedByCoreLine, (products) => {
    const filteredProducts = products.filter((p) => p.score > 0.8);
    const bestProductPerCarrier = Maps.keyBy(filteredProducts, (p) => p.product.carrierName);
    return [...bestProductPerCarrier.values()];
  });

  const topProductPerCoreLine = Maps.mapValues(selectedProducts, (products) =>
    products.sort((a, b) => b.score - a.score).slice(0, 1)
  );

  return [...topProductPerCoreLine.values()]
    .flat()
    .map((p) => p.product)
    .slice(0, selectedLinesOfBusiness.length);
};
