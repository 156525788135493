import { SnoozeButton } from "@/components/snooze-button";
import { Icon } from "@/components/ui/icon";
import { Separator } from "@/components/ui/separator";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { BankanMarketingPlanColumnQuery } from "src/generated/graphql";
import { formatTimezoneDateString } from "src/utils/date";
import {
  BankanCard,
  BankanCardHeader,
  BankanLink,
  BankanLinkFooter,
  BankanLinkText,
  daysDelta,
  DaysSince,
} from "./bankan-cards";
import { BankanScore } from "./bankan-score";

type Group = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number];

export const MarketingPlansCard = ({ group, cardClassName }: { group: Group; cardClassName: string }) => {
  const { insuredId, insured, marketingPlans } = group;
  return (
    <BankanCard className={cardClassName}>
      <BankanCardHeader insured={insured} />
      {marketingPlans.map((plan) => {
        const { id, createdAt, desiredEffectiveDate, selectedLinesOfBusiness, lastTransitionAt, score, snoozeUntil } =
          plan;
        const daysCreated = daysDelta(createdAt);
        const daysTransitioned = daysDelta(lastTransitionAt);

        return (
          <div key={id} className="relative">
            <Separator />
            <SnoozeButton opportunityId={id} desiredEffectiveDate={desiredEffectiveDate} snoozeUntil={snoozeUntil} />
            <BankanLink to={`/insured/${insuredId}/plans/${id}`}>
              <BankanLinkText className="space-y-1">
                {selectedLinesOfBusiness[0]
                  ? selectedLinesOfBusiness.map((lob) => <div key={lob}>{lob}</div>)
                  : "No Coverage Selected"}
              </BankanLinkText>
              <BankanLinkFooter>
                <div className="mr-auto">{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</div>
                <BankanScore bankanScore={score} />
                <Tooltip>
                  <TooltipTrigger className="flex gap-3">
                    <DaysSince date={createdAt} icon="markunread_mailbox" />
                    <DaysSince date={lastTransitionAt} />
                  </TooltipTrigger>
                  <TooltipContent align="end" className="filled space-y-1.5">
                    <div className="flex gap-2 items-center">
                      <Icon icon="markunread_mailbox" />
                      <span>
                        {daysCreated} day{daysCreated > 1 && "s"} since submission
                      </span>
                    </div>
                    <div className="flex gap-2 items-center">
                      <Icon icon="event" />
                      <span>
                        {daysTransitioned} day{daysTransitioned > 1 && "s"} in this column
                      </span>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </BankanLinkFooter>
            </BankanLink>
          </div>
        );
      })}
    </BankanCard>
  );
};
