import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { FC, useMemo } from "react";
import { DefaultValues } from "react-hook-form";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { input, z, ZodObject } from "zod";

import { Group } from "@/components/group";
import { Section, SectionContent, SectionHeader, SectionTitle } from "@/components/section";
import { Bar } from "@/components/ui/bar";
import { Loading } from "@/components/ui/loading";
import { FieldCombobox } from "@/forms-v2/fields/field-combobox";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { toast } from "@/hooks/use-toast";
import {
  AppetiteNotesQuery,
  Coverages,
  CreateAppetiteNoteInput,
  UpdateAppetiteNoteInput,
  useAppetiteNotesQuery,
  useCreateAppetiteNoteMutation,
  useUpdateAppetiteNoteMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

type AppetiteNote = AppetiteNotesQuery["appetiteNotes"][number];
type FormMode = "create" | "edit";

const CreateSchema = z.object({
  isoCglCodes: z
    .string()
    .regex(/^\d+(,\s*\d+)*$/)
    .or(z.literal("")),
  linesOfBusiness: z.array(z.nativeEnum(Coverages)).optional(),
  states: z.array(z.string()).optional(),
  note: z.string().min(1, { message: "Note is required" }),
});

const UpdateSchema = CreateSchema.extend({
  id: z.string(),
});

export const AppetiteNote: FC<{ mode: FormMode }> = ({ mode }) => {
  useDocumentTitle("Admin: Appetite Note");
  const { appetiteNoteId } = useParams<{ appetiteNoteId: string | undefined }>();
  const { data: { appetiteNotes = [] } = {}, loading } = useAppetiteNotesQuery();

  // TODO: Add a way to fetch a single appetite note by id.
  const appetiteNote: AppetiteNote | undefined = useMemo(
    () => appetiteNotes?.find((n) => n.id === appetiteNoteId),
    [appetiteNotes, appetiteNoteId]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Section className="flex flex-col flex-1">
      <SectionHeader className="bg-accent border-b">
        <SectionTitle>
          <h1>{appetiteNote ? "Edit" : "Create"} Note</h1>
        </SectionTitle>
      </SectionHeader>
      <FormDrawerWrapper mode={mode} appetiteNote={appetiteNote} />
    </Section>
  );
};

const FormDrawerWrapper: FC<{
  mode: FormMode;
  appetiteNote?: AppetiteNote;
}> = ({ mode, appetiteNote }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlCglCodes = queryParams.get("cglCodes")?.split(",");
  const [createAppetiteNote] = useCreateAppetiteNoteMutation({
    onError: (error) => toast({ title: "Error creating note", description: parseError(error), variant: "destructive" }),
  });
  const [updateAppetiteNote] = useUpdateAppetiteNoteMutation({
    onError: (error) => toast({ title: "Error updating note", description: parseError(error), variant: "destructive" }),
  });

  return mode === "edit" && appetiteNote ? (
    <FormDrawer
      appetiteNote={appetiteNote}
      schema={UpdateSchema}
      defaultValues={{
        id: appetiteNote.id,
        isoCglCodes: appetiteNote?.isoCglCodes?.toString(),
        linesOfBusiness: [...new Set<Coverages>(appetiteNote?.lobs)],
        states: appetiteNote?.states || [],
        note: appetiteNote?.note,
      }}
      onSubmit={async (values) => {
        await updateAppetiteNote({ variables: { input: sanitizeUpdateInput(values) } });
        navigate("..");
      }}
    />
  ) : (
    <FormDrawer
      schema={CreateSchema}
      defaultValues={{
        isoCglCodes: urlCglCodes?.join(",") || "",
        linesOfBusiness: [],
        states: [],
        note: "",
      }}
      onSubmit={async (values) => {
        await createAppetiteNote({ variables: { input: sanitizeCreateInput(values) } });
        navigate("..");
      }}
    />
  );
};

interface FormDrawerProps<T extends ZodObject<any>> {
  appetiteNote?: AppetiteNote;
  schema: T;
  onSubmit: (values: z.infer<T>) => void;
  defaultValues?: DefaultValues<input<T>>;
}

const FormDrawer = <T extends ZodObject<any>>({ schema, onSubmit, defaultValues }: FormDrawerProps<T>) => {
  const navigate = useNavigate();

  const handleSubmit = (values: z.infer<typeof schema>) => onSubmit(values);

  return (
    <Form
      validationSchema={schema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className="flex flex-1 flex-col"
    >
      <SectionContent>
        <Group>
          <FieldInput label="CGL codes" name="isoCglCodes" placeholder="Example: 94007, 94006" />

          <FieldCombobox
            label="States"
            name="states"
            placeholder="Select states"
            multiple
            options={STRICT_STATE_OPTIONS}
          />

          <FieldCombobox
            label="Lines of business"
            name="linesOfBusiness"
            placeholder="Add a line of business"
            options={Object.values(Coverages).map((lob) => ({ label: lob, value: lob }))}
            multiple
          />

          <FieldTextarea label="Note" name="note" />
        </Group>
      </SectionContent>

      <Bar as="footer" className="justify-end">
        <FormReset onClick={() => navigate("..")} />
        <FormSubmit />
      </Bar>
    </Form>
  );
};

const sanitizeCreateInput = (values: z.infer<typeof CreateSchema>): CreateAppetiteNoteInput => {
  return {
    isoCglCodes: values.isoCglCodes
      .split(",")
      .filter((v) => v !== "")
      .map((v) => v.trim()),
    states: values?.states ?? [],
    linesOfBusiness: [...(values?.linesOfBusiness ?? [])],
    note: values.note,
  };
};

const sanitizeUpdateInput = (values: z.infer<typeof UpdateSchema>): UpdateAppetiteNoteInput => {
  return {
    id: values.id,
    ...sanitizeCreateInput(values),
  };
};
