import { Outlet } from "react-router-dom";

import { SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { useInsured } from "@/hooks/use-insured";

export const Insured = () => {
  const { insured } = useInsured();
  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1 className="truncate">{insured.name}</h1>
        </SectionTitle>
      </SectionHeader>
      <Bar>
        <BarLinks>
          <BarLink to="." end>
            Details
          </BarLink>
          <BarLink to="email">Email Threads</BarLink>
        </BarLinks>
      </Bar>
      <Outlet />
    </>
  );
};
