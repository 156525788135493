import React, { FC, useCallback, useContext, useRef, useState } from "react";
import { DefaultValues } from "react-hook-form";
import { z, ZodRawShape } from "zod";

import { Group } from "@/components/group";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button, ButtonProps } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { cn } from "src/utils";

type RenderFn<T> = (close: (data?: T) => void) => React.ReactNode;

interface ModalContextData {
  open<T>(render: RenderFn<T>): Promise<T | undefined>;
  close<T>(data?: T): void;
  setOptions: React.Dispatch<React.SetStateAction<ModalOptions>>;
}

const ModalContext = React.createContext<ModalContextData>({
  open: () => Promise.resolve(undefined),
  close: () => {},
  setOptions: () => {},
});

interface ModalProps {
  onClose: () => void;
  open: boolean;
  content?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  type?: "dialog" | "alert";
}

const Modal: FC<ModalProps> = ({ onClose, type = "alert", ...props }) => {
  const onOpenChange = useCallback(
    (shouldBeOpen: boolean) => {
      if (!shouldBeOpen) {
        onClose();
      }
    },
    [onClose]
  );

  return type === "dialog" ? (
    <ModalDialog {...props} onOpenChange={onOpenChange} />
  ) : (
    <ModalAlertDialog {...props} onOpenChange={onOpenChange} />
  );
};

interface ModalDialogProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  content?: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
}

const ModalDialog: FC<ModalDialogProps> = ({ title, description, content, ...props }) => (
  <Dialog {...props}>
    <DialogContent className="max-h-[85dvh] overflow-y-auto">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <DialogDescription className={cn(!description && "hidden")}>{description ?? title}</DialogDescription>
      </DialogHeader>
      {content}
    </DialogContent>
  </Dialog>
);

const ModalAlertDialog: FC<ModalDialogProps> = ({ title, description, content, ...props }) => (
  <AlertDialog {...props}>
    <AlertDialogContent className="max-h-[85dvh] overflow-y-auto">
      <AlertDialogHeader>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <AlertDialogDescription className={cn(!description && "hidden")}>{description ?? title}</AlertDialogDescription>
      </AlertDialogHeader>
      {content}
    </AlertDialogContent>
  </AlertDialog>
);

interface ModalData {
  isOpen: boolean;
  content?: React.ReactNode;
}

interface ModalOptions {
  title: React.ReactNode;
  description?: React.ReactNode;
  type?: ModalProps["type"];
}

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [{ isOpen, content }, setData] = useState<ModalData>({
    isOpen: false,
  });
  const [{ title, description, type }, setOptions] = useState<ModalOptions>({
    title: "",
    description: "",
    type: "alert",
  });

  const resolver = useRef<(v?: unknown) => void>();

  const close = useCallback(function <T>(data?: T) {
    resolver.current?.(data);
    setData((prev) => ({ ...prev, isOpen: false }));
  }, []);

  const open = useCallback(
    function <T>(fn: RenderFn<T>) {
      // Resolve any previous promises
      resolver.current?.();

      return new Promise<T | undefined>((resolve, reject) => {
        try {
          resolver.current = resolve as any;
          const content = fn(close);
          setData({ isOpen: true, content });
        } catch (error) {
          reject(error);
        }
      });
    },
    [close]
  );

  return (
    <ModalContext.Provider value={{ open, close, setOptions }}>
      <Modal type={type} onClose={close} content={content} open={isOpen} title={title} description={description} />
      {children}
    </ModalContext.Provider>
  );
};

interface OpenModalOptions extends ModalOptions {}

export const useOpenModal = () => {
  const { open, close, setOptions } = useContext(ModalContext);

  const openModal = useCallback(
    function <T>(openFn: RenderFn<T>, options?: OpenModalOptions) {
      if (options) {
        setOptions(options);
      }

      return open(openFn);
    },
    [open]
  );

  return { openModal, closeModal: close };
};

interface OpenFormOptions<TSchema extends z.ZodObject<ZodRawShape>> extends OpenModalOptions {
  validationSchema?: TSchema;
  defaultValues?: DefaultValues<z.infer<TSchema>>;
  onSubmit?: (values: z.infer<TSchema>, close: () => void) => unknown | Promise<unknown>;
  submitButtonProps?: ButtonProps;
}

export const useOpenForm = () => {
  const { open, close, setOptions } = useContext(ModalContext);

  const openForm = useCallback(
    function <TSchema extends z.ZodObject<ZodRawShape>>(
      formFields: React.ReactNode,
      { validationSchema, defaultValues, onSubmit, submitButtonProps, ...options }: OpenFormOptions<TSchema>
    ) {
      setOptions(options);

      return open<z.infer<TSchema>>((close) => (
        <Form
          onSubmit={async (v) => {
            await onSubmit?.(v, close);
            return close(v);
          }}
          onReset={() => close()}
          defaultValues={defaultValues}
          validationSchema={validationSchema}
        >
          <Group className="gap-6">
            {formFields}

            <AlertDialogFooter className="bottom-0 sticky">
              <FormReset onClick={() => close()} />
              <FormSubmit {...submitButtonProps} />
            </AlertDialogFooter>
          </Group>
        </Form>
      ));
    },
    [open]
  );

  return { openForm, closeForm: close };
};

interface OpenConfirmationOptions extends OpenModalOptions {
  buttonProps?: ButtonProps;
}

export const useOpenConfirmation = () => {
  const { open, close, setOptions } = useContext(ModalContext);

  const openConfirmation = useCallback(
    ({ buttonProps, ...options }: OpenConfirmationOptions) => {
      setOptions(options);

      return open<boolean>((close) => (
        <AlertDialogFooter>
          <Button variant="outline" onClick={() => close(false)}>
            Cancel
          </Button>
          <Button theme="primary" {...buttonProps} onClick={() => close(true)}>
            {buttonProps?.children || "Confirm"}
          </Button>
        </AlertDialogFooter>
      ));
    },
    [open]
  );

  return { openConfirmation, closeConfirmation: close };
};

export const useModal = () => ({
  ...useOpenModal(),
  ...useOpenForm(),
  ...useOpenConfirmation(),
});
