import { ColumnStates, COLUMN_STATE_METADATA } from "@/column-metadata";
import { States, STATE_METADATA } from "@/metadata";
import { cn } from "src/utils";
import { Badge } from "./ui/badge";
import { Icon } from "./ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

export const StatePill = ({ state }: { state: States }) => {
  const { cardClassName } = STATE_METADATA[state];
  return (
    <Badge className={cn("bg-background h-6 outline-1 select-none text-2xs", cardClassName)}>
      <StateIndicator state={state} className="gap-1.5 pr-0.5" />
    </Badge>
  );
};

export const StateIcon = ({ state }: { state: States }) => {
  const { className: stateClassName, icon, label } = STATE_METADATA[state];
  return (
    <Tooltip>
      <TooltipTrigger className={cn("flex items-center justify-center", stateClassName)}>
        <Icon icon={icon} />
      </TooltipTrigger>
      <TooltipContent>{label}</TooltipContent>
    </Tooltip>
  );
};

export const StateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: States;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = STATE_METADATA[state];
  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};

export const StateLabel = ({ state, className }: { state: States; className?: string }) => {
  const { className: stateClassName, label } = STATE_METADATA[state];
  return <span className={cn(stateClassName, className)}>{label}</span>;
};

export const ColumnStateIndicator = ({
  state,
  displayLabel = true,
  className,
}: {
  state: ColumnStates;
  displayLabel?: boolean;
  className?: string;
}) => {
  const { className: stateClassName, icon, label } = COLUMN_STATE_METADATA[state];
  return (
    <span className={cn("flex gap-[0.5em] items-center", stateClassName, className)}>
      <Icon icon={icon} />
      {displayLabel && <span className="truncate">{label}</span>}
    </span>
  );
};
