import { useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Group } from "@/components/group";
import { Loading } from "@/components/ui/loading";
import { FieldCombobox } from "@/forms-v2/fields/field-combobox";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { usePrimaryLabelsQuery } from "src/generated/graphql";

import { PrimaryLabelCommandItemLabel } from "../../label-management/extracted-labels/components/assign-primary-label-popover";
import { ClientDataRuleValueType, PrimaryLabelRuleItem, RuleType } from "../rule";

export const TemplateRuleForm = () => (
  <Group>
    <FieldSelect
      label="Type"
      name="rule.0.type"
      placeholder="Select rule type"
      options={[
        { label: "Minimum Premium", value: RuleType.MinimumPremium },
        { label: "Client Data", value: RuleType.ClientData },
        { label: "Primary Label", value: RuleType.PrimaryLabel },
        { label: "State", value: RuleType.State },
        { label: "CGL", value: RuleType.CGL },
      ]}
    />
    <RuleEditor />
  </Group>
);

function RuleEditor() {
  const { watch } = useFormContext();

  const rule = watch("rule");

  switch (rule?.[0]?.type) {
    case RuleType.ClientData:
      return <ClientDataRule />;
    case RuleType.PrimaryLabel:
      return <PrimaryLabelRule rule={rule as PrimaryLabelRuleItem[]} />;
    default:
      return null;
  }
}

function ClientDataRule() {
  return (
    <Group>
      <FieldInput label="Client data key" name="rule.0.key" />
      <FieldSelect
        label="Value type"
        name="rule.0.valueType"
        options={Object.values(ClientDataRuleValueType).map((v) => ({ label: v, value: v }))}
      />
    </Group>
  );
}

function PrimaryLabelRule({ rule }: { rule?: PrimaryLabelRuleItem[] }) {
  const { data, loading } = usePrimaryLabelsQuery();

  const primaryLabels = data?.primaryLabels ?? [];

  const primaryLabel = useMemo(
    () => primaryLabels.find((pl) => pl.primaryKey === rule?.[0]?.key),
    [primaryLabels, rule?.[0].key]
  );

  if (loading && !primaryLabels) {
    return <Loading label="Loading primary labels..." />;
  }

  return (
    <>
      <FieldCombobox
        label="Primary label key"
        name="rule.0.key"
        options={primaryLabels.map((pl) => ({
          label: <PrimaryLabelCommandItemLabel primaryLabel={pl} isSelected={rule?.[0].key === pl.primaryKey} />,
          value: pl.primaryKey,
          displayValue: pl.displayName || pl.primaryKey,
        }))}
      />

      {primaryLabel?.dataType && (
        <FieldInput label="Data type" name="rule.0.dataType" inputProps={{ value: primaryLabel.dataType }} readOnly />
      )}
    </>
  );
}
