import { FC } from "react";
import { useParams } from "react-router";

import { Loading } from "@/components/ui/loading";
import { SheetDescription, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { ExtractedLabelFragment, useExtractedLabelQuery } from "src/generated/graphql";

import { AssignPrimaryLabelPopover } from "./components/assign-primary-label-popover";

export interface ExtractedLabelFormProps {
  extractedLabel: ExtractedLabelFragment;
}

export const ExtractedLabelForm: FC<ExtractedLabelFormProps> = ({ extractedLabel }) => {
  return (
    <div className="grid grid-cols-[140px_4fr] auto-rows-min gap-x-4 divide-y -mb-4">
      <div className="col-span-full grid grid-cols-subgrid py-4">
        <h4 className="text-xs text-muted-foreground font-semibold">Key</h4>
        <p className="text-sm">{extractedLabel.key}</p>
      </div>

      <div className="col-span-full grid grid-cols-subgrid py-4">
        <h4 className="text-xs text-muted-foreground font-semibold">Source</h4>
        <p className="text-sm">{extractedLabel.source}</p>
      </div>

      <div className="col-span-full grid grid-cols-subgrid py-4">
        <h4 className="text-xs text-muted-foreground font-semibold">Count</h4>
        <p className="text-sm">{extractedLabel.count}</p>
      </div>

      <div className="col-span-full grid grid-cols-subgrid py-4">
        <h4 className="text-xs text-muted-foreground font-semibold">Primary Label(s)</h4>
        <div className="-mx-3 -my-2">
          <AssignPrimaryLabelPopover extractedLabel={extractedLabel} className="text-sm text-left" />
        </div>
      </div>

      <div className="col-span-full grid grid-cols-subgrid py-4">
        <h4 className="text-xs text-muted-foreground font-semibold">Frequently Received Values</h4>
        <p className="text-sm text-muted-foreground">Coming soon</p>
      </div>
    </div>
  );
};

export const ExtractedLabel = () => {
  const { id } = useParams();
  const { data, loading } = useExtractedLabelQuery({ variables: { id: id as string } });

  if (!data?.extractedLabel) {
    return null;
  }

  return (
    <>
      <SheetHeader>
        <SheetTitle>Edit Extracted Label</SheetTitle>
        <SheetDescription>Some descriptive text about editing this extracted label.</SheetDescription>
      </SheetHeader>

      {loading && !data?.extractedLabel && <Loading label="Loading extracted label details..." />}

      {data.extractedLabel && <ExtractedLabelForm extractedLabel={data.extractedLabel} />}
    </>
  );
};
