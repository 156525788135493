import { useImpersonateUser } from "@cp/auth";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Roles, UserAccountFragment } from "src/generated/graphql";
import { HasRole } from "./has-role";

export const ImpersonateUser = ({
  children,
  elseShow = null,
  replace = false,
  roles = [Roles.Admin, Roles.Arm],
  user,
}: {
  children?: React.ReactNode;
  elseShow?: React.ReactNode;
  replace?: boolean;
  roles?: Roles[];
  user: UserAccountFragment;
}) => {
  const { mutate: impersonateUser } = useImpersonateUser();
  return (
    <HasRole roles={roles} elseShow={elseShow}>
      <Tooltip>
        <TooltipTrigger
          asChild
          onClick={() =>
            impersonateUser(
              { userIdToImpersonate: user.id },
              {
                onSuccess: () => {
                  const forward = replace ? window.location.origin : window.location.href;
                  window.location.replace(forward);
                },
              }
            )
          }
        >
          {children ?? (
            <Button variant="ghost" size="sm" display="icon">
              <Icon icon="account_circle" />
            </Button>
          )}
        </TooltipTrigger>
        <TooltipContent>
          Impersonate {user.firstName} {user.lastName}
        </TooltipContent>
      </Tooltip>
    </HasRole>
  );
};
