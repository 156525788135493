// Looking for an icon? Make sure Material Symbols and Rounded are selected and
// then snake case the names of the icons here:
//
// https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Symbols

import { MaterialSymbol } from "material-symbols";
import { FC } from "react";

import { cn } from "src/utils";

export interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
  icon: MaterialSymbol;
}

export const Icon: FC<IconProps> = ({ icon, className, ...props }) => (
  <span
    className={cn("aspect-square flex-none icon inline-flex items-center justify-center select-none", className)}
    data-icon={icon}
    {...props}
  >
    {icon}
  </span>
);
