import { Outlet } from "react-router";

import { usePage } from "@/hooks/use-page";
import { CSSProperties } from "react";
import { cn } from "src/utils";
import { AppNav } from "./app-nav";

export const Page = () => {
  const { width } = usePage();
  return (
    <div
      className={cn(
        "flex min-h-dvh isolate relative z-0",
        "landscape:p-2 landscape:pl-16",
        "portrait:pb-(--nav-height)"
      )}
    >
      <AppNav />
      <div
        className={cn("flex flex-auto md:backdrop-brightness-200 md:backdrop-opacity-50 md:rounded-md")}
        style={
          {
            "--sidebar-width": width,
          } as CSSProperties
        }
        id="Page"
      >
        <Outlet />
      </div>
    </div>
  );
};

export const CenteredPage = () => (
  <div className="flex flex-col items-center justify-center min-h-dvh p-4 relative">
    <Outlet />
  </div>
);
