import { UUID } from "@cp/toolkit";
import { useState } from "react";
import { useParams } from "react-router";

import { Grid, GridCell, GridRow, GridRowHeader, GridRowLink } from "@/components/grid";
import { ImpersonateUser } from "@/components/impersonate-user";
import { Card, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Spinner } from "@/components/ui/loading";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useAgentAccountsQuery, useInsuredsQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { formatTimezoneDateString } from "src/utils/date";

export const LaunchAgents = () => {
  const { agencyId } = useParams();
  const [agentId, setAgentId] = useState<UUID>();

  const { data: { userAccounts } = { userAccounts: [] }, loading: loadingAgents } = useAgentAccountsQuery({
    variables: { input: { agencyId } },
    skip: !agencyId,
  });

  const { data: { insureds } = { insureds: [] }, loading: insuredsLoading } = useInsuredsQuery({
    variables: {
      input: {
        agencyId,
        agentId,
      },
    },
    skip: !agencyId,
  });

  if (!agencyId) {
    return null;
  }

  return (
    <div className="gap-3 grid grid-cols-[1fr_2fr] items-start px-6 py-5">
      <Card className="overflow-hidden">
        <CardHeader>
          <CardTitle>
            Agents
            {loadingAgents && <Spinner />}
          </CardTitle>
          {userAccounts.length === 0 && <CardDescription>No insureds found.</CardDescription>}
        </CardHeader>
        {userAccounts[0] && (
          <Grid className="grid-cols-[1fr_1.5rem]">
            <GridRowHeader>
              <GridCell>Agent</GridCell>
              <GridCell />
            </GridRowHeader>
            {userAccounts.map((agent) => (
              <GridRow
                key={agent.id}
                className={cn("cursor-pointer", agentId === agent.id && "bg-primary/5")}
                onClick={() => setAgentId((agentId) => (agentId === agent.id ? undefined : agent.id))}
              >
                <GridCell>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <a href={`mailto:${agent.email}`} target="_blank" rel="noreferrer" className="underline">
                        {agent.firstName} {agent.lastName}
                      </a>
                    </TooltipTrigger>
                    <TooltipContent>Created {formatTimezoneDateString(agent.createdAt, "MMM d, yyyy")}</TooltipContent>
                  </Tooltip>
                </GridCell>
                <ImpersonateUser user={agent} replace={true} />
              </GridRow>
            ))}
          </Grid>
        )}
      </Card>
      <Card className="overflow-hidden">
        <CardHeader>
          <CardTitle>
            Insureds
            {insuredsLoading && <Spinner />}
          </CardTitle>
          {insureds.length === 0 && <CardDescription>No insureds found.</CardDescription>}
        </CardHeader>
        {insureds[0] && (
          <Grid className="grid-cols-2">
            <GridRowHeader>
              <GridCell>Insured</GridCell>
              <GridCell>Agent</GridCell>
            </GridRowHeader>
            {insureds.map((insured) => (
              <GridRowLink key={insured.id} to={`/insured/${insured.id}`}>
                <GridCell>
                  <Tooltip>
                    <TooltipTrigger>{insured.name}</TooltipTrigger>
                    <TooltipContent>
                      Created {formatTimezoneDateString(insured.createdAt, "MMM d, yyyy")}
                    </TooltipContent>
                  </Tooltip>
                </GridCell>
                <GridCell>
                  {insured.agent.firstName} {insured.agent.lastName}
                </GridCell>
              </GridRowLink>
            ))}
          </Grid>
        )}
      </Card>
    </div>
  );
};
