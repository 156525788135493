import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { ReorderGroup, ReorderItem } from "@/components/reorder";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { ToastAction } from "@/components/ui/toast";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { useToast } from "@/hooks/use-toast";
import {
  ExtractedLabelFragment,
  PrimaryLabelFragment,
  UpdatePrimaryLabelInput,
  useConnectExtractedLabelToPrimaryLabelMutation,
  useDisconnectExtractedLabelFromPrimaryLabelMutation,
  usePrimaryLabelQuery,
  useUpdatePrimaryLabelMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

import { PrimaryLabelDataTypeOptions } from "../label-management.constants";

const validationSchema = z.object({
  id: z.string().min(1, { message: "ID is required." }),
  primaryKey: z.string().min(1, { message: "Please enter a primary key." }),
  displayName: z.string().optional(),
  dataType: z.string().min(1, { message: "Please select a data type." }),
  description: z.string().optional(),
});

export interface PrimaryLabelFormProps {
  primaryLabel: PrimaryLabelFragment;
}

export const PrimaryLabelForm: FC<PrimaryLabelFormProps> = ({ primaryLabel }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [updatePrimaryLabelMutation] = useUpdatePrimaryLabelMutation();
  const [extractedLabelsOrder, setExtractedLabelsOrder] = useState(
    primaryLabel.extractedLabels.map((label) => label.id)
  );

  useEffect(() => {
    setExtractedLabelsOrder(primaryLabel.extractedLabels.map((label) => label.id));
  }, [primaryLabel.extractedLabels?.length]);

  const defaultValues = {
    id: primaryLabel.id || "",
    primaryKey: primaryLabel.primaryKey || "",
    displayName: primaryLabel.displayName || "",
    dataType: primaryLabel.dataType || "",
    description: primaryLabel.description || "",
  };

  const goBack = () => navigate("/label-management/primary-labels");

  const handleSubmit = async (values: UpdatePrimaryLabelInput) => {
    await updatePrimaryLabelMutation({
      variables: { input: values },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
      onCompleted: goBack,
      onError: (error) => toast({ title: "Error", description: parseError(error.message), variant: "destructive" }),
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues as UpdatePrimaryLabelInput}
      validationSchema={validationSchema}
    >
      <div className="grid grid-cols-[140px_4fr] auto-rows-min gap-x-4 divide-y -mb-4">
        <div className="relative col-span-full grid grid-cols-subgrid py-4">
          <div>
            <h4 className="text-xs text-muted-foreground font-semibold sticky -top-2">Primary key</h4>
          </div>
          <FieldInput name="primaryKey" placeholder="Primary key" />
        </div>

        <div className="relative col-span-full grid grid-cols-subgrid py-4">
          <div>
            <h4 className="text-xs text-muted-foreground font-semibold sticky -top-2">Display name</h4>
          </div>
          <FieldInput name="displayName" placeholder="Display name" />
        </div>

        <div className="relative col-span-full grid grid-cols-subgrid py-4">
          <div>
            <h4 className="text-xs text-muted-foreground font-semibold sticky -top-2">Data type</h4>
          </div>
          <FieldSelect name="dataType" placeholder="Data type" options={PrimaryLabelDataTypeOptions} />
        </div>

        <div className="relative col-span-full grid grid-cols-subgrid py-4">
          <div>
            <h4 className="text-xs text-muted-foreground font-semibold sticky -top-2">Description</h4>
          </div>
          <FieldTextarea name="description" placeholder="Description" />
        </div>

        <div className="relative col-span-full grid grid-cols-subgrid py-4">
          <div>
            <h4 className="text-xs text-muted-foreground font-semibold sticky -top-2">Extracted labels</h4>
          </div>
          <Card>
            <ReorderGroup values={extractedLabelsOrder} onReorder={setExtractedLabelsOrder}>
              <Grid className="grid-cols-[3fr_2fr_2rem]">
                <GridRowHeader>
                  {/* <GridCell>Rk</GridCell> */}
                  <GridCell>Key</GridCell>
                  <GridCell>Source</GridCell>
                  <GridCell>&nbsp;</GridCell>
                </GridRowHeader>

                {!primaryLabel.extractedLabels?.length && (
                  <GridRow>
                    <GridCell className="col-span-full text-sm text-muted-foreground">
                      No extracted labels assigned
                    </GridCell>
                  </GridRow>
                )}

                {!!primaryLabel.extractedLabels?.length && (
                  <>
                    {extractedLabelsOrder?.map((id, index) => {
                      const extractedLabel = primaryLabel.extractedLabels.find((label) => label.id === id);

                      if (!extractedLabel) {
                        return null;
                      }

                      return (
                        <ExtractedLabelGridRow
                          key={extractedLabel.id}
                          extractedLabel={extractedLabel}
                          primaryLabel={primaryLabel}
                          index={index}
                        />
                      );
                    })}
                  </>
                )}
              </Grid>
            </ReorderGroup>
          </Card>
        </div>
      </div>

      <SheetFooter>
        <ButtonGroup>
          <FormReset onClick={goBack} />
          <FormSubmit />
        </ButtonGroup>
      </SheetFooter>
    </Form>
  );
};

export interface ExtractedLabelGridRowProps {
  primaryLabel: PrimaryLabelFragment;
  extractedLabel: Omit<ExtractedLabelFragment, "primaryLabels">;
  index: number;
}

export const ExtractedLabelGridRow: FC<ExtractedLabelGridRowProps> = ({ extractedLabel, primaryLabel }) => {
  const { toast } = useToast();
  const [disconnectExtractedLabel, { loading: disconnectLoading }] =
    useDisconnectExtractedLabelFromPrimaryLabelMutation();
  const [connectExtractedLabel, { loading: connectLoading }] = useConnectExtractedLabelToPrimaryLabelMutation();

  const loading = disconnectLoading || connectLoading;

  const handleConnect = async () => {
    await connectExtractedLabel({
      variables: { input: { extractedLabelId: extractedLabel.id, id: primaryLabel.id } },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
    });
  };

  const handleDisconnect = async () => {
    await disconnectExtractedLabel({
      variables: { input: { extractedLabelId: extractedLabel.id, id: primaryLabel.id } },
      refetchQueries: ["PrimaryLabels", "PaginatedPrimaryLabels"],
      onCompleted: () => {
        toast({
          title: "Extracted label removed",
          action: (
            <ToastAction altText="undo disconnect extracted label" onClick={handleConnect}>
              Undo
            </ToastAction>
          ),
        });
      },
    });
  };

  return (
    <GridRow asChild key={extractedLabel.id}>
      <ReorderItem dragListener={false} value={extractedLabel.id}>
        {/* Disabling reording until we support it. */}
        {/* <GridCell className="-ml-1">
          <ReorderHandle order={index + 1} />
        </GridCell> */}

        <GridCell>{extractedLabel.key}</GridCell>
        <GridCell>{extractedLabel.source}</GridCell>
        <GridCell className="-mr-2 text-right">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                type="button"
                variant="ghost"
                size="sm"
                display="icon"
                onClick={handleDisconnect}
                disabled={loading}
              >
                <Icon icon="close" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Remove label</TooltipContent>
          </Tooltip>
        </GridCell>
      </ReorderItem>
    </GridRow>
  );
};

export const PrimaryLabel: FC = () => {
  const { id } = useParams();
  const { data, loading } = usePrimaryLabelQuery({ variables: { id: id as string } });

  if (!data?.primaryLabel) {
    return null;
  }

  return (
    <>
      <SheetHeader>
        <SheetTitle>Edit Primary Label</SheetTitle>
        <SheetDescription>Some descriptive text about editing this primary label.</SheetDescription>
      </SheetHeader>

      {loading && !data?.primaryLabel && <Loading label="Loading primary label details..." />}

      {data.primaryLabel && <PrimaryLabelForm primaryLabel={data.primaryLabel} />}
    </>
  );
};
