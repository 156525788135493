import { ControllerProps, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { cn } from "src/utils";

import { FormField } from "@/components/ui/form";
import { Input } from "@/components/ui/input";

interface CurrencyFieldProps {
  autoFocus?: boolean;
  placeholder?: string;
  required?: boolean;
  inputClassName?: string;
  allowDecimal?: boolean;
}

export const Currency = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  allowDecimal = false,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & CurrencyFieldProps) => (
  <FormField
    {...props}
    render={({ field: { onChange, onBlur, value, name } }: { field: ControllerRenderProps<any, any> }) => {
      const parseNumOrUndefined = (value: string) => {
        const numberValue = allowDecimal ? Number.parseFloat(value) : Number.parseInt(value);
        return Number.isNaN(numberValue) ? "" : numberValue;
      };
      const handleChange = (values: NumberFormatValues) => {
        const { value } = values;
        onChange(parseNumOrUndefined(value));
      };
      return (
        <NumericFormat
          autoFocus={props.autoFocus}
          thousandSeparator
          prefix="$"
          customInput={Input}
          className={cn(props.inputClassName)}
          placeholder={props.placeholder}
          name={name}
          required={props.required}
          datatype="currency"
          value={value}
          decimalScale={allowDecimal ? 2 : 0}
          onBlur={onBlur}
          onValueChange={handleChange}
        />
      );
    }}
  />
);
