import { cva, type VariantProps } from "class-variance-authority";
import { Fragment, useState } from "react";
import { useMatches } from "react-router";
import { NavLink, NavLinkProps, useSearchParams } from "react-router-dom";

import { cn } from "src/utils";
import { Handle } from "../breadcrumbs";
import { Button } from "./button";
import { Icon } from "./icon";

const barVariants = cva(
  "bg-inherit box-content flex flex-none font-medium gap-3 items-center justify-between px-6 text-xs",
  {
    variants: {
      as: {
        nav: "",
        footer: "py-3",
        header: "h-12",
      },
      position: {
        relative: "relative",
        sticky: "sticky z-20",
      },
    },
    compoundVariants: [
      {
        as: "header",
        position: "sticky",
        className: "peer top-0 peer-[&]:top-12 border-b",
      },
      {
        as: "footer",
        position: "sticky",
        className: "bottom-0 border-t",
      },
    ],
    defaultVariants: {
      as: "header",
      position: "sticky",
    },
  }
);

interface BarProps extends React.HTMLAttributes<HTMLElement>, VariantProps<typeof barVariants> {}

export const Bar = ({ as, position, className, ...props }: BarProps) => {
  const Component = as ?? "header";
  return <Component className={cn(barVariants({ as, position }), className)} {...props} />;
};

export const BarLinks = ({ children }: React.HTMLAttributes<HTMLElement>) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <Button
        variant="ghost"
        display="icon"
        onClick={() => setExpanded((expanded) => !expanded)}
        className="@xl/opp:hidden -mx-3"
      >
        <Icon icon="menu" />
      </Button>
      <nav
        className={cn(
          !expanded && "hidden",
          "absolute bg-accent/85 backdrop-blur-sm border-t h-dvh inset-0 @xl:hidden overflow-auto p-6 pb-32 space-y-4 text-lg top-12 z-10"
        )}
        onClick={() => setExpanded(false)}
      >
        {children}
      </nav>
      <nav className="@xl/opp:flex flex-auto gap-6 hidden self-stretch">{children}</nav>
    </>
  );
};

export const BarLink = ({ to, children, className, ...props }: NavLinkProps) => {
  const [searchParams] = useSearchParams();
  const params = searchParams ? `?${searchParams.toString()}` : "";

  return (
    <NavLink
      className={({ isActive }: { isActive: boolean }) =>
        cn(
          "flex-none flex gap-2 items-center transition-colors",
          isActive ? "text-primary @xl/opp:shadow-[0_1px_hsla(var(--primary))]" : "text-muted-foreground",
          className
        )
      }
      to={`${to as string}${params}`}
      role="link"
      {...props}
    >
      {children}
    </NavLink>
  );
};

export const BarMenu = () => {
  const matches = useMatches();
  const menus = matches
    .filter((match) => Boolean(match.handle && (match.handle as Handle).menu))
    .map((match) => (match.handle as Handle).menu);

  return (
    <div className="flex gap-3 items-center">
      {menus.map((menu, i) => (
        <Fragment key={i}>{menu}</Fragment>
      ))}
    </div>
  );
};
