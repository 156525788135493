import { Link } from "react-router-dom";

import { StateIcon } from "@/components/state";
import { Button } from "@/components/ui/button";
import { useAccount } from "@/hooks/use-account";
import { sentToAgent } from "@/insured/sidebar/sidebar-quotes";
import { QuoteState, SubmissionFragment } from "src/generated/graphql";
import { formatDollars } from "src/utils/strings";

export const SubmissionQuotes = ({ submission }: { submission: SubmissionFragment }) => {
  const { user } = useAccount();
  const { quotes } = submission;

  const validQuotes = user.internal
    ? quotes.filter((quote) => quote.state !== QuoteState.Invalid)
    : quotes.filter((quote) => sentToAgent(quote));

  return (
    <>
      {validQuotes.map((quote) => (
        <Button key={quote.id} variant="ghost" size="xs" className="shadow-sm" asChild>
          <Link to={`/insured/${quote.insuredId}/quotes/${quote.id}`}>
            <StateIcon state={quote.state} />
            <span className="font-semibold text-[11px]">
              {quote.premium ? formatDollars(quote.premium) : "Unprocessed"}
            </span>
          </Link>
        </Button>
      ))}
    </>
  );
};
