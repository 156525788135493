import { useAtom } from "jotai";
import { useParams } from "react-router";

import { bankanTabAtom, pinnedInsuredsAtom } from "@/atoms";
import { adaptSearchParams } from "@/components/adapt-search-params";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { BankanPolicyColumn, BankanQuoteColumn, MarketingPlanBankanColumn, Roles } from "src/generated/graphql";
import { InsuredColumn } from "./insured-column";
import { MarketingPlanColumn } from "./marketing-plan-column";
import { PolicyColumn } from "./policy-column";
import { QuoteColumn } from "./quote-column";
import { UserColumn } from "./user-column";

const brokerMarketingPlanColumns = [
  MarketingPlanBankanColumn.WaitingForCoverages,
  MarketingPlanBankanColumn.InformationGathering,
  MarketingPlanBankanColumn.InformationReview,
  MarketingPlanBankanColumn.MarketingInfoRequired,
  MarketingPlanBankanColumn.MarketingReady,
];

const brokerQuoteColumns = [
  BankanQuoteColumn.ReadyToSend,
  BankanQuoteColumn.Delivered,
  BankanQuoteColumn.BindRequestReceived,
];

const djMarketingPlanColumns = [
  MarketingPlanBankanColumn.UnassignedInformationGathering,
  MarketingPlanBankanColumn.UnassignedWaitingForCoverages,
  MarketingPlanBankanColumn.UnassignedOther,
];

const uaPolicyColumns = [
  BankanPolicyColumn.AwaitingBinderFromCarrier,
  BankanPolicyColumn.ReceivedBinderFromCarrier,
  BankanPolicyColumn.BinderProcessed,
  BankanPolicyColumn.IssuedEndorsementsRequested,
  BankanPolicyColumn.ExpiringSoon,
];

const statesByRole = (role: Roles) => {
  switch (role) {
    case Roles.Dj:
      return {
        planColumns: djMarketingPlanColumns,
        quoteColumns: [],
        policyColumns: [],
      };
    case Roles.UnderwritingAssistant:
      return {
        planColumns: [],
        quoteColumns: [BankanQuoteColumn.BindRequestReceived],
        policyColumns: uaPolicyColumns,
      };
    case Roles.Arm:
      return {
        planColumns: [...djMarketingPlanColumns, ...brokerMarketingPlanColumns],
        quoteColumns: brokerQuoteColumns,
        policyColumns: uaPolicyColumns,
      };
    default:
      return { planColumns: brokerMarketingPlanColumns, quoteColumns: brokerQuoteColumns, policyColumns: [] };
  }
};

export const BankanColumns = () => {
  const [pinnedInsureds] = useAtom(pinnedInsuredsAtom);
  const [selectedRole] = useAtom(bankanTabAtom);
  const { brokerId: brokerIdParam } = useParams();
  const [searchParams] = useUpsertSearchParams();
  const { ...filterArgs } = adaptSearchParams(searchParams);

  const brokerId = brokerIdParam === "unassigned" ? null : brokerIdParam || undefined;
  const { planColumns, quoteColumns, policyColumns } = statesByRole(selectedRole);

  return (
    <div className="flex max-md:flex-col flex-auto overflow-auto">
      {selectedRole === Roles.Dj && <UserColumn agencyId={filterArgs.agencyId} />}
      {selectedRole === Roles.Dj && <InsuredColumn agencyId={filterArgs.agencyId} />}
      {planColumns.map((column) => (
        <MarketingPlanColumn
          key={column}
          column={column}
          agencyId={filterArgs.agencyId}
          pinnedInsureds={Object.keys(pinnedInsureds)}
          {...(selectedRole === Roles.Arm ? { ownerId: brokerId } : { brokerId })}
        />
      ))}
      {quoteColumns.map((column) => (
        <QuoteColumn
          key={column}
          column={column}
          agencyId={filterArgs.agencyId}
          pinnedInsureds={Object.keys(pinnedInsureds)}
          {...(selectedRole === Roles.Arm ? { ownerId: brokerId } : { brokerId })}
        />
      ))}
      {policyColumns.map((column) => (
        <PolicyColumn
          key={column}
          column={column}
          agencyId={filterArgs.agencyId}
          pinnedInsureds={Object.keys(pinnedInsureds)}
          {...(selectedRole === Roles.Arm ? { ownerId: brokerId } : { brokerId })}
        />
      ))}
    </div>
  );
};
