import { groupBy, orderBy } from "lodash";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRowHeader, GridRowLink } from "@/components/grid";
import { SectionHeader, SectionTitle } from "@/components/section";
import { StateIndicator } from "@/components/state";
import { useInsured } from "@/hooks/use-insured";
import { formatTimezoneDateString } from "src/utils/date";

export const MarketingPlans = () => {
  const { opportunities, opportunitiesLoading } = useInsured();

  const sortedPlans = orderBy(opportunities, (plan) => Date.parse(plan.desiredEffectiveDate));
  const groupedPlans = groupBy(sortedPlans, (plan) => formatTimezoneDateString(plan.desiredEffectiveDate, "yyyy"));

  return (
    <>
      {opportunities[0] ? (
        Object.keys(groupedPlans).map((date) => (
          <div key={date}>
            <SectionHeader>
              <SectionTitle>
                <h2>{date}</h2>
              </SectionTitle>
            </SectionHeader>
            <Grid className="grid-cols-4">
              <GridRowHeader>
                <GridCell>Coverage</GridCell>
                <GridCell>Status</GridCell>
                <GridCell>Desired Eff. Date</GridCell>
                <GridCell>Broker</GridCell>
              </GridRowHeader>
              {groupedPlans[date].map((plan) => {
                const { id, broker, desiredEffectiveDate, selectedLinesOfBusiness, state } = plan;
                return (
                  <GridRowLink key={id} to={id}>
                    <GridCell>{selectedLinesOfBusiness.join(", ")}</GridCell>
                    <StateIndicator state={state} />
                    <GridCell>{formatTimezoneDateString(desiredEffectiveDate, "MMM d, yyyy")}</GridCell>
                    <GridCell>
                      {broker?.firstName} {broker?.lastName}
                    </GridCell>
                  </GridRowLink>
                );
              })}
            </Grid>
          </div>
        ))
      ) : (
        <EmptyState loading={opportunitiesLoading} title={<h2>No marketing plans found.</h2>} />
      )}
    </>
  );
};
