import { forwardRef, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import { Button, ButtonProps } from "@/components/ui/button";

export interface FormResetProps extends ButtonProps {}

export const FormReset = forwardRef<HTMLButtonElement, FormResetProps>(
  ({ children = "Cancel", variant = "outline", onClick, disabled, ...props }, ref) => {
    const { formState, reset } = useFormContext();

    const isDisabled = useMemo(() => {
      if (typeof disabled === "boolean") {
        return disabled;
      }

      return formState.isSubmitting;
    }, [disabled, formState.isSubmitting]);

    const handleClick: FormResetProps["onClick"] = (event) => {
      reset();
      onClick?.(event);
    };

    return (
      <Button ref={ref} {...props} type="button" variant={variant} disabled={isDisabled} onClick={handleClick}>
        {children}
      </Button>
    );
  }
);

FormReset.displayName = "FormReset";
