import { FC } from "react";

import { SidebarSection } from "@/components/sidebar";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useLoadFromDoc } from "./use-load-from-doc";

export const UploadTipCard: FC = () => {
  const { onClick, ...docUploadMethods } = useLoadFromDoc();
  const { dragging, loading, completed, fileName, fileUrl, reset } = docUploadMethods;

  const canUpload = !loading && !completed;

  return (
    <SidebarSection className="text-xs" onClick={(e) => canUpload && onClick?.(e)}>
      <h6 className="text-muted-foreground">Speed through the submission process by starting from an ACORD 125!</h6>
      {dragging ? (
        <p>Drop file to start upload.</p>
      ) : loading ? (
        <Spinner />
      ) : completed ? (
        <div className="flex items-center justify-between">
          <a className="w-full overflow-x-scroll" href={fileUrl} target="_blank" rel="noreferrer">
            {fileName}
          </a>
          <Button variant="ghost" display="icon" size="sm" className="-my-1.5" onClick={reset}>
            <Icon icon="close" />
          </Button>
        </div>
      ) : (
        <p>Drag and drop anywhere on this page, or click here to start an upload.</p>
      )}
    </SidebarSection>
  );
};

export const SubmissionTipCard = () => (
  <SidebarSection className="text-xs">
    <h6 className="text-muted-foreground">
      Providing a detailed Business Description will help us automatically classify the business.
    </h6>
    <p>A good description includes the following information:</p>
    <ul className="list-disc list-inside">
      <li>Industry Class</li>
      <li>Operations</li>
      <li>Key Exposures</li>
      <li>Known Business Classification Codes</li>
    </ul>
    <p>
      We&apos;ll use this information to automatically determine <span className="font-semibold">CGL</span> and{" "}
      <span className="font-semibold">NAICS</span> codes for the applicant in the next step of the submission process.
    </p>
  </SidebarSection>
);

export const OrEmail = () => (
  <SidebarSection className="text-xs">
    <h6 className="text-muted-foreground">Email Your Submission</h6>
    <p>
      You can always send your submissions to{" "}
      <a href="mailto:submissions@quotewell.com" className="text-primary hover:underline">
        submissions@quotewell.com
      </a>
    </p>
  </SidebarSection>
);
