import { ApolloError } from "@apollo/client";
import { createContext, useContext } from "react";
import { useParams } from "react-router";

import { Spinner } from "@/components/ui/loading";
import { PageNotFound } from "@/page-not-found";
import {
  InsuredOpportunitiesQuery,
  InsuredWithDuplicateFlagQuery,
  QuotesQuery,
  useInsuredOpportunitiesQuery,
  useInsuredWithDuplicateFlagQuery,
  useQuotesQuery,
  useVerticalByCglQuery,
  VerticalByCglQuery,
} from "src/generated/graphql";

interface InsuredContextValue {
  error?: ApolloError;
  insured: NonNullable<InsuredWithDuplicateFlagQuery["insured"]>;
  insuredId: string;
  loading: boolean;
  opportunities: InsuredOpportunitiesQuery["insuredOpportunities"];
  opportunitiesLoading: boolean;
  quotes: QuotesQuery["quotes"];
  quotesLoading: boolean;
  refetch: () => void;
  verticalByCGL?: VerticalByCglQuery["verticalByCGL"];
}

const InsuredContext = createContext({} as InsuredContextValue);

export const InsuredProvider = ({ children }: { children: React.ReactNode }) => {
  const { insuredId } = useParams<"insuredId">();

  const {
    data: { insured } = {},
    error,
    loading,
    refetch,
  } = useInsuredWithDuplicateFlagQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { verticalByCGL } = {} } = useVerticalByCglQuery({
    variables: { input: { isoCglCode: insured?.isoCglCodes[0] || "" } },
    skip: !insured?.isoCglCodes[0],
    fetchPolicy: "cache-and-network",
  });

  const {
    data: { insuredOpportunities: opportunities } = { insuredOpportunities: [] },
    loading: opportunitiesLoading,
  } = useInsuredOpportunitiesQuery({
    variables: {
      id: insuredId ?? "",
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  const { data: { quotes } = { quotes: [] }, loading: quotesLoading } = useQuotesQuery({
    variables: {
      input: {
        insuredId: insuredId,
      },
    },
    skip: !insuredId,
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return <Spinner className="mx-6 my-4" />;
  }

  if (!insuredId || !insured) {
    return <PageNotFound />;
  }

  const value = {
    error,
    insured,
    insuredId,
    loading,
    opportunities,
    opportunitiesLoading,
    quotes,
    quotesLoading,
    refetch,
    verticalByCGL,
  };

  return <InsuredContext.Provider value={value}>{children}</InsuredContext.Provider>;
};

export const useInsured = () => {
  const context = useContext(InsuredContext);

  if (!context) {
    throw new Error("useInsured must be used within an InsuredProvider");
  }

  return context;
};
