import { useQuoteQuery } from "../../../generated/graphql";
import { useUpsertSearchParams } from "../../hooks/use-upsert-search-params";
import { QuoteProposalForm } from "../../quotes/quote-proposal-form";

export default function QuoteTransitionGraphGenerateCoverLetter() {
  const [searchParams] = useUpsertSearchParams();
  const quoteId = searchParams.get("quoteId");

  const { data } = useQuoteQuery({
    variables: {
      id: quoteId!,
    },
    skip: !quoteId,
  });

  if (!data?.quote) {
    return null;
  }

  return <QuoteProposalForm quote={data?.quote} />;
}
