import { Link, useNavigate } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";

import { EmptyState } from "@/components/empty-state";
import { Grid, GridCell, GridRow, GridRowHeader } from "@/components/grid";
import { Group } from "@/components/group";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { useAppetiteNotesQuery } from "src/generated/graphql";

export function AppetiteNotes() {
  const navigate = useNavigate();
  const { data: { appetiteNotes } = { appetiteNotes: [] }, loading } = useAppetiteNotesQuery();
  useDocumentTitle("Admin: Appetite Notes");

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Appetite Notes</h1>
          {loading ? (
            <Spinner />
          ) : (
            <Button variant="outline" size="sm" asChild>
              <Link to="create">Create New Note</Link>
            </Button>
          )}
        </SectionTitle>
      </SectionHeader>
      {appetiteNotes[0] ? (
        <Grid className="grid-cols-[4fr_1fr_2fr_8fr_1.5rem]">
          <GridRowHeader>
            <GridCell>CGL(s)</GridCell>
            <GridCell>State(s)</GridCell>
            <GridCell>Line(s) Of Business</GridCell>
            <GridCell>Note</GridCell>
          </GridRowHeader>
          {appetiteNotes.map((note) => (
            <GridRow key={note.id}>
              <Pills items={note.isoCglCodes} />
              <Pills items={note.states} />
              <Pills items={note.lobs} />
              <GridCell>{note.note}</GridCell>
              <Button variant="ghost" size="sm" display="icon" onClick={() => navigate(`${note.id}/edit`)}>
                <Icon icon="edit" />
              </Button>
            </GridRow>
          ))}
        </Grid>
      ) : (
        <EmptyState loading={loading} title={<h2>No appetite notes found.</h2>} />
      )}
    </>
  );
}

const Pills: React.FC<{ items: string[] }> = ({ items }) => (
  <Group type="flex" direction="row" className="flex-wrap gap-1">
    {items.map((item) => (
      <Badge variant="secondary" key={item}>
        {item}
      </Badge>
    ))}
  </Group>
);
