import { usePasswordReset } from "@cp/auth";
import { LoaderFunctionArgs } from "react-router";
import { Link } from "react-router-dom";
import { z } from "zod";

import { CardContent, CardFooter } from "@/components/ui/card";
import { isGraphQLErrorResponse, StatusCodes } from "src/utils/errors";
import { createUrlParamsValidator, useTypedLoaderData } from "src/utils/router";
import { Group } from "../components/group";
import { Button } from "../components/ui/button";
import { FieldInput } from "../forms-v2/fields/field-input";
import { Form } from "../forms-v2/form";
import { FormSubmit } from "../forms-v2/form-submit";
import { AuthCard } from "./auth-card";

interface LoginFormData {
  password: string;
  confirmPassword: string;
}

const getUrlParams = createUrlParamsValidator(
  z.object({
    changePasswordId: z.string(),
  })
);

const validationSchema = z
  .object({
    password: z.string().min(8, { message: "Password must be at least 8 characters" }),
    confirmPassword: z.string().min(1, { message: "Please confirm your password" }),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (!password) {
      return;
    }

    if (confirmPassword !== password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Passwords don't match",
        path: ["confirmPassword"],
      });
    }
  });

const defaultValues = {
  password: "",
  confirmPassword: "",
};

export const activateAccountLoader = async ({ params }: LoaderFunctionArgs) => {
  const { changePasswordId } = getUrlParams(params);
  return { changePasswordId };
};

export function ActivateAccount() {
  const { changePasswordId } = useTypedLoaderData<typeof activateAccountLoader>();

  const { mutateAsync, isSuccess, error } = usePasswordReset();

  const handleSubmit = async ({ password }: LoginFormData) =>
    await mutateAsync({ newPassword: password, changePasswordId });

  const requestLinkExpired =
    isGraphQLErrorResponse(error) &&
    error?.response.errors &&
    error.response.errors.some((error) => error.extensions.authError?.statusCode === StatusCodes.NotFound);

  // we may need to request a new link if the existing changePasswordId has expired
  if (requestLinkExpired) {
    // prompt to a password
    return (
      <AuthCard header="Create new password">
        <CardContent>
          The link is no longer valid. Your account may already be set up. If you forgot your password, you can{" "}
          <Button variant="link" theme="primary" asChild>
            <Link to="/forgot-password">reset it</Link>
          </Button>
          .
        </CardContent>
      </AuthCard>
    );
  }

  // successfully reset password
  if (isSuccess) {
    return (
      <AuthCard header="Create new password">
        <CardContent>
          Password successfully reset! Please{" "}
          <Button variant="link" theme="primary" asChild>
            <Link to="/login">Sign In</Link>
          </Button>
          .
        </CardContent>
      </AuthCard>
    );
  }

  // prompt to reset password
  return (
    <Form validationSchema={validationSchema} defaultValues={defaultValues} onSubmit={handleSubmit}>
      <AuthCard header="Create new password" subHeader="Please enter your new password below." error={error}>
        <CardContent>
          <Group className="gap-2">
            <FieldInput type="password" name="password" placeholder="Password" />
            <FieldInput type="password" name="confirmPassword" placeholder="Confirm Password" />
          </Group>
        </CardContent>
        <CardFooter>
          <FormSubmit className="flex-1">Set password</FormSubmit>
        </CardFooter>
      </AuthCard>
    </Form>
  );
}
