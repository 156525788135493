import { Outlet } from "react-router";

import { HasInternalRole } from "@/components/has-role";
import { SectionHeader, SectionTitle } from "@/components/section";
import { Bar, BarLink, BarLinks } from "@/components/ui/bar";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { SetEffectiveDate } from "./set-effective-date";

export const MarketingPlan = () => {
  const { opportunity } = useMarketingPlan();

  const { id, desiredEffectiveDate, selectedLinesOfBusiness, state } = opportunity;

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1 className="truncate">{selectedLinesOfBusiness?.join(", ") || "No Coverage Selected"}</h1>
        </SectionTitle>
        <SetEffectiveDate desiredEffectiveDate={desiredEffectiveDate} id={id} state={state} />
      </SectionHeader>
      <HasInternalRole>
        <Bar>
          <BarLinks>
            <BarLink to="." end>
              Details
            </BarLink>
            <BarLink to="coverage">Coverage</BarLink>
            <BarLink to="files">Files</BarLink>
            <BarLink to="history">History</BarLink>
            <BarLink to="alby">Alby</BarLink>
          </BarLinks>
        </Bar>
      </HasInternalRole>
      <Outlet />
    </>
  );
};
