import { useNavigate, useParams } from "react-router";

import React from "react";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Icon } from "../components/ui/icon";
import { AlbyApprovalProvider, useAlbyApproval } from "../hooks/use-alby-approval";
import { useUpsertSearchParams } from "../hooks/use-upsert-search-params";
import QuoteTransitionGraphGenerateCoverLetter from "./approval-forms/quote-transition-graph-generate-cover-letter";
import SubmissionTransitionGraphSubmitToCarrier from "./approval-forms/submission-transition-graph-submit-to-carrier";

const approvalFormMapping = {
  SubmissionTransitionGraph: {
    "submit-to-carrier": SubmissionTransitionGraphSubmitToCarrier,
  },
  QuoteTransitionGraph: {
    "generate-cover-letter": QuoteTransitionGraphGenerateCoverLetter,
  },
} as Record<string, Record<string, () => React.JSX.Element | null>>;

export default function AlbyApproval() {
  const { graphName, insuredId, selectedTool } = useParams();
  const [searchParams] = useUpsertSearchParams();

  if (!graphName || !insuredId || !selectedTool) {
    return null;
  }

  const ApprovalForm = approvalFormMapping[graphName]?.[selectedTool] ?? DefaultApprovalForm;

  return (
    <AlbyApprovalProvider
      graphName={graphName}
      insuredId={insuredId}
      selectedTool={selectedTool}
      invokeParams={[...searchParams.entries()].reduce<Record<string, string>>((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, string>)}
    >
      <ApprovalForm />
    </AlbyApprovalProvider>
  );
}

const DefaultApprovalForm = () => {
  const { threadId, graphName, selectedTool, header, body, approve, decline, resumeLoading } = useAlbyApproval();
  const navigate = useNavigate();

  if (!threadId) {
    return null;
  }

  return (
    <div className="mt-6 px-4">
      <Card className="hover-outline outline-primary">
        <CardHeader>
          <CardTitle className="text-primary">
            <Icon icon="smart_toy" />
            <span className="mr-auto">{header ?? `${graphName} - ${selectedTool}`}</span>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">{body && <p>{formatBody(body)}</p>}</CardContent>
        <CardFooter className="flex justify-end gap-2">
          <Button
            type="submit"
            theme="primary"
            disabled={resumeLoading}
            onClick={() =>
              approve(
                {},
                {
                  onCompleted() {
                    navigate(-1);
                  },
                }
              )
            }
          >
            Approve
          </Button>
          <Button
            type="submit"
            theme="destructive"
            disabled={resumeLoading}
            onClick={() =>
              decline({
                onCompleted() {
                  navigate(-1);
                },
              })
            }
          >
            Decline
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

// Slack sections are formatted as <https://example.com|example.com> and we want to convert them to a clickable link
const formatBody = (body: string) => {
  const regex = /<(https?:\/\/[^|]+)\|([^>]+)>/;
  const matches = body.match(regex);

  if (!matches) {
    return body;
  }

  const [fullMatch, url, label] = matches;
  const parts = body.split(fullMatch);

  return (
    <span>
      {parts[0]}
      <a href={url} className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">
        {label}
      </a>
      {parts[1]}
    </span>
  );
};
