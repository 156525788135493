import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { HasInternalRole } from "@/components/has-role";
import { FieldCombobox } from "@/forms-v2/fields/field-combobox";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldRow } from "@/forms-v2/fields/field-row";
import { useAccount } from "@/hooks/use-account";
import { useAgenciesQuery, useAgentAccountsQuery } from "src/generated/graphql";

export const AgentForm: FC = () => {
  const { setValue } = useFormContext();
  const { user } = useAccount();

  const { data: { userAccounts: agents = [] } = {} } = useAgentAccountsQuery({ skip: !user.internal });
  const { data: { agencies = [] } = {} } = useAgenciesQuery({ skip: !user.internal });

  return (
    <HasInternalRole>
      <div className="bg-border divide-y">
        <FieldRow icon="person_search">
          <FieldCombobox
            name="agentId"
            placeholder="Search agents"
            options={agents.map((agent) => ({
              label: `${agent.firstName} ${agent.lastName} (${agent.agency.name})`,
              value: agent.id,
              keywords: [agent.firstName, agent.lastName, agent.agency.name, agent.email],
            }))}
            onChange={(agentId) => {
              const agent = agents.find((agent) => agent.id === agentId);

              if (!agent) {
                return;
              }

              setValue("firstName", agent.firstName, { shouldValidate: true });
              setValue("lastName", agent.lastName, { shouldValidate: true });
              setValue("agentEmail", agent.email, { shouldValidate: true });
              setValue("agencyId", agent.agencyId, { shouldValidate: true });
            }}
            borderless
          />
        </FieldRow>

        <FieldRow icon="badge">
          <FieldInput name="firstName" placeholder="First Name" onChange={() => setValue("agentId", "")} borderless />
        </FieldRow>

        <FieldRow icon="badge">
          <FieldInput name="lastName" placeholder="Last name" onChange={() => setValue("agentId", "")} borderless />
        </FieldRow>

        <FieldRow icon="alternate_email">
          <FieldInput name="agentEmail" placeholder="Email" onChange={() => setValue("agentId", "")} borderless />
        </FieldRow>

        <FieldRow icon="screen_search_desktop">
          <FieldCombobox
            name="agencyId"
            placeholder="Search agencies"
            options={agencies.map((agency) => ({ label: agency.name, value: agency.id, keywords: [agency.name] }))}
            onChange={() => setValue("agentId", "")}
            borderless
          />
        </FieldRow>
      </div>
    </HasInternalRole>
  );
};
