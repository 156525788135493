import { type ComponentProps, type FC } from "react";
import { useController, useFormContext } from "react-hook-form";
import { PatternFormat } from "react-number-format";

import { Input } from "@/components/ui/input";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME } from "./field.constants";

export type FieldPatternProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  format: ComponentProps<typeof PatternFormat>["format"];
  mask?: ComponentProps<typeof PatternFormat>["mask"];
  onChange?: ComponentProps<typeof PatternFormat>["onValueChange"];
  onBlur?: ComponentProps<typeof PatternFormat>["onBlur"];
  inputProps?: Omit<ComponentProps<typeof PatternFormat>, "format"> & { format?: string };
};

export const FieldPattern: FC<FieldPatternProps> = ({
  placeholder,
  borderless,
  format,
  mask,
  onChange,
  onBlur,
  inputProps,
  disabled,
  readOnly,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled });
  // !!IMPORTANT!!: We need to destructure the `onChange` from the `field` object to avoid passing
  // it to the `PatternFormat` component, because it is overriding the `onValueChange` prop.
  const { onChange: onFieldChange, ref: fieldRef, ...restField } = field;

  const error = !!formState.errors[props.name];

  const handleChange: FieldPatternProps["onChange"] = (value, sourceInfo) => {
    field.onChange(value.formattedValue);
    onChange?.(value, sourceInfo);
  };

  const handleBlur: FieldPatternProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <PatternFormat
        readOnly={readOnly}
        format={format}
        mask={mask}
        {...restField}
        {...inputProps}
        placeholder={placeholder}
        onValueChange={handleChange}
        onBlur={handleBlur}
        customInput={Input}
        getInputRef={fieldRef}
        className={cn(
          {
            "border-destructive": !!error,
            [FIELD_BORDERLESS_CLASS_NAME]: borderless,
          },
          inputProps?.className
        )}
      />
    </FieldBase>
  );
};
