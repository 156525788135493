import { uniqBy } from "lodash";
import { FormProvider, useForm } from "react-hook-form";

import { useModal } from "@/components/modal-provider";
import { AlertDialogCancel, AlertDialogFooter } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem, DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Label } from "@/components/ui/label";
import { Checkbox, Input } from "@/forms/default";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import { OpportunityDetailsFragment, useClientDataQuery, useCreateClientDataMutation } from "src/generated/graphql";

interface Rule {
  type: "client-data" | "state" | "minimum-premium" | "cgl";
  valueType: "text" | "boolean" | "number";
  key: string;
}

export default function RulesForm() {
  const { openModal } = useModal();
  const { opportunity } = useMarketingPlan();

  if (!opportunity.verticalMarketingPlanTemplate) {
    return null;
  }

  const { insuredId, verticalMarketingPlanTemplate } = opportunity;

  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        onClick={() =>
          openModal(() => <Form insuredId={insuredId} template={verticalMarketingPlanTemplate} />, {
            title: "Carrier Eligibility",
            description: "Complete this form to help refine market selection.",
          })
        }
      >
        <Icon icon="database" />
        Carrier Eligibility
      </DropdownMenuItem>
    </>
  );
}

function Form({
  insuredId,
  template,
}: {
  insuredId: string;
  template: NonNullable<OpportunityDetailsFragment["verticalMarketingPlanTemplate"]>;
}) {
  const { closeModal } = useModal();
  const formMethods = useForm();
  const { toast } = useToast();
  const [createClientData] = useCreateClientDataMutation({
    onCompleted: () => {
      toast({ title: "Saved" });
      closeModal();
    },
    onError: () => toast({ title: "Unable to save" }),
  });

  const rules = uniqBy(
    template.products
      .flatMap((p) => JSON.parse(p.rules) as Rule[])
      .flat()
      .filter((rule) => rule.type === "client-data"),
    (rule) => rule.key
  );

  useClientDataQuery({
    variables: {
      input: {
        insuredId,
        keys: rules.map((r) => r.key),
      },
    },
    onCompleted({ clientData }) {
      formMethods.reset(Object.fromEntries(clientData.map((cd) => [cd.key, cd.value])));
    },
  });

  const handleSubmit = async (fd: Record<string, string | number | boolean>) => {
    await createClientData({
      variables: {
        input: {
          insuredId,
          source: "CARRIER_ELIGIBILITY_FORM",
          clientDataInputs: Object.entries(fd).map(([key, value]) => {
            return {
              key,
              value,
            };
          }),
        },
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <div className="space-y-4">
          {rules.map((rule) => (
            <RuleInput key={rule.key} rule={rule} />
          ))}
        </div>
        <AlertDialogFooter className="mt-6">
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button type="submit">Submit</Button>
        </AlertDialogFooter>
      </form>
    </FormProvider>
  );
}

function RuleInput({ rule }: { rule: Rule }) {
  switch (rule.valueType) {
    case "text":
      return (
        <div className="space-y-2">
          <Label className="text-sm">{rule.key}</Label>
          <Input name={rule.key} />
        </div>
      );
    case "number":
      return (
        <div className="space-y-2">
          <Label className="text-sm">{rule.key}</Label>
          <Input type="number" name={rule.key} />
        </div>
      );
    case "boolean":
      return (
        <Label className="flex gap-2 items-center text-xs">
          <Checkbox name={rule.key} />
          {rule.key}
        </Label>
      );
  }
}
