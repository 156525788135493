import { UserAccountFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { HasExternalRole } from "../has-role";

interface Props {
  className?: string;
  header?: string;
  broker?: UserAccountFragment | null;
}

export const BrokerFace: React.FC<Props> = ({
  header = "Any questions? Click here to email us.",
  className,
  broker,
}) => {
  const to = broker ? broker.email : "submissions@quotewell.com";
  const cc = broker ? undefined : "submissions@quotewell.com";

  return (
    <HasExternalRole>
      <a
        href={`mailto:${to}?${
          cc && `cc=${cc}&`
        }subject=Request%20for%20Assistance&body=%5BHow%20can%20we%20help%20you%20today%3F%5D`}
        className={cn(
          "bg-linear-to-br from-primary to-purple-900 flex flex-col h-14 px-4 justify-center rounded self-center text-left text-background shadow-inner",
          className
        )}
        target="_blank"
        rel="noreferrer"
      >
        <div className="font-bold leading-none mb-2 text-xs">{header}</div>
        <div className="leading-none text-xs">Or, call us: (512) 795-4882</div>
      </a>
    </HasExternalRole>
  );
};
