import { ComponentProps, FC, HTMLProps } from "react";

import { Icon } from "@/components/ui/icon";

export interface FieldRowProps extends HTMLProps<HTMLLabelElement> {
  icon?: ComponentProps<typeof Icon>["icon"];
}

export const FieldRow: FC<FieldRowProps> = ({ children, icon, ...props }) => (
  <label className="bg-background flex items-start gap-6 py-1 pl-6 pr-6" {...props}>
    {icon && <Icon icon={icon} className="text-muted-foreground flex-none mt-3" />} {children}
  </label>
);
