import { uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { z } from "zod";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { SearchInput } from "@/components/ui/input";
import { Loading } from "@/components/ui/loading";
import { FieldCheckboxGroup } from "@/forms-v2/fields/field-checkbox-group";
import { useAppetiteFilterOptionsQuery, useCreateVerticalMarketingPlanTemplateMutation } from "src/generated/graphql";

import { useVertical } from "./use-vertical";

const CORE_LINES = new Set([
  "General Liability",
  "Property",
  "E&O/Professional Liability",
  "Workers Compensation",
  "Commercial Auto",
  "Excess Liability/Umbrella",
]);

export const AddTemplate = () => {
  const { verticalId } = useParams();
  const { openForm } = useModal();
  const { refetch } = useVertical();

  const [createVerticalMarketingPlanTemplateTrigger] = useCreateVerticalMarketingPlanTemplateMutation({
    onCompleted: refetch,
  });

  if (!verticalId) {
    return null;
  }

  const createVerticalMarketingPlan = async () =>
    await openForm(<SelectLinesOfBusiness />, {
      title: "Select LOBs",
      defaultValues: { lobs: [] },
      validationSchema: z.object({ lobs: z.array(z.string()) }),
      onSubmit: async (fd) =>
        createVerticalMarketingPlanTemplateTrigger({
          variables: { input: { verticalId: verticalId, lobs: fd.lobs.sort() } },
        }),
    });

  return (
    <Button variant="outline" size="xs" onClick={createVerticalMarketingPlan}>
      Add Template
    </Button>
  );
};

function SelectLinesOfBusiness() {
  const [term, setTerm] = useState("");
  const { data, loading } = useAppetiteFilterOptionsQuery({});

  const sortedVerticals = useMemo(
    () =>
      [...(data?.appetiteFilterOptions?.verticals || [])].sort(
        (a, b) => (b?.linesOfBusiness.length || 0) - (a?.linesOfBusiness.length || 0)
      ),
    [data?.appetiteFilterOptions?.verticals]
  );

  const linesOfBusiness = useMemo(
    () =>
      uniqBy(
        sortedVerticals.flatMap((vertical) => vertical.linesOfBusiness),
        (v) => v.name
      ).filter((l) => l.name.toLowerCase().includes(term.toLowerCase())),
    [sortedVerticals]
  );

  const coreLines = useMemo(
    () => linesOfBusiness.filter((l) => CORE_LINES.has(l.name)).map((l) => ({ label: l.name, value: l.name })),
    [linesOfBusiness]
  );

  const otherLines = useMemo(
    () => linesOfBusiness.filter((l) => !CORE_LINES.has(l.name)).map((l) => ({ label: l.name, value: l.name })),
    [linesOfBusiness]
  );

  if (loading) {
    return <Loading label="Loading LOBs..." />;
  }

  return (
    <Group>
      <SearchInput
        name="search"
        value={term}
        placeholder="Search LOBs"
        onChange={(event) => setTerm(event.currentTarget.value)}
      />
      <FieldCheckboxGroup label="Core Lines" name="lobs" options={coreLines} />
      <FieldCheckboxGroup label="Other" name="lobs" options={otherLines} />
    </Group>
  );
}
