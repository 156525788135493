import { Check } from "lucide-react";

import { Combobox } from "@/components/ui/combobox";
import { CommandItem } from "@/components/ui/command";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { useAgenciesQuery } from "src/generated/graphql";
import { cn } from "src/utils";

export const SetAgencyParam = () => {
  const [searchParams, upsertSearchParams] = useUpsertSearchParams();
  const agencyId = searchParams.get("agencyId");

  const { data: agenciesData } = useAgenciesQuery({
    fetchPolicy: "cache-first",
  });

  return (
    <Combobox
      value={agencyId ? agenciesData?.agencies.find((agency) => agency.id === agencyId)?.name : "Select Agency"}
      placeholder="Search Agencies..."
      empty="No agencies found."
    >
      {agenciesData?.agencies.map((agency) => (
        <CommandItem
          key={agency.id}
          value={agency.name.toLowerCase().trim()}
          onSelect={() => upsertSearchParams({ agencyId: agency.id })}
        >
          <Check className={cn("mr-2 h-4 w-4", agencyId === agency.id ? "opacity-100" : "opacity-0")} />
          <span className="truncate">{agency.name}</span>
        </CommandItem>
      ))}
    </Combobox>
  );
};
