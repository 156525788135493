import { NodeToolbar as ReactFlowNodeToolbar, Position } from "@xyflow/react";
import { FC, MouseEvent, ReactNode } from "react";

import { Toolbar, ToolbarMenu, ToolbarMenuItem, ToolbarSeparator } from "@/components/ui/toolbar";
import { cn } from "src/utils";

import { useFileProcessingPipelineState } from "../../../file-processing-pipeline.provider";

export enum NodeActionType {
  Item = "item",
  Separator = "separator",
}

export interface NodeAction {
  id?: string;
  type?: NodeActionType;
  icon?: ReactNode;
  label?: string;
  onClick?: (event: any) => void;
  isDefault?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
}

export interface NodeToolbarProps {
  isVisible?: boolean;
  actions?: NodeAction[];
}

export const NodeToolbar: FC<NodeToolbarProps> = ({ isVisible, actions }) => {
  const { isTransitioning } = useFileProcessingPipelineState();

  const handleActionDoubleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
  };

  if (!actions?.length) {
    return null;
  }

  return (
    <ReactFlowNodeToolbar
      position={Position.Bottom}
      isVisible={isVisible}
      className={cn({ "pointer-events-none!": isTransitioning })}
    >
      <Toolbar orientation="horizontal" popoverSide="bottom">
        <ToolbarMenu>
          {actions.map((action, i) => {
            if (action.isHidden) {
              return null;
            }

            if (action.type === NodeActionType.Separator) {
              return <ToolbarSeparator key={i} />;
            }

            if (!action.icon) {
              return null;
            }

            return (
              <ToolbarMenuItem
                key={action.id}
                icon={action.icon}
                label={action.label}
                onClick={action.onClick}
                buttonProps={{ onDoubleClickCapture: handleActionDoubleClick, disabled: action.isDisabled }}
              />
            );
          })}
        </ToolbarMenu>
      </Toolbar>
    </ReactFlowNodeToolbar>
  );
};
