import React from "react";

import { Tag, TagInput } from "@/components/tag/tag-input";
import { useToast } from "@/hooks/use-toast";
import { useFileTagsQuery, useFindFileUploadQuery, useUpdateFileUploadTagsMutation } from "src/generated/graphql";

export default function TagSelector({ fileId }: { fileId: string }) {
  const { toast } = useToast();

  const { data: fileData, refetch } = useFindFileUploadQuery({ variables: { input: { id: fileId } } });
  const { data } = useFileTagsQuery({ variables: { input: {} } });

  const [update] = useUpdateFileUploadTagsMutation();

  const add = (value: string) => {
    void update({
      variables: { input: { id: fileId, label: value } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => {
        toast({ title: `Tag added` });
        void refetch();
      },
      refetchQueries: ["FileTags", "FilesByLabel"],
    });
  };

  const remove = (value: string) => {
    void update({
      variables: { input: { id: fileId, label: value } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => {
        toast({ title: `Tag removed` });
        void refetch();
      },
      refetchQueries: ["FileTags"],
    });
  };

  const options =
    data?.fileTags
      .map((o) => ({ id: o.label, text: o.label }))
      .filter((t) => !t.text.includes("to:") || !t.text.includes("from:")) ?? [];

  const [, setTags] = React.useState<Tag[]>([]);

  return (
    <TagInput
      enableAutocomplete={true}
      autocompleteOptions={options}
      placeholder="Search For Labels"
      tags={fileData?.findFile.labels.map((l) => ({ id: l, text: l })) ?? []}
      className="sm:min-w-[450px]"
      setTags={setTags}
      size="sm"
      onTagAdd={add}
      onTagRemove={remove}
    />
  );
}
