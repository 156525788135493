import { MouseEventHandler, useState } from "react";
import { useDropzone } from "react-dropzone";
import { z } from "zod";

import { Group } from "@/components/group";
import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { QuoteWellLogo } from "@/components/ui/quotewell-logo";
import { useTheme } from "@/hooks/use-theme";
import { useToast } from "@/hooks/use-toast";
import { usePublicCreateOpportunityMutation } from "src/generated/graphql";
import { cn } from "src/utils";

import { FirstSubmissionForm, FirstSubmissionFormSchema } from "./first-submission-form";

export type SetValuesFn = (keyVals: Array<[string, string | undefined]>) => void;

export const FirstSubmissionPage = () => {
  const { theme } = useTheme();
  const { openModal } = useModal();
  const [files, setFiles] = useState<File[]>([]);
  const { toast } = useToast();

  const [createOpportunity] = usePublicCreateOpportunityMutation();

  const onDrop = async (acceptedFiles: File[]) => setFiles((prevState) => [...prevState, ...acceptedFiles]);

  const reset = () => setFiles([]);

  const { getRootProps } = useDropzone({ onDrop });
  const { onClick, ...rootProps } = getRootProps();

  const onSubmit = async (values: z.infer<typeof FirstSubmissionFormSchema>) => {
    let success = true;

    const res = await createOpportunity({
      variables: {
        input: {
          clientInput: {
            name: values.clientName,
            primaryState: values.clientState,
            revenue: values.revenue,
            description: values.description,
          },
          desiredEffectiveDate: values.desiredEffectiveDate?.toISOString(),
          agentInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.agentEmail,
            phone: values.agentPhone,
            agencyName: values.agencyName,
            agencyState: values.agencyPrimaryState,
          },
        },
      },
      onError: async () => {
        success = false;
        await openModal(() => <></>, {
          type: "dialog",
          title: "There was a problem!",
          description: (
            <>
              Please login or contact a colleague to invite you to the platform. Email us{" "}
              <Button variant="link" theme="primary" className="text-[length:inherit]" asChild>
                <a href={`mailto:support@quotewell.com`} target="_blank" rel="noreferrer">
                  support@quotewell.com
                </a>
              </Button>{" "}
              if you need further assistance.
            </>
          ),
        });
      },
      onCompleted: () => toast({ title: "Opportunity created. Now uploading file(s)." }),
    });

    const opportunityId = res.data?.publicCreateOpportunity.id;
    const createdById = res.data?.publicCreateOpportunity.createdById;

    if (files && opportunityId && createdById) {
      const fd = new FormData();
      files.map((file) => fd.append("files", file));

      const uploadResponse = await fetch(`/api/opportunities/${opportunityId}/${createdById}/files`, {
        method: "POST",
        body: fd,
      });

      if (!uploadResponse.ok) {
        success = false;
        await openModal(() => <></>, {
          type: "dialog",
          title: "Something went wrong!",
          description: (
            <>
              It looks like there was an issue uploading your files. Please check your email for an activation to your
              portal account, where you can view your submission, or email submissions@quotewell.com{" "}
              <Button variant="link" theme="primary" className="text-[length:inherit]" asChild>
                <a href={`mailto:submissions@quotewell.com`} target="_blank" rel="noreferrer">
                  submissions@quotewell.com
                </a>
              </Button>
              .
            </>
          ),
        });
      }
    }

    if (success) {
      await openModal(() => <></>, {
        title: "Thank you for submitting!",
        description: "Check your email for an activation to your portal account.",
      });
    }
  };

  return (
    <div className="flex flex-col gap-4 max-w-6xl mx-auto p-4" {...rootProps}>
      <div className="flex justify-between">
        <QuoteWellLogo className={cn("size-14", { invert: theme === "dark" })} />
        <Button theme="primary" asChild>
          <a href="/login">Login</a>
        </Button>
      </div>
      <h1>Ready to send us your submission?</h1>
      <div>
        We’re excited to work together! Please answer the following questions about your business and the risk you are
        looking to place today.
      </div>
      <div>
        Questions? Email us at{" "}
        <Button variant="link" theme="primary" className="text-[length:inherit]" asChild>
          <a href={`mailto:support@quotewell.com`} target="_blank" rel="noreferrer">
            support@quotewell.com
          </a>
        </Button>
      </div>
      <div>
        If you already have an account with us, please{" "}
        <Button variant="link" theme="primary" className="text-[length:inherit]" asChild>
          <a href="/login">login</a>
        </Button>
        ! If a colleague at your agency has an account, please contact them for an invitation.
      </div>
      <div className="flex flex-col md:flex-row flex-auto gap-4 mt-4">
        <FirstSubmissionForm onSubmit={onSubmit} />
        <div className="flex-none space-y-4 sm:w-1/3">
          <SubmissionTipCard />
          <PublicUploadTipCard clickToUpload={onClick} files={files} reset={reset} />
        </div>
      </div>
    </div>
  );
};

export const SubmissionTipCard = () => (
  <Card>
    <CardHeader>
      <CardTitle>Submission Tip</CardTitle>
      <CardDescription>
        Providing a detailed Business Description will help us automatically classify the business.
      </CardDescription>
    </CardHeader>
    <CardContent className="text-sm">
      <Group>
        <p>A good description includes the following information:</p>
        <ul className="list-disc list-inside">
          <li>Industry Class</li>
          <li>Operations</li>
          <li>Key Exposures</li>
          <li>Known Business Classification Codes</li>
        </ul>
        <p>
          We&apos;ll use this information to automatically determine <strong>CGL</strong> and <strong>NAICS</strong>{" "}
          codes for the applicant in the next step of the submission process.
        </p>
      </Group>
    </CardContent>
  </Card>
);

interface PublicUploadTipCardProps {
  clickToUpload?: MouseEventHandler<HTMLElement>;
  files: File[];
  reset: () => void;
}

const PublicUploadTipCard = ({ clickToUpload, files, reset }: PublicUploadTipCardProps) => (
  <Card onClick={(e) => clickToUpload?.(e)}>
    <CardHeader>
      <CardTitle>Upload Documents</CardTitle>
      <CardDescription>
        Drag and drop anywhere on this page, or{" "}
        <Button variant="link" theme="primary">
          click here
        </Button>{" "}
        to start an upload.
      </CardDescription>
    </CardHeader>

    {!!files?.length && (
      <CardContent className="flex flex-col text-sm leading-normal gap-4">
        <div className="border flex flex-col divide-y rounded-sm">
          {files?.map((file) => (
            <div key={file.name} className="px-3 py-2 text-sm">
              {file.name}
            </div>
          ))}
        </div>
        <div>
          {files.length > 0 && (
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                reset();
              }}
            >
              Clear
            </Button>
          )}
        </div>
      </CardContent>
    )}
  </Card>
);
