import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { ButtonProps } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { FormSubmit } from "@/forms-v2/form-submit";

// TODO: Remove the original `ContinueButton` once we have accounted for all usages.
// TODO: Need to make sure wherever this is used, the form is wrapped in a FormProvider.
export const ContinueButton: FC<ButtonProps> = (props) => {
  const { formState } = useFormContext();

  return (
    <FormSubmit {...props}>
      Continue
      {!formState.isSubmitting && <Icon icon="arrow_forward" />}
    </FormSubmit>
  );
};
