import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/loading";
import { toast } from "@/hooks/use-toast";
import { useState } from "react";
import { PolicyFileType, PolicyFragment } from "src/generated/graphql";
import { cn } from "src/utils";
import { uploadPolicyDocument } from "src/utils/file";

export const AwaitingPolicyFromCarrier = ({ policy, refetch }: { policy: PolicyFragment; refetch: () => void }) => {
  const { id: policyId, policyFileId } = policy;
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      {!policyFileId && (
        <Button asChild variant="outline" size="sm" display="flex">
          <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
            <input
              type="file"
              name="file"
              className="hidden"
              onChange={async (e) => {
                setSubmitting(true);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];

                  if (file.type !== "application/pdf") {
                    toast({ title: "We only accept PDF files" });
                    setSubmitting(false);
                    return;
                  }
                  if (!policy) {
                    toast({ title: "Error" });
                    setSubmitting(false);
                    return;
                  }

                  await uploadPolicyDocument(file, policyId, PolicyFileType.Policy);
                  refetch();
                }
                setSubmitting(false);
              }}
            />
            {submitting ? <Spinner /> : "Upload Policy File"}
          </label>
        </Button>
      )}
    </>
  );
};
