import { createBrowserRouter, Link, Navigate, Outlet, redirect, RouteObject } from "react-router-dom";

import { Roles } from "src/generated/graphql";
import { getAuthedUser } from "src/utils/get-authed-user";
import { Admin } from "./admin/admin";
import { AppetiteNote } from "./admin/appetite-notes/appetite-note";
import { AppetiteNotes } from "./admin/appetite-notes/appetite-notes";
import { Appetite } from "./admin/appetite/appetite";
import { Enablement } from "./admin/enablement/enablement";
import { Tags } from "./admin/tags/tags";
import AlbyApproval from "./alby/alby-approval";
import AlbyApprovals from "./alby/alby-approvals";
import { ActivateAccount, activateAccountLoader } from "./auth/activate-account";
import { AuthGuard } from "./auth/auth-guard";
import { ForgotPassword } from "./auth/forgot-password";
import { Login } from "./auth/login";
import { Bankan } from "./bankan/bankan";
import { Broker } from "./broker/broker";
import { BrokerActive } from "./broker/broker-active";
import { BrokerPinned } from "./broker/broker-pinned";
import { HasInternalRole, HasRole } from "./components/has-role";
import { ModalProvider } from "./components/modal-provider";
import { CenteredPage, Page } from "./components/page";
import { Icon } from "./components/ui/icon";
import { ErrorPage } from "./error";
import { DocumentLabelDetailsSidePanel } from "./file-processing-pipeline/document-label-details/document-label-details";
import { FileProcessingPipeline } from "./file-processing-pipeline/file-processing-pipeline";
import { FileProcessorDetailsSidePanel } from "./file-processing-pipeline/file-processor-details/file-processor-details";
import { IssuesSidePanel } from "./file-processing-pipeline/issues/issues";
import { Files } from "./files/files";
import { AccountProvider } from "./hooks/use-account";
import { InsuredProvider } from "./hooks/use-insured";
import { MarketingPlanProvider } from "./hooks/use-marketing-plan";
import { PageProvider } from "./hooks/use-page";
import { InsuredDetails } from "./insured/details/details";
import { InsuredDetailsMenu } from "./insured/details/details-menu";
import { Insured } from "./insured/insured";
import { InsuredEmail } from "./insured/insured-email";
import { InsuredRedirect } from "./insured/insured-redirect";
import { InsuredCrumb, InsuredRoot } from "./insured/insured-root";
import { Insureds } from "./insureds/insureds";
import { InsuredsActive } from "./insureds/insureds-active";
import { InsuredsAll } from "./insureds/insureds-all";
import { ExtractedLabels } from "./label-management/extracted-labels";
import { ExtractedLabel } from "./label-management/extracted-labels/details";
import { LabelManagement } from "./label-management/label-management";
import { PrimaryLabels } from "./label-management/primary-labels";
import { PrimaryLabel } from "./label-management/primary-labels/details";
import { Launch, LaunchAgencies } from "./launch/launch";
import { AgencyCrumb, LaunchAgency } from "./launch/launch-agency";
import { LaunchAgencyMenu } from "./launch/launch-agency-menu";
import { LaunchAgents } from "./launch/launch-agents";
import { Coverage } from "./marketing-plans/coverage";
import { EscapeHatch } from "./marketing-plans/escape-hatch";
import { History } from "./marketing-plans/history";
import { MarketingPlan } from "./marketing-plans/marketing-plan";
import { MarketingPlanDetails } from "./marketing-plans/marketing-plan-details";
import { MarketingPlanMenu } from "./marketing-plans/marketing-plan-menu";
import { MarketingPlans } from "./marketing-plans/marketing-plans";
import { SelectMarkets } from "./marketing-plans/select-markets/select-markets";
import { New } from "./new/new";
import { PageNotFound } from "./page-not-found";
import { Policies } from "./policies/policies";
import { Policy } from "./policies/policy";
import { PolicyMenu } from "./policies/policy-menu";
import { FirstSubmissionPage } from "./public/first-submission-page";
import { Bind } from "./quotes/bind/bind";
import { Quote } from "./quotes/quote";
import { QuoteMenu } from "./quotes/quote-menu";
import { Quotes } from "./quotes/quotes";
import { Supplementals } from "./supplementals/supplementals";
import { VerticalProvider } from "./verticals/components/use-vertical";
import { EditVertical } from "./verticals/edit-vertical";
import { Vertical, VerticalCrumb, VerticalMenu } from "./verticals/vertical";
import { VerticalAppetite } from "./verticals/vertical-appetite";
import { VerticalExclusionReasons } from "./verticals/vertical-exclusion-reasons";
import { VerticalHierarchy } from "./verticals/vertical-hierarchy";
import { VerticalRequirements } from "./verticals/vertical-requirements";
import { VerticalRules } from "./verticals/vertical-rules";
import { Verticals, VerticalsList } from "./verticals/verticals";

export const roleHomepageLoader = async () => {
  const { user } = (await getAuthedUser()) || {};
  let path = "/login";

  if (user) {
    if (user && user.roles.includes(Roles.Broker)) {
      path = `/bankan/${user.id}`;
    } else if (user.roles.includes(Roles.Admin)) {
      path = "/bankan";
    } else {
      path = "/insureds";
    }
  }

  return redirect(path);
};

const adminRoutes: RouteObject[] = [
  {
    path: "admin",
    element: <Admin />,
    children: [
      {
        index: true,
        element: <Navigate to="assignments" replace />,
      },
      {
        path: "appetite",
        element: <Appetite />,
      },
      {
        path: "appetite-notes",
        children: [
          {
            index: true,
            element: <AppetiteNotes />,
          },
          {
            path: "create",
            element: <AppetiteNote mode="create" />,
          },
          {
            path: ":appetiteNoteId/edit",
            element: <AppetiteNote mode="edit" />,
          },
        ],
      },
      {
        path: "file-processing-pipeline",
        element: <FileProcessingPipeline />,
        children: [
          {
            path: "file-processor/:fileProcessorId",
            element: <FileProcessorDetailsSidePanel />,
          },
          {
            path: "document-label/:documentLabelId",
            element: <DocumentLabelDetailsSidePanel />,
          },
          {
            path: "issues",
            element: <IssuesSidePanel />,
          },
        ],
      },
      {
        path: "bankan/:brokerId?",
        element: <Bankan />,
      },
      {
        path: "broker",
        element: <Broker />,
        children: [
          {
            index: true,
            element: <BrokerPinned />,
          },
          {
            path: "active",
            element: <BrokerActive />,
          },
        ],
      },
      {
        path: "assignments",
        element: <Enablement />,
      },
      {
        path: "tags",
        element: <Tags />,
      },
    ],
  },
  {
    path: "label-management",
    element: <LabelManagement />,
    children: [
      {
        index: true,
        element: <Navigate to="extracted-labels" replace />,
      },
      {
        path: "extracted-labels",
        element: <ExtractedLabels />,
        children: [
          {
            path: ":id",
            element: <ExtractedLabel />,
          },
        ],
      },
      {
        path: "primary-labels",
        element: <PrimaryLabels />,
        children: [
          {
            path: ":id",
            element: <PrimaryLabel />,
          },
        ],
      },
    ],
  },
  {
    path: "file-processing-pipeline",
    element: <FileProcessingPipeline />,
    children: [
      {
        path: "file-processor/:fileProcessorId",
        element: <FileProcessorDetailsSidePanel />,
      },
      {
        path: "document-label/:documentLabelId",
        element: <DocumentLabelDetailsSidePanel />,
      },
      {
        path: "issues",
        element: <IssuesSidePanel />,
      },
    ],
  },
];

const armRoutes: RouteObject[] = [
  {
    path: "launch",
    element: <Launch />,
    handle: {
      crumb: (
        <Link to="." className="filled flex items-center text-xs">
          <Icon icon="rocket_launch" />
        </Link>
      ),
    },
    children: [
      { index: true, element: <LaunchAgencies /> },
      {
        id: "agency",
        path: ":agencyId",
        element: <LaunchAgency />,
        handle: {
          crumb: <AgencyCrumb />,
          menu: <LaunchAgencyMenu />,
        },
        children: [
          {
            index: true,
            element: <LaunchAgents />,
          },
        ],
      },
    ],
  },
];

const internalRoutes: RouteObject[] = [
  {
    path: "bankan/:brokerId?",
    element: <Bankan />,
  },
  {
    path: "broker",
    element: <Broker />,
    children: [
      {
        index: true,
        element: <BrokerPinned />,
      },
      {
        path: "active",
        element: <BrokerActive />,
      },
    ],
  },
  {
    path: "verticals",
    element: <Verticals />,
    handle: {
      crumb: (
        <Link to="." className="filled flex items-center text-xs">
          <Icon icon="layers" />
        </Link>
      ),
    },
    children: [
      {
        index: true,
        element: <VerticalsList />,
      },
      {
        path: "create",
        element: <EditVertical />,
      },
      {
        path: ":verticalId",
        element: (
          <VerticalProvider>
            <Vertical />
          </VerticalProvider>
        ),
        handle: {
          crumb: <VerticalCrumb />,
          menu: <VerticalMenu />,
        },
        children: [
          {
            index: true,
            element: <Navigate to="hierarchy" />,
          },
          {
            path: "hierarchy",
            element: <VerticalHierarchy />,
          },
          {
            path: "rules",
            element: <VerticalRules />,
          },
          {
            path: "requirements",
            element: <VerticalRequirements />,
          },
          {
            path: "appetite",
            element: <VerticalAppetite />,
          },
          {
            path: "exclusion-reasons",
            element: <VerticalExclusionReasons />,
          },
          {
            path: "edit",
            element: <EditVertical />,
          },
        ],
      },
    ],
  },
];

const HomeLink = () => (
  <Link to="/" className="filled flex items-center text-xs">
    <Icon icon="home" />
  </Link>
);

const authProtectedRoutes: RouteObject[] = [
  {
    path: "insureds/:agencyId?",
    element: <Insureds />,
    children: [
      {
        index: true,
        element: <InsuredsActive />,
      },
      {
        path: "all",
        element: <InsuredsAll />,
      },
    ],
  },
  {
    path: "insured/new",
    element: <New />,
  },
  {
    path: "insured",
    handle: {
      crumb: <HomeLink />,
    },
    children: [
      {
        id: "insuredRoot",
        path: ":insuredId",
        element: (
          <InsuredProvider>
            <InsuredRoot />
          </InsuredProvider>
        ),
        handle: {
          crumb: <InsuredCrumb />,
        },
        children: [
          {
            element: <Insured />,
            children: [
              {
                index: true,
                element: <InsuredDetails />,
                handle: {
                  menu: <InsuredDetailsMenu />,
                },
              },
              {
                path: "email",
                element: <InsuredEmail />,
                handle: {
                  crumb: "Email Threads",
                },
              },
            ],
          },
          {
            path: "files/:fileId?",
            element: <Files />,
            handle: {
              crumb: "Files",
            },
          },
          {
            path: ":opportunityId/*",
            loader: async ({ params }) =>
              redirect(`/insured/${params.insuredId}/plans/${params.opportunityId}/${params["*"]}`),
          },
          {
            path: "plans",
            handle: {
              crumb: <Link to="plans">Marketing Plans</Link>,
            },
            children: [
              { index: true, element: <MarketingPlans /> },
              {
                path: ":opportunityId",
                element: (
                  <MarketingPlanProvider>
                    <MarketingPlan />
                  </MarketingPlanProvider>
                ),
                handle: {
                  // TODO: marketing plan crumb
                  menu: <MarketingPlanMenu />,
                },
                children: [
                  {
                    index: true,
                    element: <MarketingPlanDetails />,
                  },
                  {
                    path: "coverage",
                    element: <Coverage />,
                  },
                  {
                    path: "escape-hatch",
                    element: <EscapeHatch />,
                  },
                  {
                    path: "files",
                    element: <Files />,
                  },
                  {
                    path: "history",
                    element: <History />,
                  },
                  {
                    path: "alby",
                    element: <AlbyApprovals />,
                  },
                  {
                    path: "markets",
                    element: <SelectMarkets />,
                  },
                ],
              },
            ],
          },
          {
            path: "quotes",
            handle: {
              crumb: <Link to="quotes">Quotes</Link>,
            },
            children: [
              {
                index: true,
                element: <Quotes />,
              },
              {
                path: ":quoteId",
                element: <Quote />,
                handle: {
                  // TODO: quote crumb
                  menu: <QuoteMenu />,
                },
              },
              {
                path: ":quoteId/bind",
                element: <Bind />,
              },
            ],
          },
          {
            path: "policies",
            handle: {
              crumb: <Link to="policies">Policies</Link>,
            },
            children: [
              {
                index: true,
                element: <Policies />,
              },
              {
                path: ":policyId",
                element: <Policy />,
                handle: {
                  // TODO: policy crumb
                  menu: <PolicyMenu />,
                },
              },
            ],
          },
          {
            path: "alby/:graphName/tool/:selectedTool",
            handle: {
              crumb: "Alby",
            },
            element: <AlbyApproval />,
          },
        ],
      },
      {
        path: "opportunity/:opportunityId",
        element: <InsuredRedirect />,
      },
    ],
  },
  {
    // Note: without a clientId, the form won't save
    path: "supplementals/:clientId?",
    element: <Supplementals />,
  },
  { index: true, loader: roleHomepageLoader, element: <div /> },
];

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <ModalProvider>
          <PageProvider>
            <AccountProvider>
              <Page />
            </AccountProvider>
          </PageProvider>
        </ModalProvider>
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      ...authProtectedRoutes,
      {
        element: (
          <HasInternalRole elseShow={<PageNotFound />}>
            <Outlet />
          </HasInternalRole>
        ),
        children: [...internalRoutes],
      },
      {
        element: (
          <HasRole roles={[Roles.Admin]} elseShow={<PageNotFound />}>
            <Outlet />
          </HasRole>
        ),
        children: [...adminRoutes],
      },
      {
        element: (
          <HasRole roles={[Roles.Admin, Roles.Arm]} elseShow={<PageNotFound />}>
            <Outlet />
          </HasRole>
        ),
        children: [...armRoutes],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "first-submission",
    element: <FirstSubmissionPage />,
  },
  {
    element: <CenteredPage />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "activate-account/:changePasswordId",
        loader: activateAccountLoader,
        element: <ActivateAccount />,
      },
    ],
  },
]);
