import { useState } from "react";
import { DefaultValues } from "react-hook-form";
import { input, z, ZodObject } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { SectionContent } from "@/components/section";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Badge } from "@/components/ui/badge";
import { Bar } from "@/components/ui/bar";
import { Icon } from "@/components/ui/icon";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  useSearchBusinessClassesLazyQuery,
  VerticalFragment,
} from "src/generated/graphql";
import { cn } from "src/utils";

interface VerticalFormProps<T extends ZodObject<any>> {
  vertical?: VerticalFragment;
  schema: T;
  onSubmit: (values: z.infer<T>, selectedCgls: Set<string>) => unknown | Promise<unknown>;
  onCancel?: () => void;
  defaultValues?: DefaultValues<input<T>>;
  preselectedCgls?: string[];
  className?: string;
}

export const VerticalForm = <T extends ZodObject<any>>({
  vertical,
  schema,
  onSubmit,
  onCancel,
  defaultValues,
  preselectedCgls = [],
  className,
}: VerticalFormProps<T>) => {
  const [selectedCgls, setSelectedCgls] = useState<Set<string>>(
    new Set(vertical ? vertical?.isoCglCodes.map((c) => c.classCode) : preselectedCgls)
  );

  const handleSubmit = async (values: z.infer<typeof schema>) => {
    await onSubmit(values, selectedCgls);
  };

  return (
    <Form
      validationSchema={schema}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      className={cn("flex flex-auto flex-col gap-6", className)}
    >
      <SectionContent className="flex-auto">
        <Group>
          <FieldInput label="Name" name="name" />

          <FieldTextarea
            label={
              <>
                Description<span className="text-muted-foreground font-normal">Optional</span>
              </>
            }
            name="description"
          />

          <Pills
            items={[...selectedCgls]}
            onDelete={(code) => {
              selectedCgls.delete(code);
              setSelectedCgls(new Set(selectedCgls));
            }}
          />

          <CglSelector
            onSelect={(option) => {
              selectedCgls.has(option.code) ? selectedCgls.delete(option.code) : selectedCgls.add(option.code);
              setSelectedCgls(new Set(selectedCgls));
            }}
          />
        </Group>
      </SectionContent>

      <Bar as="footer">
        <ButtonGroup>
          {onCancel && <FormReset onClick={onCancel} />}
          <FormSubmit className="ml-auto" />
        </ButtonGroup>
      </Bar>
    </Form>
  );
};

const CglSelector: React.FC<{ onSelect: (option: BusinessClassFragment) => void }> = ({ onSelect }) => {
  const [load] = useSearchBusinessClassesLazyQuery();
  return (
    <Autocomplete
      options={(text) => {
        return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.IsoGl] } } }).then((res) => [
          ...(res.data?.searchBusinessClasses ?? []),
        ]);
      }}
      onSelect={onSelect}
      toValue={(option) => businessClassToLabel(option)}
      toLabel={(option) => businessClassToLabel(option)}
      placeholder="Search CGL Classifications"
    />
  );
};

// TODO: Since this isn't really a field, but rather sets the value for another field,
// I'm not sure we will want to support this in our form components. I'd like to
// explore different UX options for this, as it gets tricky with async data.
// const CglSelector: React.FC<{ onSelect: (code: string) => void }> = ({ onSelect }) => {
//   const [load] = useSearchBusinessClassesLazyQuery();

//   const loadOptions = async (term?: string) =>
//     load({ variables: { input: { term, classSystems: [BusinessClassSystem.IsoGl] } } }).then((res) =>
//       (res.data?.searchBusinessClasses ?? []).map((c) => ({
//         label: businessClassToLabel(c),
//         value: c.code,
//       }))
//     );

//   return (
//     <FieldComboboxAsync
//       name="cglSearch"
//       placeholder="Search CGL Classifications"
//       loadOptions={loadOptions}
//       onChange={onSelect}
//     />
//   );
// };

const Pill: React.FC<{
  text: string;
  onClick?: () => void;
  onDelete?: (item: string) => void;
}> = ({ text, onClick, onDelete }) => {
  return (
    <Badge onClick={onClick} className="justify-between gap-1">
      {text}
      {onDelete && (
        <span
          onClick={(e) => {
            e.preventDefault();
            onDelete(text);
          }}
        >
          <Icon icon="close" />
        </span>
      )}
    </Badge>
  );
};

const Pills: React.FC<{
  items: string[];
  onClick?: () => void;
  onDelete?: (item: string) => void;
}> = ({ items, onClick, onDelete }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Group type="flex" direction="row" className="flex-wrap gap-1">
      {items.map((item) => (
        <Pill key={item} text={item} onClick={onClick} onDelete={onDelete} />
      ))}
    </Group>
  );
};

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;
