import { createContext, useContext, useState } from "react";

import {
  MarketingPlanState,
  OpportunityRequirementsQuery,
  useOpportunityRequirementsQuery,
} from "src/generated/graphql";
import { useMarketingPlan } from "./use-marketing-plan";

export interface MarketingPlanRequirementsContextData {
  hasRequirements: boolean;
  loading: boolean;
  opportunity: NonNullable<OpportunityRequirementsQuery["opportunity"]>;
  refetch: () => void;
}

export const MarketingPlanRequirementsContext = createContext({} as MarketingPlanRequirementsContextData);

export const MarketingPlanRequirementsProvider = ({ children }: { children: React.ReactNode }) => {
  const { opportunityId } = useMarketingPlan();
  const [hasRequirements, setHasRequirements] = useState(false);

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityRequirementsQuery({
    variables: { id: opportunityId },
    onCompleted: ({ opportunity }) => {
      if (opportunity) {
        setHasRequirements(opportunity.agentActions.some((action) => !action.complete));
      }
    },
  });

  if (
    !opportunity ||
    opportunity.state === MarketingPlanState.WaitingForCoverages ||
    opportunity.state === MarketingPlanState.Complete
  ) {
    return null;
  }

  return (
    <MarketingPlanRequirementsContext.Provider value={{ hasRequirements, loading, opportunity, refetch }}>
      {children}
    </MarketingPlanRequirementsContext.Provider>
  );
};

export const useMarketingPlanRequirements = () => {
  const context = useContext(MarketingPlanRequirementsContext);

  if (!context) {
    throw new Error("useMarketingPlanRequirements must be used within a MarketingPlanRequirementsProvider");
  }

  return context;
};
