import { format } from "date-fns";
import { ComponentProps, forwardRef, useEffect, useRef, useState } from "react";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Icon } from "@/components/ui/icon";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "src/utils";
import { setRefs } from "src/utils/set-refs";

export interface DatePickerProps extends Omit<ComponentProps<typeof Button>, "value" | "onChange"> {
  value?: Date;
  placeholder?: string;
  dateFormat?: string;
  onChange?: (value?: Date) => void;
  hideCalendarIcon?: boolean;
}

export const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      value,
      placeholder = "Pick a date",
      dateFormat = "MMMM d, yyyy",
      onChange,
      onBlur,
      className,
      hideCalendarIcon,
      ...props
    },
    ref
  ) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [date, setDate] = useState<Date | undefined>(value);

    const handleSelect = (date?: Date) => {
      setDate(date);
      onChange?.(date);
    };

    const handleBlur = (event: any) => {
      setTimeout(() => {
        // Don't trigger blur If the focus is still within the field or dropdown.
        if (
          buttonRef.current?.contains(document.activeElement) ||
          dropdownRef.current?.contains(document.activeElement)
        ) {
          return;
        }

        onBlur?.(event);
      }, 0);
    };

    useEffect(() => {
      setDate(value);
    }, [value]);

    return (
      <Popover open={isOpen} onOpenChange={(open) => !props.disabled && setIsOpen(open)}>
        <PopoverTrigger onBlur={handleBlur} asChild>
          <Button
            ref={setRefs(ref, buttonRef)}
            variant="outline"
            {...props}
            className={cn(
              "min-w-[180px] justify-start text-left font-normal px-3",
              !date && "text-muted-foreground",
              className
            )}
          >
            {!hideCalendarIcon && (
              <Icon icon="calendar_today" className="h-4 w-4 text-base text-foreground opacity-50" />
            )}
            {date ? format(date, dateFormat) : <span>{placeholder}</span>}
          </Button>
        </PopoverTrigger>

        <PopoverContent ref={dropdownRef} onBlur={handleBlur} className="w-auto p-0">
          <Calendar mode="single" selected={date} onSelect={handleSelect} initialFocus />
        </PopoverContent>
      </Popover>
    );
  }
);
DatePicker.displayName = "DatePicker";
