import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { SUBMISSION_STATE_METADATA } from "@/metadata";
import {
  SubmissionAction,
  SubmissionFragment,
  useAddSubmissionRuleOutReasonMutation,
  useTransitionSubmissionMutation,
} from "src/generated/graphql";

export const useSubmissionActions = () => {
  const { refetchQueries } = useMarketingPlan();
  const [transition] = useTransitionSubmissionMutation();
  const [addRuleOutReason] = useAddSubmissionRuleOutReasonMutation();
  const { openConfirmation, openForm } = useModal();

  const handleConfirmation = async (
    submission: SubmissionFragment,
    action: SubmissionAction,
    includeDetails: boolean = false
  ) => {
    const isConfirmed = await (includeDetails
      ? openForm(
          <FieldInput
            name="details"
            label={`${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`}
            placeholder="Reason"
          />,
          {
            title: "Confirm",
            defaultValues: { details: "" },
            validationSchema: z.object({ details: z.string().min(1, "Please enter a reason") }),
          }
        )
      : openConfirmation({
          title: `Confirm ${SUBMISSION_STATE_METADATA[submission?.state].actionDisplayNames?.[action] ?? action}`,
          description: `${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`,
        }));

    if (!isConfirmed) {
      return;
    }

    await handleTransition(submission, action, typeof isConfirmed === "object" ? isConfirmed.details : undefined);
  };

  const handleTransition = async (submission: SubmissionFragment, action: SubmissionAction, details?: string) => {
    await transition({
      variables: {
        input: {
          id: submission.id,
          expectedState: submission.state,
          action,
          details,
        },
      },
      refetchQueries,
    });
  };

  const handleAddRuleOutReason = async (submission: SubmissionFragment) =>
    await openForm(
      <FieldInput
        name="details"
        label={`${submission.appetiteProduct.carrierName} - ${submission.appetiteProduct.carrierProductName}`}
        placeholder="Reason"
      />,
      {
        title: "Add rule out reason",
        defaultValues: { details: "" },
        validationSchema: z.object({ details: z.string().min(1, "Please enter a reason") }),
        onSubmit: async (fd) =>
          await addRuleOutReason({
            variables: { input: { id: submission.id, details: fd.details } },
            refetchQueries,
          }),
      }
    );

  return {
    handleConfirmation,
    handleTransition,
    handleAddRuleOutReason,
  };
};
