import { Dispatch } from "react";
import { z } from "zod";

import { BusinessClassFragment, useAiGuessBusinessClassesLazyQuery } from "src/generated/graphql";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";

const validationSchema = z.object({
  description: z.string().min(1, "Description cannot be empty"),
});

const defaultValues: z.infer<typeof validationSchema> = {
  description: "",
};

interface Props {
  setResults: Dispatch<React.SetStateAction<BusinessClassFragment[]>>;
  onClose: () => void;
}

export const AiClassCodeGuesser: React.FC<Props> = ({ setResults, onClose }) => {
  const [load] = useAiGuessBusinessClassesLazyQuery();

  const handleClose = () => {
    setResults([]);
    onClose();
  };

  const handleSubmit = async (values: z.infer<typeof validationSchema>) => {
    const codes = await load({ variables: { description: values.description } });
    setResults(codes.data?.aiGuessBusinessClasses ?? []);
  };

  return (
    <Form validationSchema={validationSchema} onSubmit={handleSubmit} defaultValues={defaultValues}>
      <Group>
        <FieldTextarea name="description" placeholder="Business description" />

        <ButtonGroup>
          <FormReset onClick={handleClose} />
          <FormSubmit>Search</FormSubmit>
        </ButtonGroup>
      </Group>
    </Form>
  );
};
