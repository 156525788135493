import { useParams } from "react-router";

import { StatePill } from "@/components/state";
import { Avatar } from "@/components/ui/avatar";
import { useOpportunityQuery, usePolicyQuery } from "src/generated/graphql";
import { PolicyDropdown } from "./policy-dropdown";

export const PolicyMenu = () => {
  const { policyId } = useParams();

  const { data: { policy } = {} } = usePolicyQuery({
    variables: {
      id: policyId ?? policyId!,
    },
    skip: !policyId,
    fetchPolicy: "cache-first",
  });

  const { data: { opportunity } = {} } = useOpportunityQuery({
    variables: {
      id: policy?.opportunityId ?? "",
    },
    skip: !policyId || !policy?.opportunityId,
    fetchPolicy: "cache-first",
  });

  if (!opportunity || !policy) {
    return null;
  }

  return (
    <>
      <Avatar user={opportunity.broker} />
      <StatePill state={policy.state} />
      <PolicyDropdown id={policy.id} state={policy.state} />
    </>
  );
};
