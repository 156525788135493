import { UUID } from "@cp/toolkit";
import { useParams } from "react-router";

import { SetBroker } from "@/components/set-broker";
import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { toast } from "@/hooks/use-toast";
import { useAgencyQuery, useUpdateAgencyOwnerMutation } from "src/generated/graphql";

export const LaunchAgencyMenu = () => {
  const { agencyId } = useParams();

  const { data: { agency } = {}, refetch } = useAgencyQuery({
    variables: {
      id: agencyId!,
    },
    skip: !agencyId,
  });

  const [updateAgencyOwner] = useUpdateAgencyOwnerMutation({
    onCompleted: async (update) => {
      void refetch();
      toast({ title: `Updated ${update.updateAgency.name} Owner` });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  if (!agency) {
    return null;
  }

  const { id, isPrivate, owner } = agency;

  const onSelect = (e: UUID) =>
    updateAgencyOwner({
      variables: {
        input: { agencyId: id, ownerId: e === "-" ? null : e },
      },
    });

  return (
    <>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => {
              void updateAgencyOwner({
                variables: { input: { agencyId: id, isPrivate: !isPrivate } },
                onCompleted: async () => {
                  void refetch();
                  toast({
                    title: "Agency Updated",
                    description: `Privacy set to ${isPrivate ? "Public" : "Private"}`,
                  });
                },
              });
            }}
          >
            <Icon icon={isPrivate ? "visibility_off" : "visibility"} />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Agency is {isPrivate ? "Private" : "Public"}</TooltipContent>
      </Tooltip>
      <SetBroker broker={owner} onSelect={onSelect} />
    </>
  );
};
