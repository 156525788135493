import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Link, useParams } from "react-router-dom";
import { SubmissionAction, SubmissionFragment } from "src/generated/graphql";
import { SubmissionMenu } from "./submission-menu";
import { useSubmissionActions } from "./use-submission-actions";

export const ReadyToSubmit = ({ submission }: { submission: SubmissionFragment }) => {
  const { insuredId } = useParams();
  const { handleTransition } = useSubmissionActions();

  return (
    <>
      <Link
        to={`/insured/${insuredId}/alby/SubmissionTransitionGraph/tool/submit-to-carrier?submissionId=${submission.id}`}
      >
        <Button variant="ghost" size="sm" display="icon" asChild>
          <Icon icon="mail" />
        </Button>
      </Link>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => handleTransition(submission, SubmissionAction.Submit)}
          >
            <Icon icon="check_circle" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Mark Submitted</TooltipContent>
      </Tooltip>
      <SubmissionMenu submission={submission}>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.Submit)}>
          <Icon icon="check_circle" />
          Mark Submitted
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleTransition(submission, SubmissionAction.RuleOut)}>
          <Icon icon="block" />
          Rule Out
        </DropdownMenuItem>
      </SubmissionMenu>
    </>
  );
};
