import { createContext, useContext, useEffect, useState } from "react";

export const SIDEBAR_WIDTH = "16rem";

export type Side = "right" | "left" | null;

interface PageState {
  side: Side;
  setSide: React.Dispatch<React.SetStateAction<Side>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  wide: boolean;
  setWide: React.Dispatch<React.SetStateAction<boolean>>;
  width: string;
  setWidth: React.Dispatch<React.SetStateAction<string>>;
}

const PageContext = createContext<PageState>({} as PageState);

export function PageProvider({ children }: { children: React.ReactNode }) {
  const [side, setSide] = useState<Side>(null);
  const [open, setOpen] = useState(true);
  const [wide, setWide] = useState(true);
  const [width, setWidth] = useState(SIDEBAR_WIDTH);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)");

    const handleOrientationChange = (e: any) => {
      setOpen(e.matches);
      setWide(e.matches);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);

    handleOrientationChange(mediaQuery);

    return () => mediaQuery.removeEventListener("change", handleOrientationChange);
  }, []);

  const value = {
    side,
    setSide,
    open,
    setOpen,
    wide,
    setWide,
    width,
    setWidth,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}

export const usePage = () => {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error("usePage must be used within a PageProvider");
  }

  return context;
};
