import { useEffect } from "react";

import { SIDEBAR_WIDTH, usePage } from "@/hooks/use-page";
import { Link, LinkProps, NavLink, NavLinkProps } from "react-router-dom";
import { cn } from "src/utils";
import { Bar } from "./ui/bar";
import { Button, ButtonProps } from "./ui/button";
import { Icon } from "./ui/icon";
import { Separator } from "./ui/separator";

export const SidebarTrigger = ({ children, separator = true, ...rest }: ButtonProps & { separator?: boolean }) => {
  const { side, open, setOpen } = usePage();

  if (!side) {
    return null;
  }

  return (
    <div
      className={cn(
        "flex gap-2 items-center",
        side === "right" ? "flex-row-reverse -mr-1.5 order-last" : "-ml-1.5 order-first"
      )}
    >
      <Button variant="ghost" size="sm" display="icon" onClick={() => setOpen((open: boolean) => !open)} {...rest}>
        <Icon icon={open ? `${side}_panel_close` : `${side}_panel_open`} />
      </Button>
      {separator && <Separator orientation="vertical" className="h-3" />}
    </div>
  );
};

export const Sidebar = ({
  children,
  bar,
  width = SIDEBAR_WIDTH,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & { bar?: React.ReactNode; width?: string }) => {
  const { open, setOpen, side, setWidth } = usePage();

  useEffect(() => {
    setWidth(width);
  }, []);

  return (
    <div
      className={cn(
        "flex flex-none inset-0 overflow-hidden transition-all",
        "max-md:absolute max-md:z-10",
        side === "right" && "justify-end",
        open
          ? "max-md:backdrop-blur-xs max-md:backdrop-saturate-150 md:w-[var(--sidebar-width)]"
          : "max-md:pointer-events-none max-md:opacity-0 md:w-0"
      )}
    >
      <aside
        className={cn(
          "flex flex-col relative w-[var(--sidebar-width)] z-10",
          "max-md:bg-accent max-md:m-2 max-md:shadow-xl max-md:transition-transform max-md:duration-300",
          side === "left"
            ? "max-md:rounded-md max-md:-translate-x-[var(--sidebar-width)]"
            : "max-md:rounded-md max-md:translate-x-[var(--sidebar-width)]",
          open && "max-md:translate-x-0"
        )}
        {...rest}
      >
        <div className="divide-foreground/10 divide-y flex flex-auto flex-col w-[var(--sidebar-width)]">{children}</div>
      </aside>
      <div className={cn("absolute md:hidden inset-0 w-0 z-0", open && "w-full")} onClick={() => setOpen(false)} />
    </div>
  );
};

export const SidebarHeader = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <Bar className={cn("bg-transparent", className)} {...rest}>
    {children}
  </Bar>
);

export const SidebarFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("px-6 py-4 space-y-3", className)} {...rest}>
    {children}
  </footer>
);

export const SidebarContent = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("divide-foreground/10 divide-y flex-auto overflow-auto", className)} {...rest}>
    {children}
  </div>
);

export const SidebarSection = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <section className={cn("px-6 py-5 space-y-3", className)} {...rest}>
    {children}
  </section>
);

export const SidebarLinkClassName = "flex gap-3 items-center justify-between text-xs truncate";

export const SidebarLink = ({ children, className, to, ...rest }: LinkProps) => {
  const { setOpen, wide } = usePage();
  return (
    <Link to={to} className={cn(SidebarLinkClassName, className)} onClick={() => setOpen(wide)} {...rest}>
      {children}
    </Link>
  );
};

export const SidebarNavLink = ({ children, className, to, ...rest }: NavLinkProps) => {
  const { setOpen, wide } = usePage();
  return (
    <NavLink
      to={to}
      className={({ isPending, isActive }) =>
        cn(SidebarLinkClassName, isPending && "opacity-75", isActive && "filled font-semibold", className)
      }
      onClick={() => setOpen(wide)}
      {...rest}
    >
      {children}
    </NavLink>
  );
};
