import { assertNever } from "@cp/toolkit";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { FieldBase } from "@/forms-v2/fields/field-base";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { useInsured } from "@/hooks/use-insured";
import { useToast } from "@/hooks/use-toast";
import { useUpsertSearchParams } from "@/hooks/use-upsert-search-params";
import { useFormContext } from "react-hook-form";
import {
  BusinessClassFragment,
  BusinessClassSystem,
  useSearchBusinessClassesLazyQuery,
  useUpdateInsuredMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

import { AiClassCodeGuesser } from "./ai-class-code-guesser";

const InsuredFormSchema = z.object({
  naicsCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 6, { message: "NAICS code must be 5 digits" })
    .nullable()
    .optional(),
  cglCode: z
    .string()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => val === null || val.length === 5, { message: "CGL code must be 5 digits" })
    .nullable()
    .optional(),
});
type InsuredFormValues = z.infer<typeof InsuredFormSchema>;

export const Classification = () => {
  const [editing, setEditing] = useState(false);
  const location = useLocation();
  const newSubmission = location.pathname.split("/")[1] === "new";

  return (
    <Card>
      {editing ? (
        <>
          <UpdateInsuredForm setEditing={setEditing} />
        </>
      ) : (
        <>
          <CardHeader>
            <CardTitle>Business Classification</CardTitle>
            {newSubmission && (
              <CardDescription>
                We attempted to classify this business based on the information you provided. Classification can be
                changed by clicking <strong>Edit Classification</strong> below.
              </CardDescription>
            )}
          </CardHeader>
          <ClassCodes />
          <CardFooter>
            <Button variant="secondary" display="flex" onClick={() => setEditing(true)}>
              Edit Classification
            </Button>
          </CardFooter>
        </>
      )}
    </Card>
  );
};

const UpdateInsuredForm = ({ setEditing }: { setEditing: (bool: boolean) => void }) => {
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState<BusinessClassFragment[]>([]);

  const { toast } = useToast();
  const { insured } = useInsured();
  const [updateInsured] = useUpdateInsuredMutation();

  const defaultValues: InsuredFormValues = {
    ...insured,
    // temporarily handles only one NAICS code for simplicity
    naicsCode: insured.naicsCodes[0] ?? undefined,
    cglCode: insured.isoCglCodes[0] ?? undefined,
  };

  const handleSubmit = async ({ naicsCode, cglCode }: InsuredFormValues) => {
    // necessary to assign this to a variable (naicsCode) satisfy TS type checker in updateClient()
    // setEditing(false);
    await updateInsured({
      variables: {
        input: {
          id: insured.id,
          // temporarily handles only one NAICS code for simplicity
          naicsCodes: naicsCode ? [naicsCode] : [],
          isoCglCodes: cglCode ? [cglCode] : [],
        },
      },
      refetchQueries: ["SearchAppetiteForOpportunity"],
      onCompleted: () => setEditing(false),
      onError: (error) => toast({ title: "Error", description: parseError(error.message), variant: "destructive" }),
    });
  };

  return (
    <>
      <CardHeader>
        <CardTitle>Edit Business Classification</CardTitle>
      </CardHeader>

      <CardContent>
        <Group>
          {open ? (
            <AiClassCodeGuesser setResults={setResults} onClose={() => setOpen(false)} />
          ) : (
            <Button variant="secondary" display="flex" onClick={() => setOpen(true)}>
              Search class codes with AI
            </Button>
          )}

          <Form onSubmit={handleSubmit} validationSchema={InsuredFormSchema} defaultValues={defaultValues}>
            <Group>
              <UpdateInsuredFormContent results={results} />

              <ButtonGroup>
                <FormReset onClick={() => setEditing(false)}>Cancel</FormReset>
                <FormSubmit />
              </ButtonGroup>
            </Group>
          </Form>
        </Group>
      </CardContent>
    </>
  );
};

export interface UpdateInsuredFormContentProps {
  results?: BusinessClassFragment[];
}

const UpdateInsuredFormContent: FC<UpdateInsuredFormContentProps> = ({ results }) => {
  const formMethods = useFormContext();
  const { insured } = useInsured();
  const [load] = useSearchBusinessClassesLazyQuery();

  const [selectedNaics, setSelectedNaics] = useState<BusinessClassFragment | undefined>(undefined);
  const [selectedCgl, setSelectedCgl] = useState<BusinessClassFragment | undefined>(undefined);

  const selectNaics = (option: BusinessClassFragment) => {
    setSelectedNaics(option);
    formMethods.setValue("naicsCode", option.code);
  };

  const selectCgl = (option: BusinessClassFragment) => {
    setSelectedCgl(option);
    formMethods.setValue("cglCode", option.code);
  };

  const handleResultClick = (result: BusinessClassFragment) =>
    result.system === BusinessClassSystem.Naics ? selectNaics(result) : selectCgl(result);

  // Load the insured's existing NAICS/CGL codes on mount
  useEffect(() => {
    if (insured.naicsCodes[0]) {
      void load({
        variables: { input: { term: insured.naicsCodes[0], classSystems: [BusinessClassSystem.Naics] } },
      }).then((res) => setSelectedNaics(res.data?.searchBusinessClasses[0]));
    }

    if (insured.isoCglCodes[0]) {
      void load({
        variables: { input: { term: insured.isoCglCodes[0], classSystems: [BusinessClassSystem.IsoGl] } },
      }).then((res) => setSelectedCgl(res.data?.searchBusinessClasses[0]));
    }
  }, []);

  return (
    <>
      {!!results?.length && (
        <div>
          <h6>Click on a result below to select it</h6>
          {results.map((code) => (
            <Pill code={code} onClick={handleResultClick} key={code.code} />
          ))}
        </div>
      )}

      <FieldBase label="Naics" name="naicsCode">
        <Autocomplete
          options={(text) => {
            return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.Naics] } } }).then(
              (res) => [
                { id: null, description: "None", system: BusinessClassSystem.Naics, code: "" },
                ...(res.data?.searchBusinessClasses ?? []),
              ]
            );
          }}
          selected={selectedNaics}
          onSelect={(option) => selectNaics(option)}
          toValue={(option) => businessClassToLabel(option)}
          toLabel={(option) => businessClassToLabel(option)}
          placeholder="Search NAICS Classifications"
        />
      </FieldBase>

      <FieldBase label="CGL" name="cglCode">
        <Autocomplete
          options={(text) => {
            return load({ variables: { input: { term: text, classSystems: [BusinessClassSystem.IsoGl] } } }).then(
              (res) => [
                { id: null, description: "None", system: BusinessClassSystem.IsoGl, code: "" },
                ...(res.data?.searchBusinessClasses ?? []),
              ]
            );
          }}
          selected={selectedCgl}
          onSelect={(option) => selectCgl(option)}
          toValue={(option) => businessClassToLabel(option)}
          toLabel={(option) => businessClassToLabel(option)}
          placeholder="Search CGL Classifications"
        />
      </FieldBase>
    </>
  );
};

const ClassCodes = () => {
  const { insured } = useInsured();
  // Reverse the order in which we display the systems to match how we display them in editing mode.
  const businessClasses = [...insured.businessClasses].reverse();
  return (
    <CardContent className="space-y-3">
      {businessClasses.map((bc) => (
        <BusinessClassPill businessClass={bc} key={`${bc.system}:${bc.code}`} />
      ))}
    </CardContent>
  );
};

const BusinessClassPill: FC<{ businessClass: BusinessClassFragment }> = ({ businessClass }) => {
  const [, upsertSearchParams] = useUpsertSearchParams();
  return (
    <div>
      <Badge className="cursor-pointer text-2xs" onClick={() => upsertSearchParams({ term: businessClass.code })}>
        {`${systemToFriendly(businessClass.system)}: ${businessClass.code}`}
      </Badge>
      <p className="text-xs italic pt-1">{businessClass.description}</p>
    </div>
  );
};

export const systemToFriendly = (system: BusinessClassSystem) => {
  switch (system) {
    case BusinessClassSystem.IsoGl:
      return "CGL";
    case BusinessClassSystem.Naics:
      return "NAICS";
    default:
      assertNever(system);
      return system;
  }
};

const businessClassToLabel = (c: BusinessClassFragment) =>
  `${c.code} ${c.code && c.description ? `-` : ""} ${c.description}`;

interface PillProps {
  code: BusinessClassFragment;
  onClick: (code: BusinessClassFragment) => void;
}

const Pill: FC<PillProps> = ({ code, onClick }) => (
  <Badge onClick={() => onClick(code)} className="overflow-auto">
    {`${systemToFriendly(code.system)}: ${code.code} - ${code.description}`}
  </Badge>
);
