import { SectionContent, SectionDescription, SectionHeader, SectionTitle } from "@/components/section";
import { Spinner } from "@/components/ui/loading";
import { Switch } from "@/components/ui/switch";
import { Roles, useBrokerAccountsQuery, useUpdateUserAssignmentsEnabledMutation } from "src/generated/graphql";
import { cn } from "src/utils";
import { AssignmentQueue } from "./assignment-queue";

export const TRIAGE_DATE_FORMAT = "yyyy-MM-dd";

export const KNOWN_BROKER_EMAILS = {
  QUOTEWELL_BROKER: "submissions@quotewell.com",
  ALBY_BROKER: "alby@quotewell.com",
  JAMES_BROKER: "jmonks+broker@quotewell.com",
};

export const BROKER_IGNORE_LIST = [
  KNOWN_BROKER_EMAILS.QUOTEWELL_BROKER,
  KNOWN_BROKER_EMAILS.ALBY_BROKER,
  KNOWN_BROKER_EMAILS.JAMES_BROKER,
];

export const Enablement = () => {
  const { data: { userAccounts } = { userAccounts: [] }, loading } = useBrokerAccountsQuery();
  const [mutate] = useUpdateUserAssignmentsEnabledMutation();

  const filteredBrokers = userAccounts.filter((broker) => !BROKER_IGNORE_LIST.includes(broker.email));

  const handleToggle = (brokerId: string, enabled: boolean) => {
    void mutate({
      variables: {
        input: {
          brokerId,
          enabled,
        },
      },
    });
  };

  return (
    <>
      <SectionHeader>
        <SectionTitle>
          <h1>Broker Assignment</h1>
          {loading && <Spinner />}
        </SectionTitle>
        <SectionDescription>Turn on/off to add/remove broker from the queues for their roles</SectionDescription>
      </SectionHeader>
      <SectionContent className="grid grid-cols-2">
        <ul className="space-y-4">
          {filteredBrokers.map(({ id, firstName, lastName, assignmentsEnabled, roles }) => (
            <li key={id} className="flex gap-4 items-start">
              <Switch
                id="airplane-mode"
                checked={assignmentsEnabled}
                className="flex-none mt-1 text-sm"
                onCheckedChange={(val) => handleToggle(id, val)}
              />
              <label htmlFor="airplane-mode" className="space-y-1">
                <div
                  className={cn("font-semibold text-sm", !assignmentsEnabled && "text-muted-foreground")}
                >{`${firstName} ${lastName}`}</div>
                <div className="text-2xs text-muted-foreground">{roles.join(", ")}</div>
              </label>
            </li>
          ))}
        </ul>
        <div className="space-y-6">
          <AssignmentQueue role={Roles.BindingAuthority} />
          <AssignmentQueue role={Roles.Brokerage} />
          <AssignmentQueue role={Roles.PreferredBroker} />
        </div>
      </SectionContent>
    </>
  );
};
