import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { PDFViewer } from "@/files/file-upload-viewer";
import { useSubmissionTemplate } from "./use-submission-template";

export const SubmissionTemplatePdf = () => {
  const { files, selectedFile, setSelectedFile } = useSubmissionTemplate();

  if (!selectedFile) {
    return null;
  }

  const index = files.findIndex((file) => file.id === selectedFile?.id);

  const getNextFile = () => {
    if (!index && index !== 0) {
      return;
    }
    setSelectedFile(files[index + 1]);
  };

  const getPreviousFile = () => {
    if (!index) {
      return;
    }
    setSelectedFile(files[index - 1]);
  };

  return (
    <div className="h-[calc(100dvh_-_6rem_-_1px)] sticky top-0">
      <nav className="absolute bg-background bottom-2 flex gap-2 items-center left-1/2 p rounded-full shadow-xl -translate-x-1/2">
        <Button
          variant="outline"
          display="icon"
          onClick={getPreviousFile}
          disabled={index === 0}
          className="rounded-full"
        >
          <Icon icon="chevron_left" />
        </Button>
        <Button variant="ghost" display="icon" className="rounded-full" onClick={() => setSelectedFile(undefined)}>
          <Icon icon="close_fullscreen" className="filled" />
        </Button>
        <Button
          variant="outline"
          display="icon"
          onClick={getNextFile}
          disabled={index === files.length - 1}
          className="rounded-full"
        >
          <Icon icon="chevron_right" />
        </Button>
      </nav>
      <PDFViewer file={selectedFile} style={{ height: "100%", marginTop: 0, width: "100%", zIndex: 0 }} />
    </div>
  );
};
