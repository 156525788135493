import { Link } from "react-router-dom";
import { useResumableGraphsQuery } from "../../generated/graphql";
import { Card, CardContent } from "../components/ui/card";
import { Icon } from "../components/ui/icon";
import { useInsured } from "../hooks/use-insured";

export default function AlbyApprovals() {
  const { insuredId } = useInsured();
  const { data } = useResumableGraphsQuery({ variables: { input: { insuredId } } });

  return (
    <Card>
      <CardContent>
        <ul>
          {data?.resumableGraphs.map((graph) => {
            const input = JSON.parse(graph.input);
            const invokeParams = JSON.parse(input.invokeParams);
            const output = JSON.parse(JSON.parse(graph.output));
            const header = output?.slackBlocks?.find((block: any) => block.type === "header");

            return (
              <li key={graph.id} className="border-b p-2">
                <Link
                  className="flex justify-between"
                  to={`/insured/${insuredId}/alby/${graph.graphName}/tool/${graph.toolName}?${new URLSearchParams(
                    invokeParams
                  ).toString()}`}
                >
                  <span>{header?.text.text ?? `${graph.graphName} - ${graph.toolName}`}</span>
                  <span>
                    <Icon icon="arrow_forward" />
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </CardContent>
    </Card>
  );
}
