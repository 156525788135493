import { X } from "lucide-react";
import React, { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Spinner } from "@/components/ui/loading";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import {
  AlbyActionType,
  File_Audience,
  QuoteAction,
  QuoteFragment,
  useResumableGraphQuery,
  useResumeGraphMutation,
  useSendQuoteEmailMutation,
  useTransitionQuoteMutation,
} from "src/generated/graphql";
import { uploadFiles } from "src/utils/file";
import { useInsured } from "../../hooks/use-insured";

export const Processed = ({ quote }: { quote: QuoteFragment }) => {
  const { insured } = useInsured();
  const { toast } = useToast();
  const [sendQuote, { loading, called }] = useSendQuoteEmailMutation();
  const [transition, { loading: transitionLoading }] = useTransitionQuoteMutation();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const { data } = useResumableGraphQuery({
    variables: {
      input: {
        name: "QuoteTransitionGraph",
        selectedTool: "send-quote-to-agent",
        insuredId: insured.id,
        invokeParams: JSON.stringify({ quoteId: quote.id }),
      },
    },
  });

  const [resumeGraph] = useResumeGraphMutation();

  const resumableGraphThreadId = data?.resumableGraph?.threadId;

  const handleEmailClick = () => {
    setIsEmailModalOpen(true);
  };

  const handleSendEmail = (emailComment: string, fileIds: string[]) => {
    if (resumableGraphThreadId) {
      void resumeGraph({
        variables: {
          input: {
            threadId: resumableGraphThreadId,
            actionType: AlbyActionType.Approve,
            brokerInput: JSON.stringify({
              emailComment,
              fileIds,
            }),
          },
        },
        onCompleted: () => {
          toast({ title: "Email sent" });
        },
        onError: () => {
          toast({ title: "Error sending email" });
        },
      });
    } else {
      void sendQuote({
        variables: {
          input: {
            quoteId: quote.id,
            emailComment,
            fileIds,
          },
        },
        onCompleted: () => {
          toast({ title: "Email sent" });
        },
        onError: () => {
          toast({ title: "Error sending email" });
        },
      });
    }
  };

  const handleTransition = async (action: QuoteAction) => {
    await transition({
      variables: {
        input: {
          id: quote.id,
          expectedState: quote.state,
          action,
        },
      },
    });
  };

  return (
    <>
      <Button
        onClick={handleEmailClick}
        variant="outline"
        size="sm"
        display="flex"
        disabled={!quote.processedQuote || loading || called}
      >
        Send email with quote attached {loading && <Spinner />}
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.Deliver)} variant="outline" size="sm" display="flex">
        Mark as delivered outside of app {transitionLoading && <Spinner />}
      </Button>
      <Button onClick={() => handleTransition(QuoteAction.Invalidate)} variant="outline" size="sm" display="flex">
        Invalidate
      </Button>
      <EmailModal
        quote={quote}
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        onSend={handleSendEmail}
        initialBody={""}
      />
    </>
  );
};

interface FileInfo {
  id: string;
  name: string;
}

const EmailModal = ({
  isOpen,
  onClose,
  onSend,
  initialBody,
  quote,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSend: (emailComment: string, files: string[]) => void;
  initialBody: string;
  quote: QuoteFragment;
}) => {
  const { insuredId } = useParams<"insuredId">();
  const { toast } = useToast();
  const [emailComment, setEmailComment] = useState(initialBody);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.type !== "application/pdf") {
        toast({ title: "We only accept PDF files" });
        return;
      }

      if (!insuredId) {
        toast({ title: "Error - missing insured ID" });
        return;
      }
      try {
        setUploadingFile(true);
        const id = await uploadFiles([file], insuredId || "", File_Audience.Internal, "MANUAL_UPLOAD");
        if (id) {
          setFiles([...files, { id, name: file.name }]);
          setUploadingFile(false);
        }
      } catch {
        toast({ title: "Error uploading file", description: "Please try again" });
      }
    }
  };

  const handleRemoveFile = (idToRemove: string) => {
    setFiles(files.filter((file) => file.id !== idToRemove));
  };

  const handleSend = () => {
    onSend(
      emailComment,
      files.map((file) => file.id)
    );
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Send Email with Quote</DialogTitle>
        </DialogHeader>
        <div className="gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="email-body" className="col-span-4">
              Email Body
            </Label>
            <Textarea
              id="email-body"
              value={emailComment}
              onChange={(e) => setEmailComment(e.target.value)}
              className="col-span-4"
              rows={10}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            {uploadingFile ? (
              <Spinner />
            ) : (
              <>
                <div className="col-span-4">Attach Files (PDF only)</div>
                <Input id="" type="file" onChange={handleFileChange} className="col-span-4" accept=".pdf" />{" "}
              </>
            )}
          </div>
          <div className="col-span-4">
            <Label>Attached Files:</Label>
            <ul className="mt-2 space-y-2">
              <li className="flex items-center justify-between bg-gray-100 p-2 rounded">
                <span>{quote?.processedQuote?.filename}</span>
              </li>
              {files.map((file) => (
                <li key={file.id} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                  <span>{file.name}</span>
                  <Button variant="ghost" size="sm" onClick={() => handleRemoveFile(file.id)}>
                    <X className="h-4 w-4" />
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSend}>Send Email</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
