import { FetchResult } from "@apollo/client";
import { useState } from "react";
import { z } from "zod";

import { ContinueButton } from "@/components/continue-button-v2";
import { ScrollPane } from "@/components/scroll-pane";
import { Sidebar, SidebarContent, SidebarHeader, SidebarTrigger } from "@/components/sidebar";
import { Bar } from "@/components/ui/bar";
import { useAccount } from "@/hooks/use-account";
import { CreateInsuredAndAgentMutation, CreateInsuredMutation, File_Audience } from "src/generated/graphql";
import { uploadFiles } from "src/utils/file";
import { Form, FormElement } from "../forms-v2/form";
import { InsuredForm } from "./insured-form";
import { OrEmail, SubmissionTipCard, UploadTipCard } from "./submission-cards";
import { AgentFormSchema, useCreateInsured2 } from "./use-create-opportunity";
import { LoadFromDocProviderProvider } from "./use-load-from-doc";

export const New = () => {
  const { user } = useAccount();
  // TODO: Maybe use form context to store file so we don't have to pass it down.
  const [file, setFile] = useState<File>();
  const { defaultValues, validationSchema, createInsured } = useCreateInsured2(user);

  const onSubmit = async (values: z.infer<typeof AgentFormSchema>) => {
    const res = await createInsured(values);

    let insuredId;

    if (isCreateInsuredAndAgent(res)) {
      insuredId = res.data?.createInsuredAndAgent.id; // Access BrokerCreateOpportunity properties
    } else if (isCreateInsured(res)) {
      insuredId = res.data?.createInsured.id; // Access AgentCreateOpportunity properties
    }

    if (file && insuredId) {
      await uploadFiles([file], insuredId, File_Audience.External, "MANUAL_UPLOAD");
    }
  };

  return (
    <Form validationSchema={validationSchema} defaultValues={defaultValues} providerOnly>
      <LoadFromDocProviderProvider setFile={setFile}>
        <ScrollPane sidebarPosition="right">
          <FormElement onSubmit={onSubmit}>
            <Bar>
              Create New Insured
              <SidebarTrigger separator={false} />
            </Bar>

            <InsuredForm />

            <Bar as="footer">
              <ContinueButton className="ml-auto" />
            </Bar>
          </FormElement>
        </ScrollPane>

        <Sidebar width="20rem">
          <SidebarHeader>Submission Tips</SidebarHeader>
          <SidebarContent>
            <SubmissionTipCard />
            <UploadTipCard />
            <OrEmail />
          </SidebarContent>
        </Sidebar>
      </LoadFromDocProviderProvider>
    </Form>
  );
};

function isCreateInsuredAndAgent(
  res: FetchResult<CreateInsuredAndAgentMutation> | FetchResult<CreateInsuredMutation>
): res is FetchResult<CreateInsuredAndAgentMutation> {
  return (res as FetchResult<CreateInsuredAndAgentMutation>)?.data?.createInsuredAndAgent !== undefined;
}

function isCreateInsured(
  res: FetchResult<CreateInsuredAndAgentMutation> | FetchResult<CreateInsuredMutation>
): res is FetchResult<CreateInsuredMutation> {
  return (res as FetchResult<CreateInsuredMutation>)?.data?.createInsured !== undefined;
}
