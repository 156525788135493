import { Button } from "@/components/ui/button";
import { useState } from "react";
import { PolicyFileType, PolicyFragment } from "src/generated/graphql";
import { cn } from "../../../utils";
import { uploadPolicyDocument } from "../../../utils/file";
import { Spinner } from "../../components/ui/loading";
import { toast } from "../../hooks/use-toast";
import { GenerateProcessedBinderButton } from "./generate-processed-binder-button";

export const ReceivedBinderFromCarrierActions = ({
  policy,
  refetch,
}: {
  policy: PolicyFragment;
  refetch: () => void;
}) => {
  const { id: policyId } = policy;
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <GenerateProcessedBinderButton policy={policy} />
      <Button asChild variant="outline" size="sm" display="flex">
        <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
          <input
            type="file"
            name="file"
            className="hidden"
            onChange={async (e) => {
              setSubmitting(true);
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];

                if (file.type !== "application/pdf") {
                  toast({ title: "We only accept PDF files" });
                  setSubmitting(false);
                  return;
                }
                await uploadPolicyDocument(file, policyId, PolicyFileType.ProcessedBinder).then((res) => {
                  if (res.success) {
                    toast({ title: "Processed Binder Uploaded" });
                    refetch();
                  } else {
                    toast({ title: "Error" });
                  }
                });
              }
              setSubmitting(false);
            }}
          />
          {submitting ? <Spinner /> : "Upload Processed Binder"}
        </label>
      </Button>
      <Button asChild variant="outline" size="sm" display="flex">
        <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
          <input
            type="file"
            name="file"
            className="hidden"
            onChange={async (e) => {
              setSubmitting(true);
              if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];

                if (file.type !== "application/pdf") {
                  toast({ title: "We only accept PDF files" });
                  setSubmitting(false);
                  return;
                }
                await uploadPolicyDocument(file, policyId, PolicyFileType.RedactedBinder).then((res) => {
                  if (res.success) {
                    toast({ title: "Redacted Quote Uploaded" });
                    refetch();
                  } else {
                    toast({ title: "Error" });
                  }
                });
              }
              setSubmitting(false);
            }}
          />
          {submitting ? <Spinner /> : "Upload New Redacted Binder"}
        </label>
      </Button>
    </>
  );
};
