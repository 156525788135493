import { zodResolver } from "@hookform/resolvers/zod";
import { HTMLAttributes } from "react";
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useFormContext,
  UseFormProps,
} from "react-hook-form";
import { z } from "zod";

type AsyncDefaultValues<TFieldValues> = (payload?: unknown) => Promise<TFieldValues>;

type DefaultSchema = z.ZodObject<z.ZodRawShape> | z.ZodEffects<z.ZodObject<z.ZodRawShape>>;

export interface FormProps<TFieldValues extends FieldValues, TSchema extends DefaultSchema>
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit" | "onError"> {
  defaultValues?: AsyncDefaultValues<TFieldValues> | DefaultValues<TFieldValues>;
  validationSchema?: TSchema;
  onSubmit?: SubmitHandler<TFieldValues>;
  onError?: SubmitErrorHandler<TFieldValues>;
  mode?: Mode;
  useFormProps?: UseFormProps<TFieldValues>;
  providerOnly?: boolean;
}

export function Form<TFieldValues extends FieldValues, TSchema extends DefaultSchema>({
  mode = "onTouched",
  defaultValues = {} as any,
  validationSchema,
  onSubmit,
  onError,
  useFormProps,
  providerOnly,
  children,
  ...props
}: FormProps<TFieldValues, TSchema>) {
  const methods = useForm<TFieldValues>({
    mode,
    defaultValues,
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
    ...useFormProps,
  });

  const handleSubmit: SubmitHandler<TFieldValues> = async (values, event) => {
    return await onSubmit?.(values, event);
  };

  const handleError: SubmitErrorHandler<TFieldValues> = async (errors, event) => {
    return await onError?.(errors, event);
  };

  return (
    <FormProvider {...methods}>
      {providerOnly && children}

      {!providerOnly && (
        <FormElement onSubmit={handleSubmit} onError={handleError} {...props}>
          {children}
        </FormElement>
      )}
    </FormProvider>
  );
}

export interface FormElementProps<TFieldValues extends FieldValues>
  extends Omit<HTMLAttributes<HTMLFormElement>, "onSubmit" | "onError"> {
  onSubmit: FormProps<TFieldValues, any>["onSubmit"];
  onError?: SubmitErrorHandler<TFieldValues>;
}

export function FormElement<TFieldValues extends FieldValues>({
  onSubmit,
  onError,
  ...props
}: FormElementProps<TFieldValues>) {
  const methods = useFormContext<TFieldValues>();

  const handleSubmit: SubmitHandler<TFieldValues> = async (values, event) => {
    return await onSubmit?.(values, event);
  };

  const handleError: SubmitErrorHandler<TFieldValues> = async (errors, event) => {
    return await onError?.(errors, event);
  };

  return <form onSubmit={methods.handleSubmit(handleSubmit, handleError)} {...props} />;
}
