import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { z } from "zod";

import { HasInternalRole } from "@/components/has-role";
import { useModal } from "@/components/modal-provider";
import { MoreMenu } from "@/components/more-menu";
import { Button } from "@/components/ui/button";
import { DialogContent } from "@/components/ui/dialog";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { useToast } from "@/hooks/use-toast";
import {
  FilesByLabelQuery,
  FileUploadFragment,
  File_Audience,
  useDeleteFileUploadMutation,
  useUpdateFileUploadMutation,
} from "src/generated/graphql";
import { downloadFile } from "src/utils/file";

import { PDFViewer } from "./file-upload-viewer";
import TagSelector from "./tag-selector";

export const FILE_MENU_OPTION_KEYS = [
  "editTags",
  "editPermissions",
  "renameFile",
  "deleteFile",
  "download",
  "viewInsured",
] as const;

const defaultInclude = FILE_MENU_OPTION_KEYS.filter((key) => key !== "viewInsured");

export type FileMenuOptionKeys = (typeof FILE_MENU_OPTION_KEYS)[number];

export interface FileMenuProps {
  file: FileUploadFragment & { labels: string[]; insuredId?: string | null };
  include?: FileMenuOptionKeys[];
}

export const FileMenu: FC<FileMenuProps> = ({ file, include = defaultInclude }) => (
  <MoreMenu>
    {include.includes("editPermissions") && <EditPermissions file={file} />}
    {include.includes("renameFile") && <RenameFile file={file} />}
    {include.includes("editTags") && <EditTags file={file} />}
    {include.includes("deleteFile") && <DeleteFile file={file} />}
    {include.includes("viewInsured") && <ViewInsured file={file} />}
    {include.includes("download") && <DownloadFile file={file} />}
  </MoreMenu>
);

export const RenameFile = ({ file }: { file: FileMenuProps["file"] }) => {
  const { openForm } = useModal();
  const [update] = useUpdateFileUploadMutation();
  const { toast } = useToast();

  const validationSchema = z.object({ filename: z.string().min(1, { message: "Invalid name" }) });

  const defaultValues = { filename: file.filename ?? "" };

  const onSubmit = async (values: z.infer<typeof validationSchema>) =>
    await update({
      variables: { input: { id: file.id, ...values } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `Filename updated` }),
      refetchQueries: ["FilesByLabel"],
    });

  const handleClick = () =>
    openForm(<FieldInput name="filename" placeholder="Audience" />, {
      type: "dialog",
      title: "Rename file",
      description: (
        <>
          Rename the selected file: {file.filename}
          <div>A file extension (.pdf, .png, etc) is not necessary</div>
        </>
      ),
      validationSchema,
      defaultValues,
      onSubmit,
    });

  return (
    <HasInternalRole>
      <DropdownMenuItem onClick={handleClick}>
        <Icon icon="edit" />
        Edit filename
      </DropdownMenuItem>
    </HasInternalRole>
  );
};

export function EditTags({ file }: { file: FileMenuProps["file"] }) {
  const { openModal } = useModal();

  const handleClick = () =>
    openModal(() => <TagSelector fileId={file.id} />, {
      type: "dialog",
      title: "Edit tags",
      description: (
        <>
          Add or remove tags for this file.
          <div>{file.filename}</div>
        </>
      ),
    });

  return (
    <HasInternalRole>
      <DropdownMenuItem onClick={handleClick}>
        <Icon icon="style" />
        Edit tags
      </DropdownMenuItem>
    </HasInternalRole>
  );
}

export function EditPermissions({ file }: { file: FileMenuProps["file"] }) {
  const { openForm } = useModal();
  const { toast } = useToast();
  const [update] = useUpdateFileUploadMutation();

  const validationSchema = z.object({ audience: z.nativeEnum(File_Audience) });

  const defaultValues = { audience: (file?.audience as File_Audience) ?? File_Audience.Internal };

  const options = [
    { value: File_Audience.Internal, label: "Internal users only" },
    { value: File_Audience.External, label: "All users" },
  ];

  const onSubmit = async ({ audience }: z.infer<typeof validationSchema>) =>
    await update({
      variables: { input: { id: file.id, audience } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `Permission updated` }),
      refetchQueries: ["FilesByLabel"],
    });

  const handleClick = () =>
    openForm(<FieldSelect name="audience" placeholder="Audience" options={options} />, {
      type: "dialog",
      title: "Edit permissions",
      description: (
        <>
          Select who should have access to this file
          <div>{file.filename}</div>
        </>
      ),
      validationSchema,
      defaultValues,
      onSubmit,
    });

  return (
    <HasInternalRole>
      <DropdownMenuItem onClick={handleClick}>
        <Icon icon="lock" />
        Edit permissions
      </DropdownMenuItem>
    </HasInternalRole>
  );
}

export function DeleteFile({ file }: { file: FileMenuProps["file"] & { deletedAt?: Date | null } }) {
  const { openConfirmation } = useModal();
  const { toast } = useToast();
  const [deleteFile] = useDeleteFileUploadMutation();
  const restoreFile = file.deletedAt ? false : true;

  const handleClick = async () => {
    const isConfirmed = await openConfirmation({
      title: restoreFile ? "Delete file" : "Restore file",
      description: (
        <>
          {restoreFile ? "Delete file" : "Restore file"}: {file.filename}
          <div>This will {restoreFile ? "delete the file" : "restore the file"}.</div>
        </>
      ),
      buttonProps: { theme: "destructive", children: restoreFile ? "Delete file" : "Restore file" },
    });

    if (!isConfirmed) {
      return;
    }

    await deleteFile({
      variables: { input: { id: file.id } },
      onError: (e) => toast({ title: e.message }),
      onCompleted: () => toast({ title: `File ${restoreFile ? "deleted" : "restored"}` }),
      refetchQueries: ["FilesByLabel"],
    });
  };

  return (
    <HasInternalRole>
      <DropdownMenuItem onClick={handleClick}>
        <Icon icon="delete" />
        {restoreFile ? "Delete file" : "Restore file"}
      </DropdownMenuItem>
    </HasInternalRole>
  );
}

export const ViewInsured = ({ file }: { file: FileMenuProps["file"] }) => (
  <DropdownMenuItem asChild>
    <Link to={`/insured/${file.insuredId}`}>
      <Icon icon="person" />
      View Insured
    </Link>
  </DropdownMenuItem>
);

export const DownloadFile = ({ file }: { file: FileMenuProps["file"] }) => (
  <DropdownMenuItem>
    <a
      href={`/api/files/${file.id}`}
      download={file.filename}
      media={file.mimeType}
      onClick={async (e) => {
        e.preventDefault();
        await downloadFile(file);
      }}
      className="flex gap-3 items-center"
    >
      <Icon icon="download" />
      Download
    </a>
  </DropdownMenuItem>
);

export function ViewPDF({
  file,
  files,
  setSelectedFile,
}: {
  file: FileUploadFragment;
  files: FilesByLabelQuery["filesByLabel"];
  setSelectedFile: (file: any) => void;
}) {
  const [index, setIndex] = useState<number>();

  useEffect(() => {
    const index = files.map((f) => f.id).indexOf(file.id);
    setIndex(index);
  }, [file]);

  const getNextFile = () => {
    if (!index && index !== 0) {
      return;
    }
    setSelectedFile(files[index + 1]);
  };

  const getPreviousFile = () => {
    if (!index) {
      return;
    }
    setSelectedFile(files[index - 1]);
  };

  return (
    <DialogContent
      onClick={(e) => e.stopPropagation()}
      className="flex justify-center items-center"
      style={{
        minWidth: "80vw",
        minHeight: "80vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button onClick={getPreviousFile} disabled={index === 0}>
        <Icon icon="chevron_left" />
      </Button>
      <PDFViewer file={file} />
      <Button onClick={getNextFile} disabled={index === files.length - 1}>
        <Icon icon="chevron_right" />
      </Button>
    </DialogContent>
  );
}
