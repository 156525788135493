import { type ComponentProps, type FC, type ReactNode } from "react";
import { useController, useFormContext } from "react-hook-form";

import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";

export interface FieldToggleGroupOption {
  label: ReactNode;
  value: string;
  itemProps?: Omit<ComponentProps<typeof ToggleGroupItem>, "value">;
}

export type FieldToggleGroupProps = FieldBaseProps & {
  options: readonly FieldToggleGroupOption[];
  type?: ComponentProps<typeof ToggleGroup>["type"];
  onChange?: (value: string | string[]) => void;
  onBlur?: ComponentProps<typeof ToggleGroupItem>["onBlur"];
  inputProps?: ComponentProps<typeof ToggleGroup>;
};

export const FieldToggleGroup: FC<FieldToggleGroupProps> = ({
  options,
  type = "single",
  onChange,
  onBlur,
  inputProps,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled: props.disabled });

  const error = !!formState.errors[props.name];

  const handleChange: FieldToggleGroupProps["onChange"] = (value: string | string[]) => {
    field.onChange(value);
    onChange?.(value);
  };

  const handleBlur: FieldToggleGroupProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase {...props}>
      <ToggleGroup
        ref={field.ref}
        type={type || "single"}
        onValueChange={handleChange}
        value={field.value}
        {...inputProps}
        className="my-1"
      >
        {options.length > 0 && (
          <>
            {options.map((option) => (
              <div key={option.value} className="flex items-center gap-2">
                <ToggleGroupItem
                  value={option.value}
                  onBlur={handleBlur}
                  disabled={props.disabled}
                  {...option.itemProps}
                  className={cn({ "border-destructive": !!error }, option.itemProps?.className)}
                >
                  {option.label}
                </ToggleGroupItem>
              </div>
            ))}
          </>
        )}
      </ToggleGroup>
    </FieldBase>
  );
};
