import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { FormField, FormItem } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { TextArea } from "@/forms/default";
import { QUOTE_STATE_METADATA } from "@/metadata";
import {
  QuoteAction,
  QuoteFragment,
  useSendBindRequestMutation,
  useTransitionQuoteMutation,
} from "src/generated/graphql";

export const BindRequestReceivedButton = ({ quote }: { quote: QuoteFragment }) => {
  const { openConfirmation, openForm } = useModal();
  const [transition] = useTransitionQuoteMutation();
  const [sendBindRequestTrigger] = useSendBindRequestMutation();

  const handleTransition = async (action: QuoteAction) => {
    const isConfirmed = await openConfirmation({
      title: `Confirm ${QUOTE_STATE_METADATA[quote?.state].actionDisplayNames?.[action] ?? action}`,
    });

    if (!isConfirmed) {
      return;
    }

    await transition({
      variables: {
        input: {
          id: quote.id,
          expectedState: quote.state,
          action,
        },
      },
    });
  };

  const sendBindRequest = async () =>
    await openForm(<SendBindRequestForm />, {
      title: "Send Bind Request",
      defaultValues: { tria: "", outstandingSubjectivities: "", carrierContactInformation: "" },
      validationSchema: z.object({
        tria: z.string().min(1, { message: "TRIA selection is required" }),
        outstandingSubjectivities: z.string().min(1, { message: "Outstanding subjectivities is required" }),
        carrierContactInformation: z.string().optional(),
      }),
      onSubmit: async (fd) =>
        await sendBindRequestTrigger({
          variables: {
            input: {
              quoteId: quote.id,
              tria: fd.tria,
              outstandingSubjectivities: fd.outstandingSubjectivities,
              carrierContactInformation: fd.carrierContactInformation,
            },
          },
          onCompleted: () => handleTransition(QuoteAction.ReceiveBindRequest),
        }),
    });

  return (
    <Button onClick={sendBindRequest} variant="outline" size="sm" display="flex">
      Bind Request Received
    </Button>
  );
};

function SendBindRequestForm() {
  const { setValue, watch, trigger, formState, control } = useFormContext();
  const errors = formState.errors;
  return (
    <div className="space-y-3">
      <div className="space-y-1.5">
        <FormField
          control={control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-auto">
              <Select
                key={field.value}
                required={true}
                name="tria"
                value={watch("tria")}
                onValueChange={(v) => {
                  setValue("tria", v);
                  void trigger("tria");
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="TRIA - Accepted / Rejected / Included / N/A" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Accepted">Accepted</SelectItem>
                  <SelectItem value="Rejected">Rejected</SelectItem>
                  <SelectItem value="Included">Included</SelectItem>
                  <SelectItem value="N/A">N/A</SelectItem>
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <div className="text-xs text-destructive">{errors?.tria?.message?.toString()}</div>
      </div>
      <div className="space-y-1.5">
        <TextArea
          required={true}
          name="outstandingSubjectivities"
          placeholder="Outstanding subjectivities (enter N/A if none apply)"
          className="px-4"
        />
        <div className="text-xs text-destructive">{errors?.outstandingSubjectivities?.message?.toString()}</div>
      </div>
      <TextArea name="carrierContactInformation" placeholder="Carrier contact (if brokerage)" className="px-4" />
    </div>
  );
}
