import { useLoginEmailPassword } from "@cp/auth";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";

import { Group } from "@/components/group";
import { OrEmailDisplay } from "@/components/or-email";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { FieldCheckbox } from "@/forms-v2/fields/field-checkbox";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { Form } from "@/forms-v2/form";
import { FormSubmit } from "@/forms-v2/form-submit";

import { AuthCard } from "./auth-card";

const defaultValues = {
  email: "",
  password: "",
  rememberMe: true,
};

const validationSchema = z.object({
  email: z.string().min(1, { message: "Please enter your email address" }),
  password: z.string().min(1, { message: "Please enter your password" }),
  rememberMe: z.boolean(),
});

export const Login = () => {
  const navigate = useNavigate();
  const { mutate: loginEmailPassword, error, isLoading } = useLoginEmailPassword();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("redirect") ?? "/";

  const onSubmitEmailPassword = (data: z.infer<typeof validationSchema>) => {
    loginEmailPassword(data, {
      onSuccess: () => {
        navigate(redirect);
      },
    });
  };

  return (
    <>
      <Form onSubmit={onSubmitEmailPassword} defaultValues={defaultValues} validationSchema={validationSchema}>
        <AuthCard
          header="Sign in"
          subHeader="Welcome back! Please enter your credentials to access your account."
          error={error}
        >
          <CardContent>
            <Group>
              <Group className="gap-2">
                <FieldInput name="email" type="email" placeholder="Email" disabled={isLoading} />
                <FieldInput name="password" type="password" placeholder="Password" disabled={isLoading} />
              </Group>
              <FieldCheckbox name="rememberMe" label="Remember me" disabled={isLoading} />
            </Group>
          </CardContent>
          <CardFooter>
            <Group className="flex-1">
              <FormSubmit className="grow" disabled={isLoading || false}>
                Sign In
              </FormSubmit>
              <Button variant="link" disabled={isLoading} asChild>
                <Link to="/forgot-password">Forgot your password?</Link>
              </Button>
            </Group>
          </CardFooter>
        </AuthCard>
      </Form>

      <div className="relative flex py-5 items-center">
        <div className="grow border-t border-muted-foreground"></div>
        <span className="shrink mx-4 text-muted-foreground">Or</span>
        <div className="grow border-t border-muted-foreground"></div>
      </div>
      <OrEmailDisplay className="max-w-xs" />
    </>
  );
};
