import { Spinner } from "@/components/ui/loading";
import { ErrorPage } from "@/error";
import { jwtDataAtom } from "@cp/auth";
import { useAtomValue } from "jotai";
import { createContext, useContext } from "react";
import { Navigate } from "react-router";

import { MyAccountQuery, useMyAccountQuery } from "src/generated/graphql";

interface AccountContextValue {
  user: NonNullable<MyAccountQuery["myAccount"]>;
  impersonated: boolean;
}

const AccountContext = createContext({} as AccountContextValue);

export const AccountProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: { myAccount: user } = {},
    loading,
    error,
  } = useMyAccountQuery({
    fetchPolicy: "cache-and-network",
  });

  const jwtPayload = useAtomValue(jwtDataAtom);

  if (loading) {
    return <Spinner className="mx-6 my-4" />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const value = {
    user,
    impersonated: Boolean(jwtPayload?.impersonatedById),
  };

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};

export const useAccount = () => {
  const context = useContext(AccountContext);

  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }

  return context;
};
