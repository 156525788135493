import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { FC } from "react";

import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldNumber } from "@/forms-v2/fields/field-number";
import { FieldRow } from "@/forms-v2/fields/field-row";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { useAppetiteProductLinesQuery, useAppetiteProductsCountQuery } from "src/generated/graphql";

import { AgentForm } from "./agent-form";
import { useLoadFromDoc } from "./use-load-from-doc";

export const InsuredForm: FC = () => {
  const { data: linesData, loading: loadingLines } = useAppetiteProductLinesQuery();
  const { data: productsCount, loading: loadingProducts } = useAppetiteProductsCountQuery();

  const { parentProps, onClick, ...docUploadMethods } = useLoadFromDoc();

  const uploadInProgress = docUploadMethods.loading;

  return (
    <div {...parentProps} className="flex-auto">
      <div className="bg-border border-b space-y-px">
        <div className="bg-background flex flex-row gap-5 items-start p-6 pl-5">
          <Icon icon="psychology_alt" className="filled mt-0.5 text-2xl text-primary" />
          <div className="space-y-3">
            <h2>Insured Basics</h2>
            <p className="text-base">
              Provide a few details about your insured to help us start quoting from our panel of{" "}
              <strong>
                {loadingProducts ? <Spinner className="inline text-xs" /> : productsCount?.productsCount} risk solutions
              </strong>{" "}
              offering{" "}
              <strong>
                {loadingLines ? <Spinner className="inline text-xs" /> : linesData?.appetiteProductLines.length} types
                of coverage
              </strong>
              .
            </p>
          </div>
        </div>

        <div className="flex flex-col divide-y">
          <FieldRow icon="business_center">
            <FieldInput
              name="clientName"
              placeholder={uploadInProgress ? "Loading..." : "Insured Name (This is how it will appear on the Policy)"}
              disabled={uploadInProgress}
              borderless
            />
          </FieldRow>

          <FieldRow icon="location_on">
            <FieldSelect
              name="state"
              placeholder={uploadInProgress ? "Loading..." : "Primary state"}
              options={STRICT_STATE_OPTIONS}
              disabled={uploadInProgress}
              borderless
            />
          </FieldRow>

          <FieldRow icon="attach_money">
            <FieldNumber
              name="revenue"
              placeholder="Gross revenue"
              inputProps={{ prefix: "$", thousandSeparator: true, decimalScale: 2 }}
              borderless
            />
          </FieldRow>

          <FieldRow icon="storefront">
            <FieldTextarea
              name="description"
              placeholder={uploadInProgress ? "Loading..." : "Business Description"}
              disabled={uploadInProgress}
              borderless
              inputProps={{ className: "resize-none" }}
            />
          </FieldRow>
        </div>
      </div>

      <AgentForm />
    </div>
  );
};
