import { STRICT_STATE_OPTIONS } from "@cp/toolkit";
import { z } from "zod";

import { ContinueButton } from "@/components/continue-button-v2";
import { Group } from "@/components/group";
import { Card } from "@/components/ui/card";
import { FieldDatePicker } from "@/forms-v2/fields/field-date-picker";
import { FieldInput as FieldInputV2 } from "@/forms-v2/fields/field-input";
import { FieldCurrency } from "@/forms-v2/fields/field-number";
import { FieldPattern } from "@/forms-v2/fields/field-pattern";
import { FieldRow } from "@/forms-v2/fields/field-row";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";

export const FirstSubmissionFormSchema = z.object({
  firstName: z.string().min(1, { message: "First name is required" }),
  lastName: z.string().min(1, { message: "Last name is required" }),
  agentEmail: z.string().min(1, { message: "Email is required" }).email({ message: "Email is invalid" }),
  agentPhone: z.string().optional(),
  agencyName: z.string().min(1, { message: "Agency name is required" }),
  agencyPrimaryState: z.string().length(2, { message: "State is required" }),
  clientName: z.string().min(1, { message: "Business name is required" }),
  clientState: z.string().length(2, { message: "State is required" }),
  desiredEffectiveDate: z.date({ message: "Desired effective date is required" }),
  revenue: z.number().optional(),
  description: z.string().min(1, { message: "Business description is required" }),
});

export const defaultValues = {
  firstName: "",
  lastName: "",
  agentEmail: "",
  agentPhone: "",
  agencyName: "",
  agencyPrimaryState: "",
  clientName: "",
  clientState: "",
  desiredEffectiveDate: undefined,
  revenue: undefined,
  description: "",
};

interface FirstSubmissionFormProps {
  onSubmit: (values: z.infer<typeof FirstSubmissionFormSchema>) => Promise<void>;
}

export const FirstSubmissionForm: React.FC<FirstSubmissionFormProps> = ({ onSubmit }) => (
  <Form
    validationSchema={FirstSubmissionFormSchema}
    defaultValues={defaultValues}
    onSubmit={onSubmit}
    className="flex-1"
  >
    <Group>
      <Card className="bg-muted divide-y">
        <FieldRow icon="badge">
          <FieldInputV2 name="firstName" placeholder="Agent first name*" borderless />
        </FieldRow>

        <FieldRow icon="badge">
          <FieldInputV2 name="lastName" placeholder="Agent last name*" borderless />
        </FieldRow>

        <FieldRow icon="alternate_email">
          <FieldInputV2 name="agentEmail" placeholder="Agent email*" borderless />
        </FieldRow>

        <FieldRow icon="call">
          <FieldPattern name="agentPhone" placeholder="Agent phone" format="+1 (###) ###-####" borderless />
        </FieldRow>

        <FieldRow icon="business_center">
          <FieldInputV2 name="agencyName" placeholder="Agency name*" borderless />
        </FieldRow>

        <FieldRow icon="location_on">
          <FieldSelect
            name="agencyPrimaryState"
            placeholder="Agency primary state*"
            options={STRICT_STATE_OPTIONS}
            borderless
          />
        </FieldRow>
      </Card>

      <Card className="bg-muted divide-y">
        <FieldRow icon="business_center">
          <FieldInputV2
            name="clientName"
            placeholder="Insured name* (This is how the Named Insured will appear on the Policy)"
            borderless
          />
        </FieldRow>

        <FieldRow icon="location_on">
          <FieldSelect name="clientState" placeholder="Primary state*" options={STRICT_STATE_OPTIONS} borderless />
        </FieldRow>

        <FieldRow icon="event">
          <FieldDatePicker
            name="desiredEffectiveDate"
            placeholder="Desired effective date*"
            borderless
            hideCalendarIcon
          />
        </FieldRow>

        <FieldRow icon="attach_money">
          <FieldCurrency name="revenue" placeholder="Gross revenue" borderless />
        </FieldRow>

        <FieldRow icon="storefront">
          <FieldTextarea name="description" placeholder="Business description*" borderless />
        </FieldRow>
      </Card>

      <div className="bottom-4 portrait:bottom-0 flex justify-end sticky z-10">
        <ContinueButton />
      </div>
    </Group>
  </Form>
);
