import type { ComponentProps, FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { DatePicker } from "@/components/ui/date-picker-v2";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";
import { FIELD_BORDERLESS_CLASS_NAME } from "./field.constants";

export type FieldDatePickerProps = FieldBaseProps & {
  placeholder?: string;
  borderless?: boolean;
  dateFormat?: string;
  hideCalendarIcon?: boolean;
  inputProps?: ComponentProps<typeof DatePicker>;
};

export const FieldDatePicker: FC<FieldDatePickerProps> = ({
  inputProps,
  borderless,
  dateFormat,
  placeholder,
  hideCalendarIcon,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled: props.disabled });

  const error = !!formState.errors[props.name];

  return (
    <FieldBase {...props}>
      <DatePicker
        {...field}
        {...inputProps}
        placeholder={placeholder}
        dateFormat={dateFormat}
        hideCalendarIcon={hideCalendarIcon}
        className={cn(
          "text-sm",
          { "border-destructive": !!error, [FIELD_BORDERLESS_CLASS_NAME]: borderless },
          inputProps?.className
        )}
      />
    </FieldBase>
  );
};
