import { KnownClientData } from "@qw/qw-common";
import { useEffect } from "react";
import { useSubmissionDetailsQuery } from "../../../generated/graphql";
import { cn } from "../../../utils";
import { formatDate } from "../../../utils/date";
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Icon } from "../../components/ui/icon";
import { useAlbyApproval } from "../../hooks/use-alby-approval";
import { useInsured } from "../../hooks/use-insured";
import { useToast } from "../../hooks/use-toast";
import { useUpsertSearchParams } from "../../hooks/use-upsert-search-params";
import { SubmissionTemplateAttachments } from "../../submission-template/submission-template-attachments";
import { SubmissionTemplateForm } from "../../submission-template/submission-template-form";
import { SubmissionTemplatePdf } from "../../submission-template/submission-template-pdf";
import { SubmissionTemplateProvider, useSubmissionTemplate } from "../../submission-template/use-submission-template";

export default function SubmissionTransitionGraphSubmitToCarrier() {
  const [searchParams] = useUpsertSearchParams();
  const submissionId = searchParams.get("submissionId");

  const { threadId } = useAlbyApproval();

  if (!submissionId) {
    return null;
  }

  return (
    <SubmissionTemplateProvider>
      <SubmitToCarrierForm id={submissionId} threadId={threadId} />
    </SubmissionTemplateProvider>
  );
}

const SubmitToCarrierForm = ({ id, threadId }: { id: string; threadId: string | null }) => {
  const { toast } = useToast();
  const { insured } = useInsured();
  const { approve, decline, resumeLoading } = useAlbyApproval();

  const { data: { submission } = {} } = useSubmissionDetailsQuery({
    variables: {
      id,
    },
  });

  const { createEmailDraft, fileIds, selectedFile, setSubmissionId } = useSubmissionTemplate();

  useEffect(() => {
    setSubmissionId(id);
  }, [id]);

  if (!submission) {
    return null;
  }

  return (
    <div
      className={cn(
        "bg-background border-t flex-auto overflow-y-auto px-6 py-4 relative",
        selectedFile && gridClassName
      )}
    >
      <SubmissionTemplatePdf />
      <div className={cn(selectedFile ? "flex flex-col gap-6" : gridClassName)}>
        <div className="space-y-6">
          {threadId && (
            <Card className="hover-outline outline-primary">
              <CardHeader>
                <CardTitle className="text-primary">
                  <Icon icon="smart_toy" />
                  <span className="mr-auto">May I submit to carrier?</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <ul className="space-y-1.5">
                  <ChecklistItem>
                    We have all required information for the insured <strong>{insured.name}</strong>.
                  </ChecklistItem>
                  <ChecklistItem>
                    <strong>
                      {submission.appetiteProduct.carrierName} {submission.appetiteProduct.carrierProductName}
                    </strong>{" "}
                    is one of the markets on this plan.
                  </ChecklistItem>
                </ul>
                <p>I&apos;ve drafted an email using our standard submission template. It&apos;ll be sent to:</p>
                <ul className="list-disc ml-6">
                  {submission.appetiteProduct.submissionCarrierContacts?.map((contact, key) => (
                    <li key={key}>
                      <a href={`mailto:{contact.email}`} className="underline">
                        {contact.firstName} {contact.lastName} ({contact.email})
                      </a>
                    </li>
                  ))}
                </ul>
                <p>Upon approval, I&apos;ll send this email from your inbox straightaway!</p>
                <p className="italic">&mdash; Alby</p>
              </CardContent>
              <CardFooter>
                <Button type="submit" theme="primary" className="ml-auto" form="pdf-input" disabled={resumeLoading}>
                  Approve
                </Button>
              </CardFooter>
            </Card>
          )}
          <SubmissionTemplateForm
            onSubmit={(values) => {
              threadId &&
                void approve(
                  {
                    desiredEffectiveDate: formatDate(new Date(submission.opportunity.desiredEffectiveDate)),
                    primaryState: submission.opportunity.insured.primaryState,
                    mailingAddressLine1: values[KnownClientData.MailingAddress.AddressLine1],
                    mailingAddressCity: values[KnownClientData.MailingAddress.City],
                    mailingAddressState: values[KnownClientData.MailingAddress.State],
                    mailingAddressZip: values[KnownClientData.MailingAddress.Zip],
                    descriptionOfOperations: values[KnownClientData.DescriptionOfOperations],
                    exposures: values[KnownClientData.Exposures],
                    requestLimits: values[KnownClientData.RequestedLimits],
                  },
                  {
                    onCompleted(data) {
                      if (data.resumeGraph.approvedBy) {
                        toast({ title: "Alby action approved" });
                      }
                    },
                    refetchQueries: ["ResumableGraph"],
                  }
                );
            }}
            Buttons={
              threadId ? (
                <Button
                  type="button"
                  disabled={resumeLoading}
                  variant="outline"
                  onClick={async () => {
                    await decline();
                    void createEmailDraft(fileIds);
                  }}
                >
                  Create Draft
                </Button>
              ) : (
                <Button type="button" disabled={resumeLoading} onClick={async () => createEmailDraft(fileIds)}>
                  Create Draft
                </Button>
              )
            }
          />
        </div>
        <SubmissionTemplateAttachments />
      </div>
    </div>
  );
};

const gridClassName = "gap-4 grid grid-cols-[3fr_2fr] items-start";

const ChecklistItem = ({ children }: { children: React.ReactNode }) => (
  <li className="flex items-start gap-3 ml-px">
    <Icon icon="check_circle" className="filled mt-1 text-success" />
    <div>{children}</div>
  </li>
);
