import { ComponentProps, FC } from "react";
import { useController, useFormContext } from "react-hook-form";

import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "src/utils";

import { FieldBase, FieldBaseProps } from "./field-base";

export type FieldCheckboxProps = FieldBaseProps & {
  onChange?: ComponentProps<typeof Checkbox>["onCheckedChange"];
  onBlur?: ComponentProps<typeof Checkbox>["onBlur"];
  inputProps?: ComponentProps<typeof Checkbox>;
};

export const FieldCheckbox: FC<FieldCheckboxProps> = ({ onChange, onBlur, inputProps, ...props }) => {
  const { control, formState } = useFormContext();
  const { field } = useController({ control, name: props.name, disabled: props.disabled });

  const error = !!formState.errors[props.name];

  const handleChange: FieldCheckboxProps["onChange"] = (checked) => {
    field.onChange(checked);
    onChange?.(checked);
  };

  const handleBlur: FieldCheckboxProps["onBlur"] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <FieldBase labelOrientation="horizontal" labelPosition="end" className="[&_label]:font-normal" {...props}>
      <Checkbox
        {...field}
        {...inputProps}
        checked={field.value}
        onCheckedChange={handleChange}
        onBlur={handleBlur}
        className={cn({ "border border-destructive": !!error }, inputProps?.className)}
      />
    </FieldBase>
  );
};
