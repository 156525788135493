import { z } from "zod";

import { useModal } from "@/components/modal-provider";
import { Button } from "@/components/ui/button";
import { FieldInput } from "@/forms-v2/fields/field-input";
import {
  useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation,
  VerticalMarketingPlanTemplateProductFragment,
} from "src/generated/graphql";

import { useVertical } from "./use-vertical";

export const UpdateSubmissionEmail = ({ product }: { product: VerticalMarketingPlanTemplateProductFragment }) => {
  const { openForm } = useModal();
  const { refetch } = useVertical();

  const [updateVerticalMarketingPlanTemplateProductSubmissionEmailTrigger] =
    useUpdateVerticalMarketingPlanTemplateProductSubmissionEmailMutation({
      onCompleted: refetch,
    });

  const updateSubmissionEmail = async (product: VerticalMarketingPlanTemplateProductFragment) =>
    await openForm(<FieldInput name="submissionEmail" />, {
      title: "Update Submission Email",
      defaultValues: { submissionEmail: product.submissionEmail ?? "" },
      validationSchema: z.object({ submissionEmail: z.string() }),
      onSubmit: async (fd) =>
        await updateVerticalMarketingPlanTemplateProductSubmissionEmailTrigger({
          variables: { input: { id: product.id, submissionEmail: fd.submissionEmail } },
        }),
    });

  return (
    <Button variant="outline" size="xs" onClick={() => updateSubmissionEmail(product)}>
      {product.submissionEmail ?? "N / A"}
    </Button>
  );
};
