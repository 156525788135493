import { useState } from "react";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useInsured } from "@/hooks/use-insured";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { toast } from "@/hooks/use-toast";
import { VerticalForm } from "@/verticals/components/vertical-form";
import { CreateVerticalSchema, sanitizeCreateVerticalInput } from "@/verticals/edit-vertical";
import {
  useAddProductToVerticalMarketingPlanTemplateMutation,
  useCreateVerticalMarketingPlanTemplateMutation,
  useCreateVerticalMutation,
} from "src/generated/graphql";
import { parseError } from "src/utils";

export const VerticalsButton = () => {
  const { insured, verticalByCGL } = useInsured();
  const { opportunity } = useMarketingPlan();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [createVertical] = useCreateVerticalMutation({
    onError: (error) =>
      toast({ title: "Error creating vertical", description: parseError(error), variant: "destructive" }),
    refetchQueries: ["VerticalByCGL"],
  });

  const [createVerticalTemplate] = useCreateVerticalMarketingPlanTemplateMutation({
    onError: (error) =>
      toast({ title: "Error creating vertical template", description: parseError(error), variant: "destructive" }),
  });

  const [addProductsToVerticalTemplate] = useAddProductToVerticalMarketingPlanTemplateMutation({
    onError: (error) =>
      toast({
        title: "Error adding products to vertical template",
        description: parseError(error),
        variant: "destructive",
      }),
    refetchQueries: ["VerticalByCGL"],
  });

  // we can't work on verticals without a cgl code
  if (!insured || insured.isoCglCodes.length === 0) {
    return null;
  }

  // if no vertical exists, we show a button to create a vertical
  if (!verticalByCGL) {
    return (
      <AlertDialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <AlertDialogTrigger asChild>
          <Button variant="outline" size="xs">
            Create Vertical
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Add Vertical</AlertDialogTitle>
          </AlertDialogHeader>
          <VerticalForm
            schema={CreateVerticalSchema}
            preselectedCgls={[insured.isoCglCodes[0]]}
            onSubmit={async (values, selectedCgl) => {
              await createVertical({
                variables: { input: sanitizeCreateVerticalInput(values, selectedCgl) },
                onCompleted: () => setIsModalOpen(false),
              });
            }}
            onCancel={() => setIsModalOpen(false)}
            className="py-5 -mx-6 -my-12 pb-6"
          />
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  const { appetiteProducts, selectedLinesOfBusiness } = opportunity;
  const { id, marketingPlanTemplates } = verticalByCGL;

  const sortedSelected = [...selectedLinesOfBusiness].sort();

  const template = marketingPlanTemplates.find((mpt) => {
    if (mpt.lobs.length !== selectedLinesOfBusiness.length) {
      return false;
    }

    const sortedTemplate = [...mpt.lobs].sort();

    return sortedSelected.every((value, index) => value === sortedTemplate[index]);
  });

  const handleCreateVerticalTemplate = async () => {
    const newTemplate = await createVerticalTemplate({
      variables: {
        input: {
          verticalId: id,
          lobs: [...selectedLinesOfBusiness].sort(),
        },
      },
    });

    if (newTemplate.data) {
      await addProductsToVerticalTemplate({
        variables: {
          input: {
            verticalMarketingPlanTemplateId: newTemplate.data.createVerticalMarketingPlanTemplate.id,
            appetiteProductIds: appetiteProducts.map((product) => product.id),
          },
        },
      });
    }
  };

  // if a vertical exists, but no template matching the lobss exists, we show a button to create a template
  if (!template) {
    return (
      <Button variant="outline" size="xs" onClick={handleCreateVerticalTemplate}>
        Create Vertical Template
      </Button>
    );
  }

  const marketingPlanProductIds = appetiteProducts.map((product) => product.id);
  const templateProductIds = new Set(template.products.map((product) => product.appetiteProduct.id));

  const missingProducts = marketingPlanProductIds.filter((productId) => !templateProductIds.has(productId));

  // there's nothing to update if there's no missing products so don't show the button
  if (missingProducts.length === 0) {
    return null;
  }

  const handleUpdateVerticalTemplate = async () => {
    await addProductsToVerticalTemplate({
      variables: {
        input: {
          verticalMarketingPlanTemplateId: template.id,
          appetiteProductIds: missingProducts,
        },
      },
      onCompleted: () => {
        toast({ title: `Vertical Template Updated with ${missingProducts.length} products` });
      },
    });
  };

  // if there's a template and a vertical and there's new products to add... show the final flavor of the button
  return (
    <Button variant="outline" size="xs" onClick={handleUpdateVerticalTemplate}>
      Update Vertical Template
    </Button>
  );
};
