import { forwardRef, type PropsWithChildren, type ReactNode } from "react";

import { FormControl, FormDescription, FormFieldContext, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { cn } from "src/utils";

export interface FieldBaseProps {
  name: string;
  label?: ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  labelPosition?: "start" | "end";
  labelOrientation?: "horizontal" | "vertical";
  description?: string;
  className?: string;
}

export const FieldBase = forwardRef<HTMLDivElement, PropsWithChildren<FieldBaseProps>>(
  (
    { name, label, labelPosition = "start", labelOrientation = "vertical", description, children, className, disabled },
    ref
  ) => (
    <FormFieldContext.Provider value={{ name }}>
      <FormItem
        ref={ref}
        data-disabled={disabled || undefined}
        className={cn("group/form-field space-y-0 flex flex-col flex-1 gap-1.5", className)}
      >
        <div
          className={cn("flex gap-1.5", {
            "flex-col": labelOrientation === "vertical" && labelPosition === "start",
            "flex-col-reverse": labelOrientation === "vertical" && labelPosition === "end",
            "flex-row items-center": labelOrientation === "horizontal" && labelPosition === "start",
            "flex-row-reverse items-center justify-end": labelOrientation === "horizontal" && labelPosition === "end",
          })}
        >
          {label && (
            <FormLabel className="flex gap-2 justify-between text-sm group-data-[disabled]/form-field:cursor-not-allowed group-data-[disabled]/form-field:pointer-events-none group-data-[disabled]/form-field:opacity-70">
              {label}
            </FormLabel>
          )}

          <FormControl>{children}</FormControl>
        </div>

        {description && <FormDescription>{description}</FormDescription>}

        <FormMessage role="alert" />
      </FormItem>
    </FormFieldContext.Provider>
  )
);

FieldBase.displayName = "FieldBase";
