import { ApolloError, QueryHookOptions } from "@apollo/client";
import { createContext, useContext, useEffect } from "react";
import { useParams } from "react-router";

import { PageNotFound } from "@/page-not-found";
import {
  Exact,
  OnOpportunityStateChangedDocument,
  OnOpportunityStateChangedSubscription,
  OnOpportunityStateChangedSubscriptionVariables,
  OpportunityDetailsQuery,
  useOpportunityDetailsQuery,
} from "src/generated/graphql";

interface MarketingPlanContextValue {
  error?: ApolloError;
  opportunity: NonNullable<OpportunityDetailsQuery["opportunity"]>;
  opportunityId: string;
  loading: boolean;
  refetch: () => void;
  refetchQueries: string[];
}

const MarketingPlanContext = createContext({} as MarketingPlanContextValue);

export const MarketingPlanProvider = ({ children }: { children: React.ReactNode }) => {
  const { opportunityId } = useParams<"opportunityId">();

  const {
    data: { opportunity } = {},
    loading,
    error,
    refetch,
    subscribeToMore,
  } = useOpportunityDetailsQuery({
    variables: { id: opportunityId ?? "" },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!opportunityId) {
      return;
    }

    return subscribeToMore<OnOpportunityStateChangedSubscription, OnOpportunityStateChangedSubscriptionVariables>({
      document: OnOpportunityStateChangedDocument,
      variables: { id: opportunityId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev;
        }

        const opportunity = Object.assign({}, prev.opportunity, {
          state: subscriptionData.data.opportunityStateChanged.state,
        });

        return { opportunity };
      },
      onError: (error) => {
        console.error("Subscription error:", error);
      },
    });
  }, [opportunityId]);

  if (!opportunityId || (!loading && !opportunity)) {
    return <PageNotFound />;
  }

  if (!opportunity) {
    return null;
  }

  const value = {
    error,
    loading,
    opportunity,
    opportunityId,
    refetch,
    refetchQueries: ["OpportunityDetails"],
  };

  return <MarketingPlanContext.Provider value={value}>{children}</MarketingPlanContext.Provider>;
};

export const useMarketingPlan = () => {
  const context = useContext(MarketingPlanContext);

  if (!context) {
    throw new Error("useMarketingPlan must be used within an MarketingPlanProvider");
  }

  return context;
};

export const useMarketingPlanById = (options?: QueryHookOptions<OpportunityDetailsQuery, Exact<{ id: string }>>) => {
  const { opportunityId } = useParams<"opportunityId">();

  const {
    data: { opportunity } = {},
    loading,
    refetch,
  } = useOpportunityDetailsQuery({
    variables: { id: opportunityId! },
    skip: !opportunityId,
    fetchPolicy: "cache-and-network",
    ...options,
  });

  return {
    opportunity,
    loading,
    refetch,
  };
};
