import { MaterialSymbol } from "material-symbols";
import { useEffect, useState } from "react";
import { Link, LinkProps } from "react-router-dom";

import { InsuredPin } from "@/components/insured-pin";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { BankanMarketingPlanColumnQuery, useVerticalByCglLazyQuery, VerticalFragment } from "src/generated/graphql";
import { cn } from "src/utils";

type Insured = BankanMarketingPlanColumnQuery["bankanMarketingPlanColumn"]["groupedMarketingPlans"][number]["insured"];

export const BankanCard = ({ children, className }: { children?: React.ReactNode; className?: string }) => (
  <div className={cn("bg-background hover-outline outline-input rounded-lg shadow-sm", className)}>{children}</div>
);

export const BankanCardHeader = ({ insured }: { insured: Insured }) => {
  const [vertical, setVertical] = useState<VerticalFragment | null>();
  const [load, { loading }] = useVerticalByCglLazyQuery();

  useEffect(() => {
    void handleHover("cache-only");
  }, []);

  const handleHover = async (fetchPolicy: "cache-first" | "cache-only") => {
    insured.isoCglCodes[0]
      ? await load({
          variables: { input: { isoCglCode: insured.isoCglCodes[0] } },
          fetchPolicy,
        }).then(({ data }) => {
          setVertical(data?.verticalByCGL);
        })
      : setVertical(null);
  };

  return (
    <header className="px-4 py-3 space-y-1.5" onMouseEnter={() => handleHover("cache-first")}>
      <div className="flex gap-1 items-center justify-between text-xs">
        <span className="flex-auto font-semibold mr-2 truncate">{insured.name}</span>
        {loading ? (
          <Spinner />
        ) : (
          <Icon
            icon="smart_toy"
            className={cn(
              vertical === undefined && "text-foreground/10",
              vertical === null && "hidden",
              vertical?.name && "filled text-primary"
            )}
          />
        )}
        <InsuredPin id={insured.id} className="-my-1 -mr-1.5" />
      </div>
      <div className="text-2xs text-muted-foreground truncate">
        {insured.agent.firstName} {insured.agent.lastName}, {insured.agent.agency.name}
      </div>
    </header>
  );
};

export const BankanLink = ({ children, className, to, ...rest }: LinkProps & { cardClassName?: string }) => (
  <Link to={to} target="_blank" className={cn("block px-4 py-3 space-y-1.5", className)} {...rest}>
    {children}
  </Link>
);

export const BankanLinkText = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("font-semibold text-xs truncate", className)} {...rest}>
    {children}
  </div>
);

export const BankanLinkFooter = ({ children, className, ...rest }: React.HTMLAttributes<HTMLDivElement>) => (
  <footer className={cn("flex gap-3 items-center justify-between text-2xs text-muted-foreground", className)} {...rest}>
    {children}
  </footer>
);

const now = Date.now();

export const DaysSince = ({ icon = "event", date }: { icon?: MaterialSymbol; date: string }) => (
  <div className="filled flex font-semibold gap-1 items-center text-2xs tracking-tight">
    <Icon icon={icon} />
    {daysDelta(date)}
  </div>
);

export const daysDelta = (date: string) => Math.round((now - Date.parse(date)) / 86_400_400);
