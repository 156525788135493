import { createContext, ReactNode, useContext, useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

export type Theme = "dark" | "light" | "beef";

export type SelectedTheme = Theme | "system";

interface ThemeProviderProps {
  children: ReactNode;
  defaultSelectedTheme?: SelectedTheme;
  storageKey?: string;
}

interface ThemeProviderState {
  theme: Theme;
  selectedTheme: SelectedTheme;
  setSelectedTheme: (theme: SelectedTheme) => void;
}

const initialTheme = "system";

const initialState: ThemeProviderState = {
  theme: "light",
  selectedTheme: initialTheme,
  setSelectedTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultSelectedTheme = initialTheme,
  storageKey = "theme",
  ...props
}: ThemeProviderProps) {
  const [selectedTheme, setSelectedTheme] = useLocalStorage<SelectedTheme>(storageKey, defaultSelectedTheme);

  const theme = useMemo(() => {
    if (selectedTheme === "system") {
      return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
    }

    return selectedTheme;
  }, [selectedTheme]);

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove("light", "dark", "beef");
    root.classList.add(theme);
  }, [theme]);

  const value = useMemo(
    () => ({
      theme,
      setSelectedTheme,
      selectedTheme,
    }),
    [theme, setSelectedTheme, selectedTheme]
  );

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      <div className="gradient" />
      <div className="radial" />
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }

  return context;
};
