import { Button } from "./ui/button";
import { Card, CardHeader, CardTitle } from "./ui/card";

export const OrEmailDisplay = ({ className }: { className?: string }) => (
  <Card className={className}>
    <CardHeader>
      <CardTitle>Email your submission</CardTitle>
      <p>
        You can always send us submissions to{" "}
        <Button variant="link" theme="primary" asChild>
          <a href="mailto:submissions@quotewell.com">submissions@quotewell.com</a>
        </Button>
        .
      </p>
    </CardHeader>
  </Card>
);
