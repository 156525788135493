import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Sheet, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Field, Input } from "@/forms/default";
import { Reform } from "@/forms/reform";
import { toast } from "@/hooks/use-toast";
import { CarrierContactType, useUpdateAppetiteProductMutation } from "src/generated/graphql";
import { waitMs } from "src/utils";
import { MarketingEffortSubmission } from "../submission-actions";
import { CarrierContactList } from "./carrier-contact-list";

const AppetiteProductFormSchema = z.object({
  portalLink: z.string().url().optional().or(z.literal("")),
  submissionInstructions: z.string().optional(),
});

export const CarrierContacts: React.FC<{
  submission: MarketingEffortSubmission;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ submission, open, setOpen }) => {
  const [editMode, setEditMode] = useState(false);

  const [updateAppetiteProduct] = useUpdateAppetiteProductMutation({
    onCompleted: () => {
      void waitMs(333).then(() => setEditMode(false));
    },
    onError: () => {
      toast({ title: "Error", description: "We're sorry, an error occurred.", variant: "destructive" });
    },
  });

  const submissionContacts = submission.appetiteProduct.submissionCarrierContacts ?? [];
  const referenceContacts = submission.appetiteProduct.referenceCarrierContacts ?? [];

  return (
    <Sheet open={open} onOpenChange={() => setOpen((open) => !open)}>
      <SheetContent className="overflow-y-auto">
        <SheetHeader>
          <SheetTitle>{submission.appetiteProduct.carrierName}</SheetTitle>
          <SheetDescription>{submission.appetiteProduct.carrierProductName}</SheetDescription>
        </SheetHeader>
        <div className="py-4 space-y-4">
          {!editMode && (
            <div>
              <div className="flex justify-between">
                <h5>Submission Instructions</h5>
                <Button variant="ghost" display="icon" onClick={() => setEditMode(true)}>
                  <Icon icon="edit" />
                </Button>
              </div>
              <p>{submission.appetiteProduct.submissionInstructions ?? "None listed"}</p>
              {submission.appetiteProduct.portalLink ? (
                <p>
                  <a
                    href={submission.appetiteProduct.portalLink}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    {submission.appetiteProduct.portalLink}
                  </a>
                </p>
              ) : (
                <p>No Link Available - Click Pencil to add</p>
              )}
            </div>
          )}
          {editMode && (
            <Reform
              className="py-4 space-y-4"
              schema={AppetiteProductFormSchema}
              onSubmit={(_, values) => {
                void updateAppetiteProduct({
                  variables: {
                    input: {
                      id: submission.appetiteProduct.id,
                      portalLink: values.portalLink,
                      submissionInstructions: values.submissionInstructions,
                    },
                  },
                });
              }}
              defaultValues={{
                portalLink: submission.appetiteProduct.portalLink ?? undefined,
                submissionInstructions: submission.appetiteProduct.submissionInstructions ?? undefined,
              }}
            >
              <Field label="Portal Link" name="portalLink">
                <Input name="portalLink" />
              </Field>

              <Field label="Submission Instructions" name="submissionInstructions">
                <Input name="submissionInstructions" />
              </Field>

              <SheetFooter>
                <Button variant="outline" theme="destructive" onClick={() => setEditMode(false)}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </SheetFooter>
            </Reform>
          )}
          <CarrierContactList
            type={CarrierContactType.Submission}
            contacts={submissionContacts}
            appetiteProductId={submission.appetiteProduct.id}
          />
          <CarrierContactList
            type={CarrierContactType.Reference}
            contacts={referenceContacts}
            appetiteProductId={submission.appetiteProduct.id}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

// This component is essentially a wrapper used to watch the effectiveDate field
// When effectiveDate is updated, default the expirationDate to 1 year later
export const FieldWatcher = ({ children }: { children: React.ReactNode }) => {
  const { getValues, setValue, watch } = useFormContext();

  const effectiveDate = watch("effectiveDate");
  const expirationDate = getValues("expirationDate");
  useEffect(() => {
    // Don't overwrite expirationDate if it's already set
    if (effectiveDate && !expirationDate) {
      const oneYearLater = new Date(new Date(effectiveDate).setFullYear(new Date(effectiveDate).getFullYear() + 1));
      setValue("expirationDate", oneYearLater.toISOString());
    }
  }, [effectiveDate, setValue]);

  return <>{children}</>;
};
