import { join } from "lodash";

import { Grid, GridCell, GridRow, GridRowFooter, GridRowHeader } from "@/components/grid";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Icon } from "@/components/ui/icon";
import { Loading } from "@/components/ui/loading";
import { cn } from "src/utils";
import { formatDate } from "src/utils/date";
import { useSubmissionTemplate } from "./use-submission-template";

export const SubmissionTemplateAttachments = () => {
  const { fileIds, files, filesLoading, setFileIds, selectedFile, setSelectedFile } = useSubmissionTemplate();

  if (filesLoading) {
    return <Loading />;
  }

  if (files.length === 0) {
    return <h3>No files found for this opportunity</h3>;
  }

  return (
    <Grid className="border grid-cols-[1rem_1fr_3.5rem_1.5rem] rounded">
      <GridRowHeader className="bg-accent rounded-t">
        <Checkbox
          checked={fileIds.length === files.length}
          onCheckedChange={(checked) => {
            checked ? setFileIds(files.map(({ id }) => id)) : setFileIds([]);
          }}
        />
        <GridCell>File Name / Labels</GridCell>
        <GridCell>Date</GridCell>
        <div />
      </GridRowHeader>
      {files.map(({ id, labels, filename, createdAt, ...rest }) => {
        const selected = selectedFile?.id === id;
        return (
          <GridRow key={id} className="group">
            <Checkbox
              checked={fileIds.includes(id ?? "")}
              onCheckedChange={(checked) => {
                checked
                  ? setFileIds((prev: string[]) => [...prev, id ?? ""])
                  : setFileIds((prev: string[]) => prev.filter((fileId) => fileId !== id));
              }}
            />
            <GridCell className="space-y-px">
              <div className="truncate">{filename}</div>
              <div className="text-2xs text-muted-foreground truncate">{join(labels, ", ")}</div>
            </GridCell>
            <GridCell>{formatDate(new Date(createdAt), "LL-dd-yy")}</GridCell>
            <Button
              variant={selected ? "outline" : "ghost"}
              size="sm"
              display="icon"
              className={cn("opacity-25 transition-opacity group-hover:opacity-100", selected && "opacity-100")}
              onClick={() => {
                setSelectedFile(selected ? undefined : { id, filename, ...rest });
              }}
            >
              <Icon icon={selected ? "close_fullscreen" : "open_in_full"} className="filled" />
            </Button>
          </GridRow>
        );
      })}
      <GridRowFooter className="bg-accent rounded-b sticky bottom-0">
        <div className="col-span-full">{fileIds.length} Attachments Selected</div>
      </GridRowFooter>
    </Grid>
  );
};
