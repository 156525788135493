import { Activity } from "@/components/activity/activity";
import { HasInternalRole } from "@/components/has-role";
import { Notes } from "@/components/notes";
import { ScrollPaneColumns } from "@/components/scroll-pane";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { MarketingPlanRequirementsProvider } from "@/hooks/use-marketing-plan-requirements";
import { Requirements } from "@/requirements/requirements";
import { Submissions } from "@/submissions/submissions";
import { MarketingPlanState, UserAccountFragment } from "src/generated/graphql";
import { Coverage } from "./coverage";
import { FollowUps } from "./follow-ups/follow-ups";
import { Team } from "./team";

export const MarketingPlanDetails = () => {
  const { opportunity } = useMarketingPlan();

  if (opportunity.state === MarketingPlanState.WaitingForCoverages) {
    return <Coverage skipNavigation />;
  }

  return (
    <div className="p-6 space-y-6">
      <Submissions />
      <MarketingPlanRequirementsProvider>
        <Requirements />
      </MarketingPlanRequirementsProvider>
      <ScrollPaneColumns className="flex-col-reverse grid-cols-[2fr_1fr] p-0">
        <Activity opportunityId={opportunity.id} />
        <div className="space-y-6">
          <HasInternalRole>
            <Notes opportunityId={opportunity.id} />
            <FollowUps />
          </HasInternalRole>
          <Team broker={opportunity.broker as UserAccountFragment} agent={opportunity.insured.agent} />
        </div>
      </ScrollPaneColumns>
    </div>
  );
};
