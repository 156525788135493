import { useState } from "react";
import { useParams } from "react-router";

import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Spinner } from "@/components/ui/loading";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useMarketingPlan } from "@/hooks/use-marketing-plan";
import { useToast } from "@/hooks/use-toast";
import { SubmissionFragment, SubmissionState, useQuotesLazyQuery } from "src/generated/graphql";
import { cn } from "src/utils";
import { uploadRedactedQuote } from "src/utils/file";

export const UploadQuote = ({ submission }: { submission: SubmissionFragment }) => {
  const { insuredId } = useParams();
  const { refetch } = useMarketingPlan();
  const { toast } = useToast();
  const [submitting, setSubmitting] = useState(false);

  const [fetch] = useQuotesLazyQuery({
    variables: {
      input: {
        insuredId,
      },
    },
  });

  const { id, state } = submission;

  if (
    [
      SubmissionState.Pending,
      SubmissionState.Declined,
      SubmissionState.MarketBlocked,
      SubmissionState.RuledOut,
    ].includes(state)
  ) {
    return null;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button variant="ghost" size="sm" display="icon" asChild>
          <label className={cn(submitting ? "cursor-wait opacity-60" : "cursor-pointer")}>
            <input
              type="file"
              name="file"
              className="hidden"
              onChange={async (e) => {
                setSubmitting(true);
                if (e.target.files && e.target.files.length > 0) {
                  const file = e.target.files[0];

                  if (file.type !== "application/pdf") {
                    toast({ title: "We only accept PDF files" });
                    setSubmitting(false);
                    return;
                  }
                  await uploadRedactedQuote(file, id).then((res) => {
                    if (res.success) {
                      toast({ title: "Redacted Quote Uploaded" });
                      void fetch();
                      void refetch();
                    } else {
                      toast({ title: "Error" });
                    }
                  });
                }
                setSubmitting(false);
              }}
            />
            {submitting ? <Spinner /> : <Icon icon="upload" />}
          </label>
        </Button>
      </TooltipTrigger>
      <TooltipContent>Upload Quote</TooltipContent>
    </Tooltip>
  );
};
