import { UUID } from "@cp/toolkit";

import { SetBroker } from "@/components/set-broker";
import { StatePill } from "@/components/state";
import { useMarketingPlanById } from "@/hooks/use-marketing-plan";
import { toast } from "@/hooks/use-toast";
import { UserAccountFragment, useUpdateOpportunityMutation } from "src/generated/graphql";
import { MarketingPlanDropdown } from "./marketing-plan-dropdown";
import { TestAlby } from "./test-alby";

export const MarketingPlanMenu = () => {
  const { opportunity } = useMarketingPlanById();

  const [updateOpportunity] = useUpdateOpportunityMutation({
    onCompleted: () => {
      toast({ title: "Success", description: "Broker assignment updated." });
    },
    onError: () => {
      toast({ title: "Error", description: "An error occurred.", variant: "destructive" });
    },
  });

  if (!opportunity) {
    return null;
  }

  const { id, broker, state } = opportunity;

  const onSelect = (e: UUID) =>
    updateOpportunity({
      variables: {
        input: { id: id, brokerId: e === "-" ? null : e },
      },
    });

  return (
    <>
      {process.env.NODE_ENV === "development" && <TestAlby />}
      <SetBroker broker={broker as UserAccountFragment} onSelect={onSelect} />
      <StatePill state={state} />
      <MarketingPlanDropdown opportunity={opportunity} />
    </>
  );
};
