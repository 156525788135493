import { Button } from "@/components/ui/button";
import { Icon } from "@/components/ui/icon";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { SubmissionAction, SubmissionFragment } from "src/generated/graphql";
import { useSubmissionActions } from "./use-submission-actions";

export const RuledOut = ({ submission }: { submission: SubmissionFragment }) => {
  const { handleTransition, handleAddRuleOutReason } = useSubmissionActions();

  const hasRuleOutReason = !!submission.stateTransitions[submission.stateTransitions.length - 1].details;

  return (
    <>
      {!hasRuleOutReason && (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="sm" display="icon" onClick={() => handleAddRuleOutReason(submission)}>
              <Icon icon="edit_note" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Add rule out reason</TooltipContent>
        </Tooltip>
      )}

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            display="icon"
            onClick={() => handleTransition(submission, SubmissionAction.Revert)}
          >
            <Icon icon="undo" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Revert</TooltipContent>
      </Tooltip>
    </>
  );
};
