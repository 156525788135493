import { UUID } from "@cp/toolkit";
import { useState } from "react";
import { useParams } from "react-router";

import { ContainerHeaderTrigger } from "@/components/container";
import { HasInternalRole } from "@/components/has-role";
import { Autocomplete } from "@/components/ui/autocomplete";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Select, SelectContent, SelectItem } from "@/components/ui/select";
import { useInsured } from "@/hooks/use-insured";
import { toast } from "@/hooks/use-toast";
import { Roles, useUpdateInsuredMutation, useUserAccountsQuery } from "src/generated/graphql";

export const SelectAgent = ({ children }: { children: React.ReactNode }) => {
  const { insured } = useInsured();
  const [open, setOpen] = useState(false);

  const { data: agents } = useUserAccountsQuery({
    variables: { input: { agencyId: insured.agency.id, roles: [Roles.Agent] } },
    fetchPolicy: "cache-first",
  });

  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => toast({ title: "Success", description: "Insured updated." }),
    onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
  });

  const { id, agent } = insured;

  return (
    <HasInternalRole elseShow={children}>
      <Card>
        <CardHeader>
          <CardTitle>Select Agent</CardTitle>
        </CardHeader>
        <CardContent>
          <Dialog open={open} onOpenChange={() => setOpen(!open)}>
            <Select
              value={agent.id}
              onValueChange={async (e) => {
                if (e === "agentAtAnotherAgency") {
                  setOpen(true);
                } else {
                  await updateInsured({
                    variables: {
                      input: {
                        id,
                        agentId: e as UUID,
                      },
                    },
                  });
                }
              }}
            >
              <ContainerHeaderTrigger>{children}</ContainerHeaderTrigger>
              <SelectContent>
                {agents?.userAccounts.map((u) => (
                  <SelectItem key={u.id} value={u.id}>
                    {u.firstName} {u.lastName}
                  </SelectItem>
                ))}
                <DialogTrigger asChild>
                  <SelectItem key={"agentAtAnotherAgency"} value={"agentAtAnotherAgency"} className="font-bold">
                    Agent at another agency
                  </SelectItem>
                </DialogTrigger>
              </SelectContent>
            </Select>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Re-assign to Agent</DialogTitle>
              </DialogHeader>
              <AllAgentsAutocomplete onSelect={() => setOpen(false)} />
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </HasInternalRole>
  );
};

const AllAgentsAutocomplete = ({ onSelect }: { onSelect: () => void }) => {
  const { insuredId } = useParams();
  const { data: agents } = useUserAccountsQuery({
    variables: { input: { roles: [Roles.Agent] } },
    fetchPolicy: "cache-first",
  });
  const [updateInsured] = useUpdateInsuredMutation({
    onCompleted: () => toast({ title: "Success", description: "Insured updated." }),
    onError: () => toast({ title: "Error", description: "An error occurred.", variant: "destructive" }),
  });

  if (!agents) {
    return null;
  }

  return (
    <Autocomplete
      options={agents.userAccounts}
      onSelect={async (agent) => {
        await updateInsured({
          variables: {
            input: {
              id: insuredId || "",
              agentId: agent.id as UUID,
            },
          },
        });

        onSelect();
      }}
      toValue={(agent) => `${agent.firstName} ${agent.lastName}`}
      toLabel={(agent) => `${agent.firstName} ${agent.lastName}`}
      placeholder="Search for an Agent"
    />
  );
};
