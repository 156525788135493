import { useAtom } from "jotai";

import { bankanTabAtom } from "@/atoms";
import { NavigateBroker } from "@/components/navigate/navigate-broker";
import { ScrollPane } from "@/components/scroll-pane";
import { Bar } from "@/components/ui/bar";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ROLE_METADATA } from "@/metadata";
import { Roles } from "src/generated/graphql";
import { NavigateArm } from "../components/navigate/navigate-arm";
import { BankanColumns } from "./bankan-columns";
import { BankanFilter } from "./bankan-filter";

const roles = [Roles.Dj, Roles.Broker, Roles.UnderwritingAssistant, Roles.Arm];

export const Bankan = () => {
  const [selectedRole, setSelectedRole] = useAtom(bankanTabAtom);

  return (
    <ScrollPane>
      <Bar>
        <Tabs value={selectedRole} onValueChange={(v) => setSelectedRole(v as Roles)}>
          <TabsList>
            {roles.map((role) => {
              return (
                <TabsTrigger key={role} value={role}>
                  {ROLE_METADATA[role].label}
                </TabsTrigger>
              );
            })}
          </TabsList>
        </Tabs>
        <div className="flex gap-3 items-center ml-auto">
          {selectedRole !== Roles.Arm && <NavigateBroker path="/bankan" />}
          {selectedRole === Roles.Arm && <NavigateArm path="/bankan" />}
          <BankanFilter />
        </div>
      </Bar>
      <BankanColumns />
    </ScrollPane>
  );
};
