import { US_STATES } from "@cp/toolkit";
import { KnownClientData } from "@qw/qw-common";
import { useState } from "react";
import { z } from "zod";

import { ButtonGroup } from "@/components/button-group";
import { Group } from "@/components/group";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { FieldInput } from "@/forms-v2/fields/field-input";
import { FieldCurrency } from "@/forms-v2/fields/field-number";
import { FieldSelect } from "@/forms-v2/fields/field-select";
import { FieldTextarea } from "@/forms-v2/fields/field-textarea";
import { Form } from "@/forms-v2/form";
import { FormReset } from "@/forms-v2/form-reset";
import { FormSubmit } from "@/forms-v2/form-submit";
import { useInsured } from "@/hooks/use-insured";
import { useCreateClientDataMutation, useUpdateInsuredMutation } from "src/generated/graphql";

const InsuredFormSchema = z.object({
  name: z.string().min(1),
  primaryState: z.string().optional().nullable(),
  description: z.string().min(1, { message: "Business description is required" }),
  exposures: z.string().nullable(),
  revenue: z.number().optional(),
  tiv: z.number().optional(),
});

type InsuredFormValues = z.infer<typeof InsuredFormSchema>;

export const Summary = () => {
  const [editing, setEditing] = useState(false);

  return (
    <Card>{editing ? <UpdateInsuredForm setEditing={setEditing} /> : <InsuredDetails setEditing={setEditing} />}</Card>
  );
};

const UpdateInsuredForm = ({ setEditing }: { setEditing: (bool: boolean) => void }) => {
  const { insured } = useInsured();

  const [updateInsured] = useUpdateInsuredMutation();
  const [updateClientData] = useCreateClientDataMutation();

  const defaultValues: InsuredFormValues = {
    ...insured,
    description: insured.description ?? "",
    exposures: insured.exposures ?? "",
    revenue: insured.revenue ?? undefined,
    tiv: insured.tiv ?? undefined,
  };

  const handleSubmit = async ({ name, primaryState, description, exposures, revenue, tiv }: InsuredFormValues) => {
    const dataToWrite = [];
    if (revenue && revenue !== insured.revenue) {
      dataToWrite.push({ key: KnownClientData.Revenue.CurrentYear, value: revenue.toString() });
    }

    if (tiv && tiv !== insured.tiv) {
      dataToWrite.push({ key: KnownClientData.TotalInsurableValue, value: tiv.toString() });
    }

    if (dataToWrite.length > 0) {
      await updateClientData({
        variables: {
          input: {
            insuredId: insured.id,
            clientDataInputs: dataToWrite,
            source: "UPDATE_CLIENT",
          },
        },
      });
    }

    await updateInsured({
      variables: {
        input: {
          id: insured.id,
          name: name,
          primaryState: primaryState,
          description: description,
          exposures,
        },
      },
      refetchQueries: ["SearchAppetiteForOpportunity"],
    });

    setEditing(false);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} validationSchema={InsuredFormSchema} defaultValues={defaultValues}>
        <CardHeader>
          <CardTitle>Edit Named Insured</CardTitle>
        </CardHeader>

        <CardContent className="space-y-3">
          <Group>
            <FieldInput name="name" label="Business name" />

            <FieldSelect
              name="primaryState"
              label="Primary state"
              options={US_STATES.map((state) => ({ value: state, label: state }))}
            />

            <FieldCurrency name="revenue" label="Gross revenue" />

            <FieldTextarea name="description" label="Business description" />

            <FieldTextarea name="exposures" label="Exposures" />

            {insured.tiv && <FieldCurrency name="tiv" label="TIV" placeholder="Total Insurable Value" />}
          </Group>
        </CardContent>

        <CardFooter>
          <ButtonGroup>
            <FormReset onClick={() => setEditing(false)} />
            <FormSubmit />
          </ButtonGroup>
        </CardFooter>
      </Form>
    </>
  );
};

const InsuredDetails = ({ setEditing }: { setEditing: (bool: boolean) => void }) => {
  const { insured } = useInsured();

  return (
    <>
      <CardHeader>
        <CardTitle>Named Insured</CardTitle>
      </CardHeader>

      <CardContent className="space-y-3">
        <div>
          <h5 className="text-muted-foreground">Business Name</h5>
          <p>{insured.name}</p>
        </div>

        <div>
          <h5 className="text-muted-foreground">Primary State</h5>
          <p>{insured.primaryState ?? "-"}</p>
        </div>

        {insured.revenue && (
          <div>
            <h5 className="text-muted-foreground">Gross Revenue</h5>
            <p className="text-sm">
              {insured.revenue.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
              })}
            </p>
          </div>
        )}

        <div className="3xl:col-span-2">
          <h5 className="text-muted-foreground">Business Description</h5>
          <p className="text-sm">{insured.description ?? "-"}</p>
        </div>

        {insured.exposures && (
          <div className="3xl:col-span-2">
            <h5 className="text-muted-foreground">Exposures</h5>
            <p className="text-sm">{insured.exposures}</p>
          </div>
        )}

        {insured.tiv && (
          <div>
            <h5 className="text-muted-foreground">TIV</h5>
            <p className="text-sm">
              {insured.tiv.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
              })}
            </p>
          </div>
        )}
      </CardContent>

      <CardFooter>
        <Button variant="secondary" display="flex" onClick={() => setEditing(true)}>
          Edit Named Insured
        </Button>
      </CardFooter>
    </>
  );
};
