import { MaterialSymbol } from "material-symbols";
import { Fragment, ReactNode, type FC, type HTMLAttributes, type MouseEvent } from "react";
import { Link } from "react-router-dom";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Icon } from "@/components/ui/icon";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "src/utils";

export interface SidePanelAction {
  id: string;
  icon: MaterialSymbol;
  label?: string;
  onClick?: (event: any) => void;
  isHidden?: boolean;
}

export const SidePanel: FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...props }) => (
  <div
    className={cn(
      "relative grow-0 shrink-0 h-full w-full landscape:basis-96 landscape:max-w-96 portrait:basis-full landscape:border-l landscape:border-l-heavy",
      className
    )}
    {...props}
  >
    <div className="flex flex-col bg-background h-full overflow-y-auto overflow-x-hidden text-sm">{children}</div>
  </div>
);

export interface SidePanelHeaderProps {
  title?: string;
  label?: string;
  icon?: ReactNode;
  onClose?: () => void;
  onCloseNavigatePath?: string;
  className?: string;
  actions?: SidePanelAction[];
  isLoading?: boolean;
  isEmpty?: boolean;
}

export const SidePanelHeader: FC<SidePanelHeaderProps> = ({
  icon,
  title,
  label,
  actions,
  onClose,
  onCloseNavigatePath,
  className,
  isLoading,
  isEmpty,
}) => {
  const handleCloseClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (!onClose) {
      return;
    }

    event.preventDefault();
    onClose();
  };

  return (
    <header className={cn("flex items-start justify-between gap-2 p-5", className)}>
      <div className="flex items-start flex-1 gap-4 max-w-[80%]">
        {isLoading && <Skeleton className="w-10 h-10 rounded-sm" />}

        {!isLoading && !isEmpty && icon && (
          <div className="w-10 h-10 flex items-center justify-center border bg-background rounded-sm shrink-0 text-muted-foreground">
            {typeof icon === "string" ? <Icon icon={icon as MaterialSymbol} className="text-xl" /> : icon}
          </div>
        )}

        {!isLoading && isEmpty && (
          <div className="w-10 h-10 flex items-center justify-center border bg-background rounded-sm shrink-0">
            <Icon icon="error" className="text-xl text-muted-foreground" />
          </div>
        )}

        <div className={cn("flex flex-col flex-1 w-full max-w-[80%]", { "gap-1": !icon && !isEmpty && !isLoading })}>
          {isLoading && (
            <>
              <Skeleton className="h-3 w-[80px]" />
              <Skeleton className="h-5 w-[150px] mt-2" />
            </>
          )}

          {!isLoading && !isEmpty && (
            <>
              <p className="font-semibold text-2xs text-muted-foreground">{label}</p>
              <h2 className="text-lg font-semibold leading-tight tracking-tight break-words">{title}</h2>
            </>
          )}

          {!isLoading && isEmpty && (
            <>
              <p className="font-semibold text-2xs text-muted-foreground">{label}</p>
              <h2 className="text-lg font-semibold leading-tight tracking-tight break-words">Not Found</h2>
            </>
          )}
        </div>
      </div>

      <div className="flex items-center gap-1 -mt-2 -mr-2 -mb-2">
        {!isEmpty && !isLoading && !!actions?.length && (
          <div>
            <SidePanelActions actions={actions} />
          </div>
        )}

        <div>
          <Button variant="ghost" display="icon" size="sm" asChild>
            <Link to={onCloseNavigatePath || "#"} onClick={handleCloseClick}>
              <Icon icon="close" />
            </Link>
          </Button>
        </div>
      </div>
    </header>
  );
};

export type SidePanelHeadingProps = HTMLAttributes<HTMLElement>;

export const SidePanelHeading: FC<SidePanelHeadingProps> = ({ className, ...props }) => (
  <h3 className={cn("font-semibold text-2xs text-muted-foreground", className)} {...props} />
);

export type SidePanelSectionProps = HTMLAttributes<HTMLElement> & {
  hasDivider?: boolean;
};

export const SidePanelSection: FC<SidePanelSectionProps> = ({ className, hasDivider = true, ...props }) => (
  <section className={cn("flex flex-col p-5 gap-2", { "border-t": hasDivider }, className)} {...props} />
);

export type SidePanelListProps = HTMLAttributes<HTMLUListElement>;

export const SidePanelList: FC<SidePanelListProps> = ({ className, ...props }) => (
  <ul className={cn("flex flex-col gap-1.5", className)} {...props} />
);

export type SidePanelDetailsProps = HTMLAttributes<HTMLDivElement> & {
  label?: ReactNode;
};

export const SidePanelDetails: FC<SidePanelDetailsProps> = ({ className, label, children, ...props }) => (
  <div className={cn("flex flex-col gap-2", className)} {...props}>
    {label && <SidePanelDetailsHeader label={label} />}
    {children}
  </div>
);

export type SidePanelDetailsHeaderProps = HTMLAttributes<HTMLElement> & {
  label: ReactNode;
};

export const SidePanelDetailsHeader: FC<SidePanelDetailsHeaderProps> = ({ label, className, children, ...props }) => (
  <header className={cn("flex items-center gap-2", className)} {...props}>
    <h3 className="font-semibold text-xs text-muted-foreground">{label}</h3>
    {children}
  </header>
);

export interface SidePanelActions {
  actions?: SidePanelAction[];
}

export const SidePanelActions: FC<SidePanelActions> = ({ actions }) => {
  if (!actions?.length) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" display="icon" size="sm">
          <Icon icon="more_horiz" className="font-bold" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {actions.map((action) => {
          if (action.isHidden) {
            return null;
          }

          return (
            <DropdownMenuItem key={action.id} onClick={action.onClick} className="items-center">
              {action.icon && <Icon icon={action.icon} className="text-sm" />}
              {action.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const SidePanelLoading: FC<{ sectionCount?: number }> = ({ sectionCount }) => (
  <>
    {/* eslint-disable-next-line unicorn/no-new-array */}
    {[...new Array(sectionCount || 1).keys()].map((key) => (
      <Fragment key={key}>
        <SidePanelSection>
          <Skeleton className="h-3 w-[80px]" />
          <Skeleton className="h-5 w-full" />
        </SidePanelSection>
        <SidePanelSection>
          <Skeleton className="h-3 w-[80px]" />
          <Skeleton className="h-9 w-full" />
        </SidePanelSection>
        <SidePanelSection>
          <Skeleton className="h-3 w-[80px]" />
          <Skeleton className="h-5 w-full" />
        </SidePanelSection>
        <SidePanelSection>
          <Skeleton className="h-3 w-[80px]" />
          <Skeleton className="h-9 w-full" />
          <Skeleton className="h-9 w-full" />
        </SidePanelSection>
      </Fragment>
    ))}
  </>
);
